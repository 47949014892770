import React from 'react';

const HeroImage = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 313.7 320" enable-background="new 0 0 313.7 320">
    <g>
      <path fill="#ECF7FA" d="M163.5,1.2c-0.2,0-0.5,0-0.7,0l0-1.2c0.2,0,0.5,0,0.7,0L163.5,1.2z M157.9,1.3l0-1.2c0.2,0,0.5,0,0.7,0
      l0,1.2C158.4,1.2,158.2,1.3,157.9,1.3L157.9,1.3z M168.5,1.4c-0.2,0-0.5,0-0.7,0l0.1-1.2c0.2,0,0.5,0,0.7,0L168.5,1.4z M153,1.6
      l-0.1-1.2c0.2,0,0.5,0,0.7-0.1l0.1,1.2C153.5,1.5,153.2,1.5,153,1.6z M173.4,1.8c-0.2,0-0.5,0-0.7-0.1l0.1-1.2c0.2,0,0.5,0,0.7,0.1
      L173.4,1.8z M148.1,2.1L147.9,1c0.2,0,0.5-0.1,0.7-0.1l0.2,1.2C148.6,2.1,148.4,2.1,148.1,2.1L148.1,2.1z M178.3,2.3l-0.7-0.1
      l0.2-1.2l0.7,0.1L178.3,2.3z M143.3,3L143,1.8c0.2,0,0.5-0.1,0.7-0.1l0.2,1.2C143.7,2.9,143.5,2.9,143.3,3L143.3,3z M183.2,3.1
      l-0.7-0.1l0.2-1.2l0.7,0.1L183.2,3.1z M188,4c-0.2,0-0.5-0.1-0.7-0.1l0.2-1.2c0.2,0,0.5,0.1,0.7,0.1L188,4z M138.5,4.1l-0.3-1.2
      c0.2-0.1,0.5-0.1,0.7-0.2l0.3,1.2C138.9,4,138.7,4,138.5,4.1L138.5,4.1z M192.8,5l-0.7-0.2l0.3-1.2l0.7,0.2L192.8,5z M133.7,5.4
      l-0.4-1.1c0.2-0.1,0.5-0.1,0.7-0.2l0.3,1.1C134.2,5.3,133.9,5.4,133.7,5.4L133.7,5.4z M197.6,6.3l-0.7-0.2l0.3-1.2l0.7,0.2
      L197.6,6.3z M129.1,7l-0.4-1.1c0.2-0.1,0.4-0.2,0.7-0.2l0.4,1.1C129.5,6.8,129.3,6.9,129.1,7z M202.3,7.7l-0.7-0.2l0.4-1.1l0.7,0.2
      L202.3,7.7z M124.4,8.7L124,7.6c0.2-0.1,0.4-0.2,0.7-0.3l0.4,1.1C124.9,8.6,124.7,8.7,124.4,8.7L124.4,8.7z M207,9.3
      c-0.2-0.1-0.4-0.2-0.7-0.2l0.4-1.1c0.2,0.1,0.4,0.2,0.7,0.2L207,9.3z M119.9,10.7l-0.5-1.1l0.6-0.3l0.5,1.1L119.9,10.7z M211.6,11
      l-0.7-0.3l0.4-1.1l0.7,0.3L211.6,11z M115.5,12.8l-0.5-1.1l0.6-0.3l0.5,1.1L115.5,12.8z M216.2,12.9l-0.6-0.3l0.5-1.1l0.7,0.3
      L216.2,12.9z M220.7,15c-0.2-0.1-0.4-0.2-0.6-0.3l0.5-1.1c0.2,0.1,0.4,0.2,0.6,0.3L220.7,15z M111.1,15l-0.6-1.1l0.6-0.3l0.6,1.1
      L111.1,15z M225,17.2l-0.6-0.3l0.6-1.1l0.6,0.3L225,17.2z M106.7,17.5l-0.6-1l0.6-0.4l0.6,1L106.7,17.5z M229.3,19.6
      c-0.2-0.1-0.4-0.2-0.6-0.4l0.6-1c0.2,0.1,0.4,0.2,0.6,0.4L229.3,19.6z M102.5,20l-0.6-1l0.6-0.4l0.6,1L102.5,20z M233.5,22.2
      c-0.2-0.1-0.4-0.3-0.6-0.4l0.7-1c0.2,0.1,0.4,0.3,0.6,0.4L233.5,22.2z M98.3,22.6l-0.7-1l0.6-0.4l0.7,1L98.3,22.6z M237.6,25
      l-0.6-0.4l0.7-1l0.6,0.4L237.6,25z M94.2,25.4l-0.7-1l0.6-0.4l0.7,1L94.2,25.4z M241.5,28.1c-0.2-0.1-0.4-0.3-0.5-0.4l0.7-0.9
      c0.2,0.1,0.4,0.3,0.6,0.4L241.5,28.1z M90.2,28.3l-0.7-1l0.6-0.4l0.7,1L90.2,28.3z M245.2,31.3c-0.2-0.2-0.3-0.3-0.5-0.5l0.8-0.9
      c0.2,0.2,0.4,0.3,0.5,0.5L245.2,31.3z M86.3,31.3l-0.7-0.9l0.6-0.4l0.7,1L86.3,31.3z M82.4,34.3l-0.8-0.9l0.6-0.4l0.7,0.9
      L82.4,34.3z M248.7,34.7c-0.2-0.2-0.3-0.3-0.5-0.5l0.8-0.8c0.2,0.2,0.3,0.3,0.5,0.5L248.7,34.7z M78.6,37.5l-0.8-0.9l0.5-0.5
      l0.8,0.9L78.6,37.5z M252,38.3c-0.1-0.2-0.3-0.4-0.5-0.5l0.9-0.8c0.2,0.2,0.3,0.4,0.5,0.5L252,38.3z M74.9,40.7l-0.8-0.9l0.5-0.5
      l0.8,0.9L74.9,40.7z M255.1,42.2c-0.1-0.2-0.3-0.4-0.4-0.6l1-0.7c0.1,0.2,0.3,0.4,0.4,0.6L255.1,42.2z M71.2,44l-0.8-0.9l0.5-0.5
      l0.8,0.9L71.2,44z M257.8,46.2c-0.1-0.2-0.2-0.4-0.4-0.6l1-0.6c0.1,0.2,0.3,0.4,0.4,0.6L257.8,46.2z M67.6,47.4l-0.8-0.9l0.5-0.5
      l0.8,0.9L67.6,47.4z M260.3,50.5c-0.1-0.2-0.2-0.4-0.4-0.6l1-0.6c0.1,0.2,0.2,0.4,0.4,0.6L260.3,50.5z M64,50.8L63.2,50l0.5-0.5
      l0.8,0.9L64,50.8z M60.8,54.5l-0.9-0.8c0.2-0.2,0.3-0.4,0.5-0.6l0.9,0.8C61.1,54.1,60.9,54.3,60.8,54.5L60.8,54.5z M263.1,54.6
      c-0.1-0.2-0.3-0.4-0.4-0.6l1-0.7c0.1,0.2,0.3,0.4,0.4,0.6L263.1,54.6z M57.9,58.4l-1-0.7c0.1-0.2,0.3-0.4,0.4-0.6l1,0.7
      C58.1,58,58,58.2,57.9,58.4L57.9,58.4z M266.3,58.5c-0.2-0.2-0.3-0.4-0.5-0.5l0.9-0.8c0.2,0.2,0.3,0.4,0.5,0.5L266.3,58.5z
      M269.6,62.2l-0.5-0.5l0.9-0.8l0.5,0.5L269.6,62.2z M55.2,62.5l-1-0.6c0.1-0.2,0.2-0.4,0.4-0.6l1,0.6
      C55.5,62.1,55.4,62.3,55.2,62.5z M273.1,65.7l-0.5-0.5l0.8-0.8l0.5,0.5L273.1,65.7z M52.9,66.9l-1.1-0.5c0.1-0.2,0.2-0.4,0.3-0.6
      l1.1,0.5C53.1,66.4,53,66.7,52.9,66.9L52.9,66.9z M276.6,69.2l-0.5-0.5l0.8-0.8l0.5,0.5L276.6,69.2z M50.8,71.3l-1.1-0.5l0.3-0.6
      l1.1,0.5L50.8,71.3z M280.1,72.8l-0.5-0.5l0.9-0.8l0.5,0.5L280.1,72.8z M48.8,75.8l-1.1-0.5l0.3-0.7l1.1,0.5L48.8,75.8z
      M283.4,76.4c-0.2-0.2-0.3-0.3-0.5-0.5l0.9-0.8c0.2,0.2,0.3,0.4,0.5,0.5L283.4,76.4z M286.6,80.1c-0.1-0.2-0.3-0.4-0.4-0.5l0.9-0.7
      c0.1,0.2,0.3,0.4,0.4,0.6L286.6,80.1z M46.9,80.4l-1.1-0.5l0.3-0.7l1.1,0.5L46.9,80.4z M289.5,84.1c-0.1-0.2-0.3-0.4-0.4-0.6l1-0.7
      c0.1,0.2,0.3,0.4,0.4,0.6L289.5,84.1z M45,85l-1.1-0.5l0.3-0.7l1.1,0.4L45,85z M291.9,88.3c-0.1-0.2-0.2-0.4-0.3-0.6l1.1-0.6
      c0.1,0.2,0.2,0.4,0.3,0.6L291.9,88.3z M43.1,89.6L42,89.1l0.3-0.7l1.1,0.5L43.1,89.6z M293.8,92.8l0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
      l1.1-0.4c0.1,0.2,0.1,0.4,0.2,0.6l0,0.1L293.8,92.8z M41.1,94.1L40,93.6c0.1-0.2,0.2-0.4,0.3-0.6l1.1,0.5
      C41.3,93.7,41.2,93.9,41.1,94.1z M295.4,97.5l-0.2-0.7l1.1-0.4l0.2,0.7L295.4,97.5z M38.9,98.6L37.9,98c0.1-0.2,0.2-0.4,0.3-0.6
      l1.1,0.5C39.2,98.2,39,98.4,38.9,98.6z M297.1,102.2l-0.2-0.7l1.1-0.4l0.2,0.7L297.1,102.2z M36.5,102.9l-1-0.6
      c0.1-0.2,0.2-0.4,0.4-0.6l1,0.6C36.7,102.5,36.6,102.7,36.5,102.9z M298.8,106.8l-0.2-0.7l1.1-0.4l0.2,0.7L298.8,106.8z
      M33.7,107.1l-1-0.7c0.1-0.2,0.3-0.4,0.4-0.6l1,0.7C34,106.7,33.8,106.9,33.7,107.1L33.7,107.1z M30.5,110.9l-0.9-0.8
      c0.1-0.1,0.2-0.3,0.3-0.4l0.1-0.1l0.9,0.8l-0.1,0.2C30.7,110.7,30.6,110.8,30.5,110.9L30.5,110.9z M300.5,111.5l-0.2-0.7l1.1-0.4
      l0.2,0.7L300.5,111.5z M27.4,114.7l-1-0.7c0.1-0.2,0.3-0.4,0.4-0.6l1,0.7C27.7,114.4,27.5,114.5,27.4,114.7L27.4,114.7z
      M302.3,116.1l-0.2-0.7l1.1-0.4l0.2,0.7L302.3,116.1z M24.7,118.8l-1-0.6c0.1-0.2,0.2-0.4,0.4-0.6l1,0.6
      C24.9,118.4,24.8,118.6,24.7,118.8z M304,120.8l-0.2-0.7l1.1-0.4l0.2,0.7L304,120.8z M22.5,123.1l-1.1-0.5c0.1-0.2,0.2-0.4,0.3-0.7
      l1.1,0.5C22.7,122.7,22.6,122.9,22.5,123.1L22.5,123.1z M305.6,125.4l-0.2-0.7l1.1-0.4l0.2,0.7L305.6,125.4z M20.7,127.7l-1.1-0.4
      c0.1-0.2,0.1-0.5,0.2-0.7l1.1,0.4C20.9,127.3,20.8,127.5,20.7,127.7L20.7,127.7z M307.1,130.1l-0.2-0.7l1.1-0.4l0.2,0.7
      L307.1,130.1z M19.5,132.4l-1.2-0.2c0-0.2,0.1-0.5,0.2-0.7l1.2,0.3C19.6,132,19.5,132.2,19.5,132.4L19.5,132.4z M308.6,134.8
      l-0.2-0.7l1.2-0.3l0.2,0.7L308.6,134.8z M18.7,137.3l-1.2-0.1c0-0.2,0.1-0.5,0.1-0.7l1.2,0.1C18.8,136.8,18.7,137,18.7,137.3
      L18.7,137.3z M309.8,139.6c-0.1-0.2-0.1-0.5-0.2-0.7l1.2-0.3c0.1,0.2,0.1,0.5,0.2,0.7L309.8,139.6z M18.4,142.1l-1.2,0
      c0-0.2,0-0.5,0-0.7l1.2,0C18.4,141.7,18.4,141.9,18.4,142.1L18.4,142.1z M310.9,144.4c0-0.2-0.1-0.5-0.1-0.7l1.2-0.2
      c0,0.2,0.1,0.5,0.1,0.7L310.9,144.4z M17.3,147.1c0-0.2,0-0.5,0-0.7l1.2-0.1c0,0.2,0,0.5,0,0.7L17.3,147.1z M311.8,149.3
      c0-0.2-0.1-0.5-0.1-0.7l1.2-0.2c0,0.2,0.1,0.5,0.1,0.7L311.8,149.3z M17.7,152.1c0-0.2-0.1-0.5-0.1-0.7l1.2-0.1
      c0,0.2,0.1,0.5,0.1,0.7L17.7,152.1z M312.3,154.2c0-0.2,0-0.5-0.1-0.7l1.2-0.1c0,0.2,0,0.5,0.1,0.7L312.3,154.2z M18.5,157
      c0-0.2-0.1-0.5-0.1-0.7l1.2-0.2c0,0.2,0.1,0.5,0.1,0.7L18.5,157z M313.7,159.1l-1.2,0v-0.2c0-0.2,0-0.3,0-0.5l1.2,0
      c0,0.2,0,0.3,0,0.5L313.7,159.1z M19.4,161.9c-0.1-0.2-0.1-0.5-0.2-0.7l1.2-0.2c0,0.2,0.1,0.5,0.1,0.7L19.4,161.9z M313.4,164.1
      l-1.2-0.1c0-0.2,0-0.5,0.1-0.7l1.2,0.1C313.5,163.6,313.5,163.8,313.4,164.1z M20.6,166.8l-0.2-0.7l1.2-0.3l0.2,0.7L20.6,166.8z
      M312.6,169l-1.2-0.3c0-0.2,0.1-0.5,0.1-0.7l1.2,0.2C312.7,168.6,312.7,168.8,312.6,169z M21.9,171.6l-0.2-0.7l1.2-0.3l0.2,0.7
      L21.9,171.6z M311.2,173.8l-1.1-0.4c0.1-0.2,0.2-0.4,0.2-0.7l1.1,0.4C311.3,173.4,311.2,173.6,311.2,173.8z M23.3,176.3l-0.2-0.7
      l1.1-0.3l0.2,0.7L23.3,176.3z M309,178.4l-1-0.6c0.1-0.2,0.2-0.4,0.3-0.6l1.1,0.6C309.2,178,309.1,178.2,309,178.4L309,178.4z
      M24.7,181.1l-0.2-0.7l1.1-0.4l0.2,0.7L24.7,181.1z M306.2,182.6l-0.9-0.7c0.1-0.2,0.3-0.4,0.4-0.5l1,0.7
      C306.5,182.2,306.3,182.4,306.2,182.6z M26.2,185.8l-0.2-0.7l1.1-0.4l0.2,0.7L26.2,185.8z M302.8,186.3l-0.9-0.8
      c0.2-0.2,0.3-0.4,0.5-0.5l0.9,0.8C303.1,185.9,303,186.1,302.8,186.3L302.8,186.3z M300,190.1l-1-0.6c0.1-0.2,0.3-0.4,0.4-0.6
      l1,0.6C300.2,189.7,300.1,189.9,300,190.1z M27.8,190.5l-0.2-0.7l1.1-0.4l0.2,0.7L27.8,190.5z M297.8,194.5l-1.1-0.5
      c0.1-0.2,0.2-0.4,0.3-0.7l1.1,0.5C297.9,194,297.9,194.2,297.8,194.5z M29.2,195.2l-0.2-0.7l1.1-0.4l0.2,0.7L29.2,195.2z
      M296.1,199.1l-1.1-0.3c0.1-0.2,0.1-0.5,0.2-0.7l1.1,0.4C296.3,198.6,296.2,198.8,296.1,199.1z M30.6,200l-0.2-0.7l1.1-0.3l0.2,0.7
      L30.6,200z M294.9,203.8l-1.2-0.3c0.1-0.2,0.1-0.5,0.2-0.7l1.2,0.3C295,203.4,294.9,203.6,294.9,203.8z M32,204.7
      c-0.1-0.2-0.1-0.5-0.2-0.7l1.1-0.4c0.1,0.2,0.1,0.4,0.2,0.7L32,204.7z M293.9,208.6l-1.2-0.2c0-0.2,0.1-0.5,0.1-0.7l1.2,0.2
      C294,208.2,294,208.4,293.9,208.6z M33.7,209.4c-0.1-0.2-0.2-0.4-0.3-0.7l1.1-0.4c0.1,0.2,0.2,0.4,0.3,0.7L33.7,209.4z
      M293.2,213.5l-1.2-0.2c0-0.2,0.1-0.5,0.1-0.7l1.2,0.2L293.2,213.5z M35.6,214c-0.1-0.2-0.2-0.4-0.3-0.7l1.1-0.5
      c0.1,0.2,0.2,0.4,0.3,0.6L35.6,214z M292.5,218.4l-1.2-0.1l0.1-0.7l1.2,0.2L292.5,218.4z M37.7,218.6l-0.3-0.6l1.1-0.5l0.3,0.6
      L37.7,218.6z M39.9,223l-0.3-0.6l1.1-0.5l0.3,0.6L39.9,223z M291.9,223.3l-1.2-0.1l0.1-0.7l1.2,0.1L291.9,223.3z M42.3,227.4
      l-0.3-0.6l1.1-0.6l0.3,0.6L42.3,227.4z M291.3,228.3l-1.2-0.2l0.1-0.7l1.2,0.2L291.3,228.3z M44.8,231.7l-0.4-0.6l1-0.6l0.4,0.6
      L44.8,231.7z M290.6,233.2l-1.2-0.2l0.1-0.7l1.2,0.2L290.6,233.2z M47.4,236l-0.4-0.6l1-0.6l0.4,0.6L47.4,236z M289.8,238.1
      l-1.2-0.2l0.1-0.7l1.2,0.2L289.8,238.1z M50.1,240.1l-0.4-0.6l1-0.7l0.4,0.6L50.1,240.1z M288.8,243l-1.2-0.3
      c0-0.2,0.1-0.5,0.2-0.7l1.2,0.3C288.9,242.5,288.9,242.8,288.8,243L288.8,243z M52.9,244.2l-0.4-0.6l1-0.7l0.4,0.6L52.9,244.2z
      M287.6,247.8l-1.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7l1.2,0.3C287.7,247.4,287.6,247.6,287.6,247.8L287.6,247.8z M55.8,248.3l-0.4-0.6
      l1-0.7l0.4,0.6L55.8,248.3z M58.9,252.2l-0.4-0.6l0.9-0.7l0.4,0.6L58.9,252.2z M285.9,252.5l-1.1-0.4c0.1-0.2,0.2-0.4,0.3-0.6
      l1.1,0.4C286.1,252.1,286,252.3,285.9,252.5L285.9,252.5z M62,256l-0.5-0.5l0.9-0.8l0.5,0.5L62,256z M283.8,257.1l-1-0.6
      c0.1-0.2,0.2-0.4,0.3-0.6l1.1,0.6C284,256.7,283.9,256.9,283.8,257.1z M65.3,259.7c-0.2-0.2-0.3-0.3-0.5-0.5l0.9-0.8
      c0.2,0.2,0.3,0.3,0.5,0.5L65.3,259.7z M281,261.3l-0.9-0.7c0.1-0.2,0.3-0.4,0.4-0.6l1,0.7C281.3,260.9,281.1,261.1,281,261.3
      L281,261.3z M68.8,263.3c-0.2-0.2-0.3-0.3-0.5-0.5l0.9-0.8c0.2,0.2,0.3,0.3,0.5,0.5L68.8,263.3z M278.9,263.7l-0.9-0.8
      c0.2-0.2,0.3-0.3,0.5-0.5l0.9,0.8C279.3,263.3,279.1,263.5,278.9,263.7z M72.3,266.8c-0.2-0.2-0.3-0.3-0.5-0.5l0.8-0.9
      c0.2,0.2,0.3,0.3,0.5,0.5L72.3,266.8z M275.2,267l-0.8-0.9c0.2-0.1,0.4-0.3,0.5-0.4l0.8,0.9C275.6,266.7,275.4,266.9,275.2,267
      L275.2,267z M271.2,270l-0.7-1c0.2-0.1,0.4-0.3,0.6-0.4l0.7,1C271.6,269.7,271.4,269.9,271.2,270L271.2,270z M76,270.2
      c-0.2-0.2-0.4-0.3-0.5-0.5l0.8-0.9c0.2,0.2,0.4,0.3,0.5,0.5L76,270.2z M267,272.7l-0.6-1c0.2-0.1,0.4-0.2,0.6-0.4l0.6,1
      C267.4,272.4,267.2,272.6,267,272.7L267,272.7z M79.9,273.3c-0.2-0.1-0.4-0.3-0.6-0.4l0.7-0.9c0.2,0.1,0.4,0.3,0.6,0.4L79.9,273.3z
      M262.6,275.1l-0.6-1.1l0.6-0.3l0.6,1.1L262.6,275.1z M83.9,276.3c-0.2-0.1-0.4-0.3-0.6-0.4l0.7-1c0.2,0.1,0.4,0.3,0.6,0.4
      L83.9,276.3z M258.2,277.3l-0.5-1.1c0.2-0.1,0.4-0.2,0.6-0.3l0.5,1.1C258.6,277.1,258.4,277.2,258.2,277.3L258.2,277.3z M88,279.1
      c-0.2-0.1-0.4-0.3-0.6-0.4l0.6-1c0.2,0.1,0.4,0.3,0.6,0.4L88,279.1z M253.6,279.4l-0.5-1.1l0.6-0.3l0.5,1.1L253.6,279.4z
      M249.1,281.3l-0.5-1.1l0.7-0.3l0.5,1.1L249.1,281.3z M92.3,281.6c-0.2-0.1-0.4-0.2-0.6-0.3l0.6-1c0.2,0.1,0.4,0.2,0.6,0.3
      L92.3,281.6z M244.5,283.2l-0.4-1.1l0.7-0.3l0.4,1.1L244.5,283.2z M96.7,283.9c-0.2-0.1-0.4-0.2-0.6-0.3l0.5-1.1
      c0.2,0.1,0.4,0.2,0.6,0.3L96.7,283.9z M239.8,285l-0.4-1.1l0.7-0.3l0.4,1.1L239.8,285z M101.3,285.9c-0.2-0.1-0.4-0.2-0.7-0.3
      l0.5-1.1c0.2,0.1,0.4,0.2,0.6,0.3L101.3,285.9z M235.2,286.8l-0.4-1.1l0.7-0.3l0.4,1.1L235.2,286.8z M106,287.6
      c-0.2-0.1-0.5-0.1-0.7-0.2l0.4-1.1c0.2,0.1,0.4,0.1,0.7,0.2L106,287.6z M230.6,288.5l-0.4-1.1l0.7-0.3l0.4,1.1L230.6,288.5z
      M110.8,289c-0.2-0.1-0.5-0.1-0.7-0.2l0.3-1.2c0.2,0.1,0.5,0.1,0.7,0.2L110.8,289z M115.7,290c-0.2,0-0.5-0.1-0.7-0.1l0.2-1.2
      c0.2,0,0.5,0.1,0.7,0.1L115.7,290z M226,290.3l-0.4-1.1l0.7-0.3l0.4,1.1L226,290.3z M120.6,290.6c-0.2,0-0.5,0-0.7-0.1l0.1-1.2
      c0.2,0,0.5,0,0.7,0.1L120.6,290.6z M135.5,290.7c-0.2,0-0.5,0-0.7,0l0-1.2c0.2,0,0.5,0,0.7,0L135.5,290.7z M129.9,290.8l-0.1-1.2
      c0.2,0,0.5,0,0.7,0l0.1,1.2C130.4,290.8,130.1,290.8,129.9,290.8z M125.6,290.9c-0.2,0-0.5,0-0.7,0l0-1.2c0.2,0,0.5,0,0.7,0
      L125.6,290.9z M140.4,291.1c-0.2,0-0.5-0.1-0.7-0.1l0.1-1.2c0.2,0,0.5,0.1,0.7,0.1L140.4,291.1z M145.2,291.8
      c-0.2,0-0.5-0.1-0.7-0.1l0.2-1.2c0.2,0,0.5,0.1,0.7,0.1L145.2,291.8z M221.4,292.1l-0.5-1.1l0.7-0.3l0.5,1.1L221.4,292.1z
      M150,292.9l-0.7-0.2l0.3-1.2l0.7,0.2L150,292.9z M154.8,294l-0.7-0.2l0.3-1.2l0.7,0.2L154.8,294z M216.8,294.1l-0.5-1.1l0.6-0.3
      l0.5,1.1L216.8,294.1z M159.6,295.3l-0.7-0.2l0.3-1.2l0.7,0.2L159.6,295.3z M212.4,296.2l-0.5-1.1c0.2-0.1,0.4-0.2,0.6-0.3l0.5,1.1
      C212.8,296,212.6,296.1,212.4,296.2L212.4,296.2z M164.4,296.6l-0.7-0.2l0.3-1.2l0.7,0.2L164.4,296.6z M169.2,297.9l-0.7-0.2
      l0.3-1.2l0.7,0.2L169.2,297.9z M207.8,298.3l-0.4-1.1c0.2-0.1,0.4-0.2,0.6-0.3l0.5,1.1C208.3,298.1,208,298.2,207.8,298.3
      L207.8,298.3z M174,299l-0.7-0.2l0.3-1.2l0.7,0.2L174,299z M203,299.8l-0.3-1.2c0.2-0.1,0.4-0.1,0.7-0.2l0.3,1.2
      C203.5,299.7,203.3,299.7,203,299.8z M178.9,300c-0.2,0-0.5-0.1-0.7-0.1l0.2-1.2c0.2,0,0.5,0.1,0.7,0.1L178.9,300z M183.8,300.7
      c-0.2,0-0.5-0.1-0.7-0.1l0.2-1.2c0.2,0,0.5,0.1,0.7,0.1L183.8,300.7z M198.1,300.7l-0.2-1.2c0.2,0,0.5-0.1,0.7-0.1l0.2,1.2
      C198.6,300.7,198.3,300.7,198.1,300.7z M188.8,301.1c-0.2,0-0.5,0-0.7,0l0.1-1.2c0.2,0,0.5,0,0.7,0L188.8,301.1z M193.1,301.2
      l0-1.2c0.2,0,0.5,0,0.7,0l0.1,1.2C193.6,301.1,193.3,301.2,193.1,301.2"/>
      <path fill="#D9F0F5" d="M152.8,4.2c-0.5,0-0.9,0-1.4,0l0-1.3c0.5,0,1,0,1.4,0L152.8,4.2z M146.5,4.3L146.4,3c0.5,0,1-0.1,1.4-0.1
      l0.1,1.3C147.4,4.2,146.9,4.3,146.5,4.3L146.5,4.3z M157.7,4.4c-0.5,0-0.9-0.1-1.4-0.1l0.1-1.2c0.5,0,0.9,0.1,1.4,0.1L157.7,4.4z
      M141.6,4.7l-0.2-1.2c0.5-0.1,0.9-0.1,1.4-0.2l0.1,1.2C142.5,4.6,142,4.7,141.6,4.7z M162.6,4.8c-0.5,0-0.9-0.1-1.4-0.1l0.1-1.2
      c0.5,0,0.9,0.1,1.4,0.1L162.6,4.8z M167.5,5.4c-0.5-0.1-0.9-0.1-1.4-0.2l0.1-1.2c0.5,0.1,0.9,0.1,1.4,0.2L167.5,5.4z M136.7,5.5
      l-0.2-1.2c0.5-0.1,0.9-0.2,1.4-0.2l0.2,1.2C137.6,5.3,137.2,5.4,136.7,5.5L136.7,5.5z M172.4,6.1C172,6,171.5,5.9,171,5.9l0.2-1.2
      c0.5,0.1,0.9,0.1,1.4,0.2L172.4,6.1z M131.9,6.5l-0.3-1.2c0.5-0.1,0.9-0.2,1.4-0.3l0.3,1.2C132.8,6.3,132.4,6.4,131.9,6.5
      L131.9,6.5z M177.3,6.9c-0.5-0.1-0.9-0.2-1.4-0.3l0.2-1.2c0.5,0.1,0.9,0.2,1.4,0.3L177.3,6.9z M127.2,7.8l-0.4-1.2
      c0.5-0.1,0.9-0.3,1.4-0.4l0.3,1.2C128.1,7.5,127.6,7.7,127.2,7.8L127.2,7.8z M182.1,7.9c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2
      c0.5,0.1,0.9,0.2,1.4,0.3L182.1,7.9z M186.9,9c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2c0.5,0.1,0.9,0.2,1.4,0.3L186.9,9z M122.5,9.3
      l-0.4-1.2c0.4-0.2,0.9-0.3,1.3-0.5l0.4,1.2C123.4,9,122.9,9.1,122.5,9.3L122.5,9.3z M191.7,10.2c-0.5-0.1-0.9-0.2-1.4-0.4l0.3-1.2
      c0.5,0.1,0.9,0.2,1.4,0.4L191.7,10.2z M117.9,11l-0.5-1.2c0.4-0.2,0.9-0.3,1.3-0.5l0.4,1.2C118.7,10.7,118.3,10.8,117.9,11z
      M196.4,11.6c-0.4-0.1-0.9-0.3-1.4-0.4l0.4-1.2c0.5,0.1,0.9,0.3,1.4,0.4L196.4,11.6z M113.3,12.9l-0.5-1.1c0.4-0.2,0.9-0.4,1.3-0.6
      l0.5,1.2C114.2,12.5,113.7,12.7,113.3,12.9L113.3,12.9z M201.1,13.1c-0.4-0.2-0.9-0.3-1.3-0.4l0.4-1.2c0.5,0.1,0.9,0.3,1.4,0.5
      L201.1,13.1z M205.8,14.8c-0.4-0.2-0.9-0.3-1.3-0.5l0.4-1.2c0.4,0.2,0.9,0.3,1.3,0.5L205.8,14.8z M108.8,15l-0.5-1.1
      c0.4-0.2,0.9-0.4,1.3-0.6l0.5,1.1C109.7,14.6,109.3,14.8,108.8,15L108.8,15z M210.4,16.6c-0.4-0.2-0.9-0.4-1.3-0.5l0.5-1.2
      c0.4,0.2,0.9,0.4,1.3,0.5L210.4,16.6z M104.4,17.2l-0.6-1.1c0.4-0.2,0.8-0.4,1.3-0.7l0.6,1.1C105.3,16.8,104.9,17,104.4,17.2z
      M214.9,18.6c-0.4-0.2-0.8-0.4-1.3-0.6l0.5-1.1c0.4,0.2,0.9,0.4,1.3,0.6L214.9,18.6z M100.1,19.6l-0.6-1.1c0.4-0.2,0.8-0.5,1.2-0.7
      l0.6,1.1C100.9,19.2,100.5,19.4,100.1,19.6z M219.3,20.7c-0.4-0.2-0.8-0.4-1.3-0.6l0.6-1.1c0.4,0.2,0.9,0.4,1.3,0.6L219.3,20.7z
      M95.9,22.2l-0.7-1.1c0.4-0.2,0.8-0.5,1.2-0.7l0.6,1.1C96.7,21.7,96.3,21.9,95.9,22.2L95.9,22.2z M223.7,23.1
      c-0.4-0.2-0.8-0.5-1.2-0.7l0.6-1.1c0.4,0.2,0.8,0.5,1.2,0.7L223.7,23.1z M91.7,24.8l-0.7-1c0.4-0.3,0.8-0.5,1.2-0.8l0.7,1.1
      C92.5,24.3,92.1,24.6,91.7,24.8L91.7,24.8z M227.9,25.6c-0.4-0.2-0.8-0.5-1.2-0.7l0.7-1.1c0.4,0.2,0.8,0.5,1.2,0.8L227.9,25.6z
      M87.6,27.6l-0.7-1c0.4-0.3,0.8-0.5,1.2-0.8l0.7,1C88.4,27.1,88,27.3,87.6,27.6L87.6,27.6z M232,28.3c-0.4-0.3-0.8-0.5-1.2-0.8
      l0.7-1c0.4,0.3,0.8,0.5,1.2,0.8L232,28.3z M83.6,30.5l-0.7-1c0.4-0.3,0.8-0.6,1.1-0.8l0.7,1C84.4,29.9,84,30.2,83.6,30.5L83.6,30.5
      z M235.9,31.3c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-1c0.4,0.3,0.8,0.6,1.1,0.9L235.9,31.3z M79.7,33.5l-0.8-1c0.4-0.3,0.7-0.6,1.1-0.9
      l0.8,1C80.4,32.9,80,33.2,79.7,33.5z M239.7,34.4c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-0.9c0.4,0.3,0.7,0.6,1.1,0.9L239.7,34.4z
      M75.8,36.5l-0.8-1c0.4-0.3,0.7-0.6,1.1-0.9l0.8,1C76.5,35.9,76.2,36.2,75.8,36.5z M243.2,37.8c-0.3-0.3-0.7-0.7-1-1l0.9-0.9
      c0.3,0.3,0.7,0.7,1,1L243.2,37.8z M72,39.7l-0.8-0.9c0.4-0.3,0.7-0.6,1.1-0.9l0.8,1C72.7,39.1,72.4,39.4,72,39.7L72,39.7z
      M246.5,41.5c-0.3-0.4-0.6-0.7-0.9-1.1l0.9-0.8c0.3,0.4,0.6,0.7,0.9,1.1L246.5,41.5z M68.3,43L67.5,42c0.3-0.3,0.7-0.6,1.1-0.9
      l0.8,0.9C69,42.3,68.7,42.6,68.3,43z M249.5,45.3L249.5,45.3c-0.3-0.4-0.6-0.8-0.8-1.1l1-0.8c0.3,0.4,0.5,0.7,0.8,1.1l0.1,0.1
      L249.5,45.3z M64.7,46.3l-0.9-0.9c0.3-0.3,0.7-0.6,1-1l0.9,0.9C65.4,45.7,65,46,64.7,46.3z M252.7,49.2c-0.3-0.4-0.6-0.7-0.9-1.1
      l1-0.8c0.3,0.4,0.6,0.7,0.9,1.1L252.7,49.2z M61.1,49.7l-0.9-0.9c0.3-0.3,0.7-0.7,1-1l0.9,0.9C61.8,49.1,61.5,49.4,61.1,49.7
      L61.1,49.7z M256.1,52.9c-0.3-0.3-0.7-0.7-1-1L256,51c0.3,0.3,0.7,0.7,1,1L256.1,52.9z M57.7,53.2l-0.9-0.9l0.1-0.1
      c0.3-0.3,0.6-0.6,0.9-0.9l0.9,0.9c-0.3,0.3-0.6,0.6-0.9,0.9L57.7,53.2z M259.7,56.3c-0.4-0.3-0.7-0.6-1.1-1l0.8-0.9
      c0.3,0.3,0.7,0.6,1,0.9L259.7,56.3z M54.5,56.9l-1-0.8c0.3-0.4,0.6-0.7,0.9-1.1l1,0.8C55.1,56.2,54.8,56.6,54.5,56.9z M263.5,59.5
      c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-0.9c0.4,0.3,0.7,0.6,1.1,0.9L263.5,59.5z M51.6,60.9l-1-0.7c0.3-0.4,0.5-0.8,0.8-1.2l1,0.7
      C52.1,60.1,51.9,60.5,51.6,60.9L51.6,60.9z M267.3,62.7l-1.1-0.9l0.8-1l1.1,0.9L267.3,62.7z M49.1,65.1L48,64.5
      c0.2-0.4,0.5-0.8,0.7-1.2l1.1,0.6C49.5,64.3,49.3,64.7,49.1,65.1L49.1,65.1z M271,65.9c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-1
      c0.4,0.3,0.7,0.6,1.1,0.9L271,65.9z M274.8,69.2c-0.3-0.3-0.7-0.6-1.1-0.9l0.8-0.9c0.4,0.3,0.7,0.6,1.1,0.9L274.8,69.2z M46.8,69.5
      l-1.1-0.5c0.2-0.4,0.4-0.9,0.6-1.3l1.1,0.6C47.2,68.6,47,69.1,46.8,69.5z M278.4,72.5c-0.3-0.3-0.7-0.7-1-1l0.9-0.9
      c0.3,0.3,0.7,0.7,1,1L278.4,72.5z M44.8,74l-1.2-0.5c0.2-0.4,0.4-0.9,0.6-1.3l1.1,0.5C45.2,73.1,45,73.6,44.8,74z M281.8,76.1
      c-0.3-0.3-0.6-0.7-0.9-1l0.9-0.9c0.3,0.3,0.6,0.7,1,1.1L281.8,76.1z M43,78.6l-1.2-0.4c0.2-0.4,0.3-0.9,0.5-1.3l1.2,0.5
      C43.4,77.7,43.2,78.1,43,78.6L43,78.6z M284.9,79.9c-0.3-0.4-0.6-0.7-0.9-1.1l1-0.8c0.3,0.4,0.6,0.8,0.9,1.1L284.9,79.9z
      M41.3,83.2l-1.2-0.4c0.2-0.4,0.3-0.9,0.5-1.3l1.2,0.4C41.6,82.3,41.5,82.8,41.3,83.2L41.3,83.2z M287.5,83.9
      c-0.2-0.4-0.5-0.8-0.7-1.2l1.1-0.7c0.3,0.4,0.5,0.8,0.7,1.2L287.5,83.9z M39.7,87.9l-1.2-0.4l0.5-1.3l1.2,0.4L39.7,87.9z
      M289.7,88.3c-0.2-0.4-0.4-0.9-0.5-1.3l1.1-0.5c0.2,0.4,0.4,0.9,0.6,1.3L289.7,88.3z M38.2,92.6L37,92.2l0.4-1.3l1.2,0.4L38.2,92.6
      z M291.1,92.9l-0.1-0.5c-0.1-0.3-0.1-0.6-0.2-0.8l1.2-0.3c0.1,0.3,0.2,0.6,0.2,0.9l0.1,0.5L291.1,92.9z M36.6,97.3l-1.2-0.4
      c0.2-0.4,0.3-0.9,0.5-1.3l1.2,0.4C36.9,96.4,36.7,96.8,36.6,97.3z M292.4,97.8c-0.1-0.5-0.3-0.9-0.4-1.4l1.2-0.3
      c0.1,0.5,0.3,0.9,0.4,1.4L292.4,97.8z M34.9,102l-1.2-0.4c0.2-0.4,0.3-0.9,0.5-1.3l1.2,0.4C35.2,101.1,35.1,101.5,34.9,102
      L34.9,102z M293.9,102.5c-0.1-0.5-0.3-0.9-0.4-1.4l1.2-0.4c0.1,0.4,0.3,0.9,0.4,1.3L293.9,102.5z M33.1,106.6l-1.2-0.5
      c0.2-0.4,0.4-0.9,0.5-1.3l1.2,0.5C33.4,105.7,33.3,106.2,33.1,106.6L33.1,106.6z M295.4,107.2c-0.2-0.4-0.3-0.9-0.4-1.3l1.2-0.4
      c0.1,0.4,0.3,0.9,0.5,1.3L295.4,107.2z M31,111.1l-1.1-0.5c0.2-0.4,0.4-0.8,0.6-1.3l1.1,0.5C31.4,110.3,31.2,110.7,31,111.1z
      M297.1,111.9l-0.5-1.3l1.2-0.4l0.5,1.3L297.1,111.9z M28.7,115.6l-1.1-0.6c0.2-0.4,0.5-0.8,0.7-1.2l1.1,0.6
      C29.2,114.7,29,115.2,28.7,115.6L28.7,115.6z M298.7,116.6l-0.5-1.3l1.2-0.4l0.5,1.3L298.7,116.6z M26.1,119.8l-1-0.7
      c0.3-0.4,0.5-0.8,0.8-1.2l1,0.7C26.6,119,26.3,119.4,26.1,119.8z M300.3,121.2l-0.5-1.3l1.2-0.4l0.5,1.3L300.3,121.2z M23.4,123.9
      l-1.1-0.6c0.2-0.4,0.5-0.8,0.7-1.2l1.1,0.7C23.9,123.1,23.6,123.5,23.4,123.9L23.4,123.9z M302,125.9c-0.2-0.4-0.3-0.9-0.5-1.3
      l1.2-0.4c0.2,0.4,0.3,0.9,0.5,1.3L302,125.9z M21.2,128.3l-1.1-0.5c0.2-0.4,0.4-0.9,0.6-1.3l1.1,0.5
      C21.6,127.4,21.4,127.9,21.2,128.3L21.2,128.3z M303.5,130.6c-0.1-0.4-0.3-0.9-0.4-1.3l1.2-0.4c0.1,0.5,0.3,0.9,0.4,1.4
      L303.5,130.6z M19.5,132.8l-1.2-0.4c0.1-0.5,0.3-0.9,0.5-1.4l1.2,0.4C19.8,132,19.6,132.4,19.5,132.8L19.5,132.8z M304.9,135.4
      c-0.1-0.4-0.3-0.9-0.4-1.4l1.2-0.4c0.1,0.5,0.3,0.9,0.4,1.4L304.9,135.4z M18.2,137.6l-1.2-0.3c0.1-0.5,0.2-0.9,0.3-1.4l1.2,0.3
      C18.4,136.7,18.3,137.1,18.2,137.6z M306.3,140.1c-0.1-0.5-0.2-0.9-0.4-1.4l1.2-0.3c0.1,0.5,0.2,0.9,0.4,1.4L306.3,140.1z
      M17.5,142.4l-1.2-0.1c0.1-0.5,0.1-0.9,0.2-1.4l1.2,0.2C17.6,141.5,17.5,141.9,17.5,142.4z M307.4,144.9c-0.1-0.5-0.2-0.9-0.3-1.4
      l1.2-0.3c0.1,0.5,0.2,0.9,0.3,1.4L307.4,144.9z M17.1,147.3l-1.3,0c0-0.5,0-1,0.1-1.4l1.3,0.1C17.2,146.3,17.2,146.8,17.1,147.3
      L17.1,147.3z M308.3,149.7c-0.1-0.5-0.1-0.9-0.2-1.4l1.2-0.2c0.1,0.5,0.2,0.9,0.2,1.4L308.3,149.7z M16,152.3c0-0.5,0-1-0.1-1.4
      l1.3,0c0,0.5,0,0.9,0.1,1.4L16,152.3z M308.9,154.6c0-0.5-0.1-0.9-0.1-1.4l1.2-0.1c0,0.5,0.1,0.9,0.1,1.4L308.9,154.6z M16.4,157.2
      c-0.1-0.5-0.1-0.9-0.2-1.4l1.2-0.1c0,0.5,0.1,0.9,0.1,1.4L16.4,157.2z M310.3,159.5l-1.2,0v-0.1c0-0.4,0-0.8,0-1.3l1.2,0
      c0,0.4,0,0.9,0,1.3L310.3,159.5z M17.1,162.2c-0.1-0.5-0.2-0.9-0.2-1.4l1.2-0.2c0.1,0.5,0.1,0.9,0.2,1.4L17.1,162.2z M310,164.5
      l-1.2-0.1c0.1-0.5,0.1-0.9,0.1-1.4l1.2,0.1C310.1,163.6,310.1,164,310,164.5L310,164.5z M18,167.1c-0.1-0.5-0.2-0.9-0.3-1.4
      l1.2-0.2c0.1,0.5,0.2,0.9,0.3,1.4L18,167.1z M309.2,169.5l-1.2-0.3c0.1-0.4,0.2-0.9,0.3-1.4l1.2,0.2
      C309.4,168.5,309.3,169,309.2,169.5L309.2,169.5z M19.1,171.9c-0.1-0.5-0.2-0.9-0.3-1.4l1.2-0.3c0.1,0.5,0.2,0.9,0.3,1.4
      L19.1,171.9z M307.6,174.3l-1.2-0.5c0.2-0.4,0.3-0.9,0.5-1.3l1.2,0.4C308,173.4,307.8,173.8,307.6,174.3z M20.4,176.7
      c-0.1-0.5-0.2-0.9-0.4-1.4l1.2-0.3c0.1,0.5,0.2,0.9,0.4,1.4L20.4,176.7z M305.4,178.8l-1.1-0.6c0.2-0.4,0.5-0.8,0.7-1.2l1.1,0.6
      C305.9,178,305.6,178.4,305.4,178.8z M21.7,181.5c-0.1-0.5-0.3-0.9-0.4-1.4l1.2-0.3c0.1,0.5,0.3,0.9,0.4,1.4L21.7,181.5z
      M302.5,182.9l-1-0.8c0.1-0.2,0.2-0.3,0.4-0.5c0.2-0.2,0.3-0.4,0.5-0.7l1,0.8c-0.2,0.2-0.4,0.5-0.5,0.7
      C302.7,182.6,302.6,182.7,302.5,182.9z M23,186.3l-0.4-1.4l1.2-0.3l0.4,1.4L23,186.3z M299.9,187l-1.1-0.6c0.2-0.4,0.5-0.9,0.7-1.3
      l1.1,0.6C300.4,186.2,300.1,186.6,299.9,187L299.9,187z M24.4,191l-0.4-1.4l1.2-0.3l0.4,1.4L24.4,191z M298,191.4l-1.2-0.4
      c0.2-0.5,0.3-0.9,0.5-1.3l1.2,0.5C298.3,190.6,298.1,191,298,191.4z M25.7,195.8c-0.1-0.5-0.2-0.9-0.4-1.4l1.2-0.3
      c0.1,0.5,0.3,0.9,0.4,1.4L25.7,195.8z M296.5,196.1l-1.2-0.3c0.1-0.5,0.3-0.9,0.4-1.4l1.2,0.4C296.8,195.2,296.6,195.7,296.5,196.1
      z M27,200.6c-0.1-0.5-0.2-0.9-0.3-1.4l1.2-0.3c0.1,0.5,0.2,0.9,0.4,1.4L27,200.6z M295.4,200.9l-1.2-0.3c0.1-0.5,0.2-0.9,0.3-1.4
      l1.2,0.3C295.6,200,295.4,200.4,295.4,200.9L295.4,200.9z M28.2,205.4c-0.1-0.5-0.2-0.9-0.3-1.4l1.2-0.3c0.1,0.5,0.2,0.9,0.3,1.4
      L28.2,205.4z M294.4,205.7l-1.2-0.2c0.1-0.5,0.2-0.9,0.2-1.4l1.2,0.2C294.6,204.8,294.5,205.3,294.4,205.7z M29.3,210.2
      c-0.1-0.5-0.3-0.9-0.4-1.4l1.2-0.3c0.1,0.4,0.2,0.9,0.4,1.4L29.3,210.2z M293.7,210.6l-1.2-0.2c0.1-0.5,0.1-0.9,0.2-1.4l1.2,0.2
      C293.8,209.7,293.7,210.2,293.7,210.6z M30.8,215c-0.2-0.5-0.3-0.9-0.5-1.3l1.2-0.4c0.1,0.4,0.3,0.9,0.5,1.3L30.8,215z M293,215.5
      l-1.2-0.2l0.2-1.4l1.2,0.2L293,215.5z M32.6,219.6c-0.2-0.4-0.4-0.9-0.5-1.3l1.2-0.5c0.2,0.4,0.4,0.9,0.5,1.3L32.6,219.6z
      M292.3,220.4l-1.2-0.2l0.2-1.4l1.2,0.2L292.3,220.4z M34.7,224.2c-0.2-0.4-0.4-0.9-0.6-1.3l1.1-0.5c0.2,0.4,0.4,0.8,0.6,1.3
      L34.7,224.2z M291.6,225.4l-1.2-0.2c0.1-0.5,0.1-0.9,0.2-1.4l1.2,0.2C291.8,224.4,291.7,224.9,291.6,225.4z M36.9,228.6
      c-0.2-0.4-0.4-0.8-0.7-1.3l1.1-0.6c0.2,0.4,0.4,0.8,0.6,1.3L36.9,228.6z M290.9,230.3l-1.2-0.2c0.1-0.5,0.2-0.9,0.2-1.4l1.2,0.2
      C291,229.3,290.9,229.8,290.9,230.3L290.9,230.3z M39.3,233c-0.2-0.4-0.5-0.8-0.7-1.2l1.1-0.6c0.2,0.4,0.5,0.8,0.7,1.2L39.3,233z
      M289.9,235.2l-1.2-0.3c0.1-0.5,0.2-0.9,0.3-1.4l1.2,0.2C290.1,234.2,290,234.7,289.9,235.2z M41.8,237.3c-0.2-0.4-0.5-0.8-0.7-1.2
      l1.1-0.6c0.2,0.4,0.5,0.8,0.7,1.2L41.8,237.3z M288.8,240l-1.2-0.3c0.1-0.5,0.2-0.9,0.4-1.4l1.2,0.3
      C289,239.1,288.9,239.5,288.8,240z M44.5,241.5c-0.3-0.4-0.5-0.8-0.8-1.2l1.1-0.7c0.3,0.4,0.5,0.8,0.8,1.2L44.5,241.5z
      M287.3,244.8l-1.2-0.4c0.2-0.4,0.3-0.9,0.4-1.3l1.2,0.4C287.6,243.9,287.5,244.3,287.3,244.8z M47.3,245.6
      c-0.3-0.4-0.5-0.8-0.8-1.2l1-0.7c0.3,0.4,0.5,0.8,0.8,1.2L47.3,245.6z M285.5,249.4l-1.1-0.5c0.2-0.4,0.4-0.8,0.6-1.3l1.2,0.5
      C285.9,248.6,285.7,249,285.5,249.4L285.5,249.4z M50.2,249.6c-0.3-0.4-0.6-0.8-0.9-1.1l1-0.7c0.3,0.4,0.6,0.8,0.8,1.1L50.2,249.6z
      M53.3,253.5c-0.3-0.4-0.6-0.7-0.9-1.1l1-0.8c0.3,0.4,0.6,0.7,0.9,1.1L53.3,253.5z M283.1,253.9l-1.1-0.7c0.2-0.4,0.5-0.8,0.7-1.2
      l1.1,0.6C283.6,253.1,283.3,253.5,283.1,253.9L283.1,253.9z M56.5,257.4c-0.3-0.4-0.6-0.7-0.9-1.1l1-0.8c0.3,0.4,0.6,0.7,0.9,1.1
      L56.5,257.4z M279.6,258.4l-0.9-0.8c0.3-0.3,0.6-0.7,0.9-1.1c0.1-0.2,0.3-0.4,0.4-0.5l1,0.8c-0.1,0.2-0.3,0.4-0.4,0.6
      C280.3,257.7,279.9,258.1,279.6,258.4z M59.8,261.1c-0.3-0.3-0.6-0.7-1-1l0.9-0.8c0.3,0.3,0.6,0.7,1,1L59.8,261.1z M276.1,262
      l-0.8-0.9c0.4-0.3,0.7-0.6,1-0.9l0.9,0.9C276.8,261.3,276.4,261.7,276.1,262z M63.3,264.6c-0.3-0.3-0.7-0.7-1-1l0.9-0.9
      c0.3,0.3,0.7,0.7,1,1L63.3,264.6z M272.2,265.2l-0.8-1c0.4-0.3,0.7-0.6,1.1-0.9l0.8,1C273,264.6,272.6,264.9,272.2,265.2z
      M268.1,268.1l-0.7-1c0.4-0.3,0.8-0.5,1.2-0.8l0.7,1C268.9,267.5,268.5,267.8,268.1,268.1L268.1,268.1z M66.9,268.1
      c-0.4-0.3-0.7-0.6-1-1l0.9-0.9c0.3,0.3,0.7,0.6,1,1L66.9,268.1z M263.9,270.7l-0.6-1.1c0.4-0.2,0.8-0.5,1.2-0.7l0.7,1.1
      C264.7,270.2,264.3,270.5,263.9,270.7L263.9,270.7z M70.6,271.4c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-0.9c0.4,0.3,0.7,0.6,1.1,0.9
      L70.6,271.4z M259.6,273.2l-0.6-1.1c0.4-0.2,0.8-0.5,1.2-0.7l0.6,1.1C260.4,272.8,260,273,259.6,273.2L259.6,273.2z M74.5,274.6
      c-0.4-0.3-0.7-0.6-1.1-0.9l0.8-1c0.4,0.3,0.7,0.6,1.1,0.9L74.5,274.6z M255.2,275.5l-0.6-1.1c0.4-0.2,0.8-0.4,1.2-0.6l0.6,1.1
      C256.1,275.1,255.6,275.3,255.2,275.5z M78.5,277.5c-0.4-0.3-0.8-0.5-1.2-0.8l0.7-1c0.4,0.3,0.8,0.5,1.1,0.8L78.5,277.5z
      M250.8,277.8l-0.5-1.1c0.4-0.2,0.8-0.4,1.3-0.6l0.6,1.1C251.6,277.3,251.2,277.6,250.8,277.8L250.8,277.8z M246.3,279.9l-0.5-1.1
      c0.4-0.2,0.9-0.4,1.3-0.6l0.5,1.1C247.2,279.5,246.7,279.7,246.3,279.9z M82.6,280.3c-0.4-0.3-0.8-0.5-1.2-0.8l0.7-1
      c0.4,0.3,0.8,0.5,1.2,0.8L82.6,280.3z M241.8,282l-0.5-1.1l1.3-0.6l0.5,1.1L241.8,282z M86.9,282.8c-0.4-0.2-0.8-0.5-1.2-0.7
      l0.6-1.1c0.4,0.2,0.8,0.5,1.2,0.7L86.9,282.8z M237.3,284.1l-0.5-1.1l1.3-0.6l0.5,1.1L237.3,284.1z M91.3,285.2
      c-0.4-0.2-0.9-0.4-1.3-0.6l0.6-1.1c0.4,0.2,0.8,0.4,1.3,0.6L91.3,285.2z M232.8,286.2l-0.5-1.1c0.4-0.2,0.9-0.4,1.3-0.6l0.5,1.1
      C233.7,285.8,233.2,286,232.8,286.2z M95.8,287.2c-0.4-0.2-0.9-0.4-1.3-0.6l0.5-1.1c0.4,0.2,0.9,0.4,1.3,0.6L95.8,287.2z
      M228.4,288.3l-0.6-1.1c0.4-0.2,0.8-0.4,1.3-0.6l0.5,1.1C229.2,287.9,228.8,288.1,228.4,288.3L228.4,288.3z M100.5,289
      c-0.4-0.2-0.9-0.3-1.3-0.5l0.4-1.2c0.4,0.2,0.9,0.3,1.3,0.5L100.5,289z M105.3,290.4c-0.5-0.1-0.9-0.3-1.4-0.4l0.3-1.2
      c0.4,0.1,0.9,0.3,1.4,0.4L105.3,290.4z M224,290.6l-0.6-1.1c0.4-0.2,0.8-0.4,1.3-0.7l0.6,1.1C224.8,290.1,224.4,290.3,224,290.6
      L224,290.6z M110.2,291.6c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2c0.5,0.1,0.9,0.2,1.4,0.3L110.2,291.6z M115.1,292.3
      c-0.5-0.1-0.9-0.1-1.4-0.2l0.2-1.2c0.5,0.1,0.9,0.1,1.4,0.2L115.1,292.3z M120.1,292.8c-0.5,0-1,0-1.4-0.1l0.1-1.2
      c0.5,0,0.9,0.1,1.4,0.1L120.1,292.8z M125,292.8c-0.4,0-0.9,0-1.3,0l-0.1,0v-1.3h0.1c0.5,0,0.9,0,1.4,0L125,292.8z M219.6,292.9
      l-0.6-1.1c0.4-0.2,0.8-0.5,1.2-0.7l0.6,1.1C220.5,292.5,220.1,292.7,219.6,292.9z M129.9,293.2c-0.5-0.1-0.9-0.1-1.4-0.2l0.1-1.2
      c0.5,0,0.9,0.1,1.4,0.2L129.9,293.2z M134.8,293.9c-0.5-0.1-0.9-0.2-1.4-0.2l0.2-1.2c0.5,0.1,0.9,0.2,1.4,0.2L134.8,293.9z
      M139.6,294.9c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2c0.5,0.1,0.9,0.2,1.4,0.3L139.6,294.9z M215.5,295.5l-0.7-1l0.2-0.1
      c0.3-0.2,0.7-0.4,1-0.7l0.7,1.1c-0.3,0.2-0.7,0.4-1,0.6L215.5,295.5z M144.4,296c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2
      c0.5,0.1,0.9,0.2,1.4,0.3L144.4,296z M149.2,297.2l-1.4-0.3l0.3-1.2l1.4,0.3L149.2,297.2z M211.2,298.1l-0.6-1.1
      c0.4-0.2,0.8-0.4,1.2-0.7l0.6,1.1C212,297.6,211.6,297.9,211.2,298.1L211.2,298.1z M154,298.4l-1.4-0.3l0.3-1.2l1.4,0.3L154,298.4z
      M158.8,299.6c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2c0.5,0.1,0.9,0.2,1.4,0.3L158.8,299.6z M206.6,300.1l-0.4-1.2
      c0.4-0.2,0.9-0.3,1.3-0.5l0.5,1.2C207.5,299.8,207,300,206.6,300.1L206.6,300.1z M163.7,300.7c-0.5-0.1-0.9-0.2-1.4-0.3l0.3-1.2
      c0.5,0.1,0.9,0.2,1.4,0.3L163.7,300.7z M168.6,301.7c-0.5-0.1-0.9-0.2-1.4-0.3l0.2-1.2c0.5,0.1,0.9,0.2,1.4,0.3L168.6,301.7z
      M201.8,301.7l-0.3-1.2c0.4-0.1,0.9-0.2,1.3-0.4l0.4,1.2C202.7,301.4,202.3,301.6,201.8,301.7z M173.5,302.5
      c-0.5-0.1-0.9-0.1-1.4-0.2l0.2-1.2c0.5,0.1,0.9,0.1,1.4,0.2L173.5,302.5z M196.9,302.8l-0.2-1.2c0.5-0.1,0.9-0.2,1.4-0.2l0.2,1.2
      C197.9,302.6,197.4,302.7,196.9,302.8L196.9,302.8z M178.4,303.1c-0.5,0-0.9-0.1-1.4-0.2l0.1-1.2c0.5,0.1,0.9,0.1,1.4,0.2
      L178.4,303.1z M191.9,303.4l-0.1-1.2c0.5,0,0.9-0.1,1.4-0.1l0.1,1.2C192.9,303.3,192.4,303.3,191.9,303.4L191.9,303.4z
      M183.4,303.5c-0.5,0-0.9,0-1.4-0.1l0.1-1.2c0.5,0,0.9,0.1,1.4,0.1L183.4,303.5z M186.9,303.6l0-1.3c0.5,0,0.9,0,1.4,0l0,1.3
      C187.9,303.6,187.4,303.6,186.9,303.6z"/>
      <path fill="#C5E8F0" d="M143.2,6.7l0-1.3c0.7,0,1.4,0,2.1,0l0,1.3C144.7,6.7,143.9,6.7,143.2,6.7L143.2,6.7z M150.3,6.8
      c-0.7,0-1.4-0.1-2.1-0.1l0-1.3c0.7,0,1.4,0.1,2.1,0.1L150.3,6.8z M138.3,7l-0.1-1.3c0.7-0.1,1.4-0.1,2.1-0.2l0.1,1.3
      C139.7,6.9,139,7,138.3,7L138.3,7z M155.1,7.2c-0.6-0.1-1.2-0.1-1.7-0.2L153,7l0.1-1.3l0.4,0c0.6,0.1,1.2,0.1,1.7,0.2L155.1,7.2z
      M133.5,7.7l-0.2-1.3c0.7-0.1,1.4-0.2,2.1-0.3l0.2,1.3C134.9,7.5,134.2,7.6,133.5,7.7L133.5,7.7z M160.1,7.9
      c-0.7-0.1-1.4-0.2-2.1-0.3l0.2-1.3c0.7,0.1,1.4,0.2,2.1,0.3L160.1,7.9z M128.6,8.6l-0.3-1.3c0.7-0.1,1.4-0.3,2.1-0.4l0.2,1.3
      C130,8.3,129.3,8.4,128.6,8.6L128.6,8.6z M164.9,8.6c-0.7-0.1-1.4-0.2-2.1-0.3L163,7c0.7,0.1,1.4,0.2,2.1,0.3L164.9,8.6z
      M169.8,9.4c-0.7-0.1-1.4-0.2-2.1-0.4l0.2-1.3c0.7,0.1,1.4,0.2,2.1,0.4L169.8,9.4z M123.8,9.7l-0.3-1.3c0.7-0.2,1.4-0.4,2.1-0.5
      l0.3,1.3C125.2,9.3,124.5,9.5,123.8,9.7z M174.7,10.3c-0.7-0.1-1.4-0.3-2.1-0.4l0.2-1.3c0.7,0.1,1.4,0.3,2.1,0.4L174.7,10.3z
      M119.1,11.1l-0.4-1.2c0.7-0.2,1.4-0.4,2-0.6l0.4,1.3C120.5,10.6,119.8,10.8,119.1,11.1z M179.5,11.4c-0.7-0.2-1.4-0.3-2.1-0.5
      l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L179.5,11.4z M184.3,12.5c-0.7-0.2-1.4-0.3-2.1-0.5l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L184.3,12.5z
      M114.4,12.6l-0.4-1.2c0.7-0.2,1.3-0.5,2-0.7l0.4,1.2C115.8,12.2,115.1,12.4,114.4,12.6L114.4,12.6z M189,13.8
      c-0.7-0.2-1.4-0.4-2-0.6l0.3-1.3c0.7,0.2,1.4,0.4,2.1,0.6L189,13.8z M109.8,14.4l-0.5-1.2c0.7-0.3,1.3-0.5,2-0.8l0.5,1.2
      C111.1,13.9,110.5,14.1,109.8,14.4L109.8,14.4z M193.8,15.2c-0.7-0.2-1.3-0.4-2-0.6l0.4-1.3c0.7,0.2,1.4,0.4,2,0.6L193.8,15.2z
      M105.3,16.3l-0.5-1.2c0.7-0.3,1.3-0.6,2-0.9l0.5,1.2C106.6,15.7,106,16,105.3,16.3z M198.5,16.8c-0.7-0.2-1.3-0.5-2-0.7l0.4-1.2
      c0.7,0.2,1.4,0.5,2,0.7L198.5,16.8z M100.8,18.4l-0.6-1.2c0.6-0.3,1.3-0.6,1.9-0.9l0.6,1.2C102.1,17.8,101.5,18.1,100.8,18.4z
      M203.1,18.4c-0.7-0.3-1.3-0.5-2-0.7l0.5-1.2c0.7,0.2,1.3,0.5,2,0.7L203.1,18.4z M207.7,20.3c-0.6-0.3-1.3-0.5-2-0.8l0.5-1.2
      c0.7,0.3,1.3,0.5,2,0.8L207.7,20.3z M96.4,20.7l-0.6-1.2c0.6-0.3,1.3-0.7,1.9-1l0.6,1.2C97.7,20,97.1,20.4,96.4,20.7L96.4,20.7z
      M212.2,22.3c-0.6-0.3-1.3-0.6-1.9-0.9l0.5-1.2c0.7,0.3,1.3,0.6,1.9,0.9L212.2,22.3z M92.1,23.1L91.5,22c0.6-0.4,1.2-0.7,1.9-1.1
      L94,22C93.4,22.4,92.7,22.7,92.1,23.1L92.1,23.1z M216.6,24.5c-0.6-0.3-1.2-0.6-1.9-1l0.6-1.2c0.6,0.3,1.3,0.6,1.9,1L216.6,24.5z
      M87.9,25.6l-0.7-1.1c0.6-0.4,1.2-0.7,1.8-1.1l0.7,1.1C89.1,24.9,88.5,25.3,87.9,25.6z M220.9,26.9c-0.6-0.4-1.2-0.7-1.8-1l0.6-1.1
      c0.6,0.3,1.2,0.7,1.9,1.1L220.9,26.9z M83.7,28.3L83,27.2c0.6-0.4,1.2-0.8,1.8-1.2l0.7,1.1C84.9,27.5,84.3,27.9,83.7,28.3
      L83.7,28.3z M225.1,29.4c-0.6-0.4-1.2-0.8-1.8-1.1l0.7-1.1c0.6,0.4,1.2,0.8,1.8,1.1L225.1,29.4z M79.6,31.1L78.9,30
      c0.6-0.4,1.2-0.8,1.8-1.2l0.7,1.1C80.8,30.3,80.2,30.7,79.6,31.1z M229.1,32.2c-0.6-0.4-1.1-0.8-1.7-1.2l0.8-1.1
      c0.6,0.4,1.2,0.8,1.7,1.2L229.1,32.2z M75.6,34l-0.8-1c0.6-0.4,1.1-0.8,1.7-1.3l0.8,1.1C76.8,33.1,76.2,33.5,75.6,34z M233,35.3
      c-0.5-0.4-1.1-0.9-1.6-1.3l0.8-1c0.6,0.4,1.1,0.9,1.7,1.4L233,35.3z M71.7,36.9l-0.8-1c0.6-0.4,1.1-0.9,1.7-1.3l0.8,1
      C72.8,36.1,72.3,36.5,71.7,36.9z M236.6,38.6c-0.5-0.5-1-1-1.5-1.4l0.9-1c0.5,0.5,1.1,1,1.6,1.5L236.6,38.6z M67.9,40l-0.8-1
      c0.5-0.5,1.1-0.9,1.6-1.3l0.8,1C69,39.1,68.4,39.6,67.9,40z M240,42.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.4-0.7-0.7-1-1.1l1-0.9
      c0.3,0.4,0.7,0.7,1,1.1c0.1,0.2,0.3,0.3,0.4,0.5L240,42.1z M64.2,43.2l-0.9-1c0.5-0.5,1.1-0.9,1.6-1.4l0.8,1
      C65.2,42.3,64.7,42.8,64.2,43.2z M243.6,45.7c-0.5-0.5-1.1-1-1.6-1.5l0.9-0.9c0.5,0.5,1,1,1.5,1.5L243.6,45.7z M60.5,46.5l-0.9-1
      c0.5-0.5,1-1,1.6-1.4l0.9,1C61.5,45.6,61,46.1,60.5,46.5L60.5,46.5z M247.3,48.9c-0.6-0.5-1.1-0.9-1.6-1.4l0.9-1
      c0.5,0.5,1.1,0.9,1.6,1.4L247.3,48.9z M56.9,49.9L56,49c0.5-0.5,1-1,1.5-1.5l0.9,0.9C57.9,49,57.4,49.5,56.9,49.9L56.9,49.9z
      M251.2,52c-0.6-0.4-1.1-0.9-1.7-1.3l0.8-1c0.6,0.4,1.1,0.9,1.7,1.3L251.2,52z M53.4,53.4l-0.9-0.9c0.5-0.5,1-1,1.5-1.5l0.9,0.9
      C54.4,52.4,53.9,52.9,53.4,53.4z M255.2,55c-0.6-0.4-1.1-0.8-1.7-1.3l0.8-1c0.6,0.4,1.1,0.8,1.7,1.3L255.2,55z M50.1,57l-1-0.9
      c0.5-0.5,0.9-1.1,1.4-1.6l1,0.9C51,56,50.5,56.5,50.1,57L50.1,57z M259.2,57.9l-1.7-1.2l0.8-1.1l1.7,1.2L259.2,57.9z M263.2,60.8
      c-0.6-0.4-1.1-0.8-1.7-1.3l0.8-1.1c0.6,0.4,1.2,0.8,1.7,1.3L263.2,60.8z M47.1,60.9L46,60.1c0.4-0.6,0.8-1.2,1.3-1.7l1,0.8
      C47.9,59.8,47.5,60.3,47.1,60.9L47.1,60.9z M267.2,63.8c-0.6-0.4-1.1-0.9-1.7-1.3l0.8-1c0.6,0.4,1.1,0.9,1.7,1.3L267.2,63.8z
      M44.4,65l-1.1-0.7c0.4-0.6,0.7-1.2,1.1-1.8l1.1,0.7C45.1,63.8,44.8,64.4,44.4,65z M271.1,66.8c-0.5-0.4-1.1-0.9-1.6-1.3l0.8-1
      c0.6,0.4,1.1,0.9,1.7,1.3L271.1,66.8z M42,69.3l-1.2-0.6c0.3-0.6,0.7-1.3,1-1.9l1.1,0.6C42.7,68,42.4,68.7,42,69.3z M274.8,70.1
      c-0.5-0.5-1-0.9-1.6-1.4l0.9-1c0.6,0.5,1.1,1,1.6,1.4L274.8,70.1z M278.3,73.5c-0.5-0.5-1-1-1.5-1.5l0.9-0.9c0.5,0.5,1,1,1.5,1.5
      L278.3,73.5z M40,73.7l-1.2-0.5c0.3-0.7,0.6-1.3,0.9-2l1.2,0.5C40.5,72.4,40.2,73.1,40,73.7z M281.5,77.2c-0.4-0.5-0.9-1.1-1.3-1.6
      l1-0.8c0.5,0.6,0.9,1.1,1.4,1.7L281.5,77.2z M38.1,78.3l-1.2-0.5c0.3-0.7,0.5-1.3,0.8-2l1.2,0.5C38.6,77,38.4,77.7,38.1,78.3
      L38.1,78.3z M284.2,81.2c-0.4-0.6-0.7-1.2-1.1-1.8l1.1-0.7c0.4,0.6,0.8,1.2,1.2,1.8L284.2,81.2z M36.5,83l-1.2-0.4
      c0.2-0.7,0.5-1.3,0.7-2l1.2,0.4C36.9,81.6,36.7,82.3,36.5,83z M286.4,85.6c-0.3-0.6-0.5-1.3-0.9-1.9l1.2-0.6c0.3,0.7,0.6,1.3,0.9,2
      L286.4,85.6z M35,87.7l-1.2-0.4c0.2-0.7,0.4-1.4,0.6-2l1.2,0.4C35.4,86.3,35.2,87,35,87.7z M287.9,90.2c-0.2-0.7-0.3-1.3-0.5-2
      l1.2-0.4c0.2,0.7,0.4,1.4,0.6,2.1L287.9,90.2z M33.6,92.4l-1.3-0.4c0.2-0.7,0.4-1.4,0.6-2l1.3,0.4C33.9,91.1,33.8,91.7,33.6,92.4z
      M288.8,95c-0.1-0.7-0.3-1.4-0.4-2.1l1.3-0.2c0.1,0.7,0.3,1.4,0.4,2.1L288.8,95z M32.2,97.2L31,96.8c0.2-0.7,0.4-1.4,0.6-2l1.3,0.4
      C32.6,95.8,32.4,96.5,32.2,97.2z M289.9,99.9c-0.2-0.7-0.3-1.4-0.5-2.1l1.3-0.3c0.2,0.7,0.3,1.4,0.5,2L289.9,99.9z M30.9,102
      l-1.3-0.3l0.6-2l1.3,0.3L30.9,102z M291.3,104.7c-0.2-0.7-0.4-1.4-0.6-2l1.3-0.4c0.2,0.7,0.4,1.4,0.6,2L291.3,104.7z M29.6,106.7
      l-1.3-0.4c0.2-0.7,0.4-1.4,0.6-2l1.3,0.4C30,105.4,29.8,106,29.6,106.7z M292.7,109.4c-0.2-0.7-0.4-1.4-0.6-2l1.2-0.4
      c0.2,0.7,0.4,1.3,0.6,2L292.7,109.4z M28.2,111.5l-1.2-0.4c0.2-0.7,0.4-1.3,0.6-2l1.3,0.4C28.6,110.1,28.4,110.8,28.2,111.5
      L28.2,111.5z M294.3,114.1c-0.2-0.7-0.5-1.3-0.7-2l1.2-0.4c0.2,0.7,0.4,1.3,0.7,2L294.3,114.1z M26.6,116.2l-1.2-0.4
      c0.2-0.7,0.4-1.3,0.7-2l1.2,0.4C27.1,114.9,26.9,115.6,26.6,116.2L26.6,116.2z M295.9,118.8c-0.2-0.7-0.5-1.3-0.7-2l1.2-0.4
      c0.2,0.7,0.5,1.3,0.7,2L295.9,118.8z M24.9,120.9l-1.2-0.5c0.3-0.7,0.5-1.3,0.7-2l1.2,0.5C25.4,119.6,25.2,120.2,24.9,120.9z
      M297.5,123.5l-0.7-2l1.2-0.4l0.7,2L297.5,123.5z M23,125.5l-1.2-0.5c0.3-0.6,0.6-1.3,0.9-1.9l1.2,0.5
      C23.6,124.2,23.3,124.9,23,125.5L23,125.5z M299.1,128.2c-0.2-0.7-0.5-1.3-0.7-2l1.2-0.4c0.2,0.7,0.5,1.3,0.7,2L299.1,128.2z
      M20.7,130l-1.2-0.6c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.4,0.4-0.8,0.6-1.2l1.2,0.6c-0.2,0.4-0.4,0.8-0.6,1.2
      C21,129.5,20.9,129.7,20.7,130z M300.7,132.9c-0.2-0.7-0.4-1.3-0.7-2l1.2-0.4c0.2,0.7,0.4,1.4,0.7,2L300.7,132.9z M18.7,134.4
      l-1.2-0.5c0.3-0.7,0.5-1.3,0.8-2l1.2,0.5C19.3,133.1,19,133.8,18.7,134.4L18.7,134.4z M302.1,137.6c-0.2-0.7-0.4-1.4-0.6-2l1.2-0.4
      c0.2,0.7,0.4,1.4,0.6,2L302.1,137.6z M17.2,139l-1.3-0.3c0.2-0.7,0.4-1.4,0.6-2.1l1.2,0.4C17.6,137.7,17.4,138.4,17.2,139z
      M303.4,142.3c-0.2-0.7-0.4-1.4-0.5-2l1.3-0.3c0.2,0.7,0.4,1.4,0.5,2.1L303.4,142.3z M16.1,143.8l-1.3-0.2c0.1-0.7,0.3-1.4,0.4-2.1
      l1.3,0.3C16.4,142.4,16.2,143.1,16.1,143.8L16.1,143.8z M304.5,147.1c-0.1-0.7-0.3-1.4-0.4-2.1l1.3-0.3c0.2,0.7,0.3,1.4,0.5,2.1
      L304.5,147.1z M15.5,148.6l-1.3-0.1c0.1-0.7,0.1-1.4,0.2-2.1l1.3,0.2C15.6,147.2,15.6,147.9,15.5,148.6z M305.4,152
      c-0.1-0.7-0.2-1.4-0.3-2.1l1.3-0.2c0.1,0.7,0.2,1.4,0.3,2.1L305.4,152z M14,153.5l0-0.3c0-0.6,0-1.3,0-1.9l1.3,0c0,0.6,0,1.2,0,1.8
      l0,0.3L14,153.5z M305.9,156.9c0-0.7-0.1-1.4-0.2-2.1l1.3-0.1c0.1,0.7,0.1,1.4,0.2,2.1L305.9,156.9z M14.2,158.5
      c-0.1-0.7-0.1-1.4-0.1-2.1l1.3-0.1c0,0.7,0.1,1.4,0.1,2.1L14.2,158.5z M307.3,161.8l-1.3,0c0-0.6,0-1.2,0-1.8v-0.3l1.3,0v0.3
      C307.3,160.6,307.3,161.2,307.3,161.8L307.3,161.8z M14.7,163.5c-0.1-0.7-0.2-1.4-0.3-2.1l1.3-0.1c0.1,0.7,0.2,1.4,0.3,2.1
      L14.7,163.5z M306.8,166.8l-1.3-0.2c0.1-0.7,0.2-1.4,0.3-2.1l1.3,0.1C307,165.4,306.9,166.1,306.8,166.8L306.8,166.8z M15.5,168.4
      c-0.1-0.7-0.2-1.4-0.4-2.1l1.3-0.2c0.1,0.7,0.2,1.4,0.4,2.1L15.5,168.4z M305.6,171.7l-1.2-0.4c0.2-0.7,0.4-1.3,0.5-2l1.3,0.3
      C306,170.3,305.8,171,305.6,171.7z M16.5,173.3c-0.2-0.7-0.3-1.4-0.4-2.1l1.3-0.3c0.1,0.7,0.3,1.4,0.4,2.1L16.5,173.3z
      M303.8,176.4l-1.2-0.6c0.3-0.6,0.6-1.3,0.8-1.9l1.2,0.5C304.4,175.1,304.1,175.8,303.8,176.4z M17.7,178.2
      c-0.2-0.7-0.3-1.4-0.5-2.1l1.3-0.3c0.2,0.7,0.3,1.4,0.5,2.1L17.7,178.2z M301.4,180.8l-1.1-0.6c0.3-0.5,0.6-1,0.9-1.5
      c0.1-0.1,0.2-0.3,0.2-0.4l1.1,0.7c-0.1,0.1-0.2,0.3-0.2,0.4C301.9,179.8,301.6,180.3,301.4,180.8L301.4,180.8z M18.9,183
      c-0.2-0.7-0.4-1.4-0.5-2.1l1.3-0.3c0.2,0.7,0.4,1.4,0.5,2.1L18.9,183z M299.4,185.2l-1.2-0.5c0.3-0.7,0.5-1.3,0.8-2l1.2,0.5
      C299.9,183.9,299.6,184.5,299.4,185.2L299.4,185.2z M20.2,187.8l-0.6-2.1l1.3-0.3l0.6,2L20.2,187.8z M297.8,189.8l-1.3-0.4
      c0.2-0.7,0.4-1.4,0.6-2.1l1.2,0.4C298.2,188.5,298,189.1,297.8,189.8L297.8,189.8z M21.4,192.5c-0.2-0.7-0.4-1.4-0.5-2l1.3-0.3
      c0.2,0.7,0.4,1.4,0.6,2.1L21.4,192.5z M296.6,194.6l-1.3-0.3c0.2-0.7,0.3-1.4,0.5-2.1l1.3,0.3C296.9,193.2,296.7,193.9,296.6,194.6
      L296.6,194.6z M22.7,197.3c-0.2-0.7-0.3-1.4-0.5-2.1l1.3-0.3c0.2,0.7,0.4,1.4,0.5,2.1L22.7,197.3z M295.6,199.4l-1.3-0.2
      c0.1-0.7,0.3-1.4,0.4-2.1l1.3,0.3C295.8,198,295.7,198.7,295.6,199.4z M23.9,202.1c-0.2-0.7-0.3-1.4-0.5-2.1l1.3-0.3
      c0.2,0.7,0.3,1.4,0.5,2.1L23.9,202.1z M294.7,204.3l-1.3-0.2c0.1-0.7,0.2-1.4,0.3-2.1l1.3,0.2C295,202.9,294.9,203.6,294.7,204.3z
      M24.9,206.9c-0.1-0.7-0.3-1.4-0.4-2.1l1.3-0.3c0.1,0.7,0.3,1.4,0.4,2.1L24.9,206.9z M294,209.2l-1.3-0.2c0.1-0.7,0.2-1.4,0.3-2.1
      l1.3,0.2C294.2,207.8,294.1,208.5,294,209.2L294,209.2z M25.8,211.8c-0.1-0.7-0.2-1.4-0.3-2.1l1.3-0.2c0.1,0.7,0.2,1.4,0.3,2.1
      L25.8,211.8z M293.3,214.1l-1.3-0.2l0.3-2.1l1.3,0.2L293.3,214.1z M26.7,216.7c-0.2-0.7-0.3-1.4-0.5-2.1l1.3-0.3
      c0.1,0.7,0.3,1.4,0.5,2L26.7,216.7z M292.5,219l-1.3-0.2c0.1-0.7,0.2-1.4,0.3-2.1l1.3,0.2C292.8,217.6,292.7,218.3,292.5,219z
      M28.2,221.5c-0.2-0.7-0.5-1.4-0.7-2l1.2-0.4c0.2,0.7,0.4,1.3,0.6,2L28.2,221.5z M291.7,223.9l-1.3-0.2c0.1-0.7,0.2-1.4,0.4-2.1
      l1.3,0.2C292,222.5,291.9,223.2,291.7,223.9L291.7,223.9z M29.9,226.2c-0.3-0.7-0.5-1.3-0.8-2l1.2-0.5c0.2,0.6,0.5,1.3,0.8,2
      L29.9,226.2z M290.8,228.8l-1.3-0.3c0.1-0.7,0.3-1.4,0.4-2.1l1.3,0.2C291.1,227.4,290.9,228.1,290.8,228.8z M31.9,230.7
      c-0.3-0.6-0.6-1.3-0.9-1.9l1.2-0.5c0.3,0.6,0.6,1.3,0.9,1.9L31.9,230.7z M289.6,233.6l-1.3-0.3c0.2-0.7,0.3-1.4,0.5-2l1.3,0.3
      C290,232.2,289.8,232.9,289.6,233.6L289.6,233.6z M34.2,235.2c-0.3-0.6-0.7-1.3-1-1.9l1.2-0.6c0.3,0.6,0.6,1.2,1,1.9L34.2,235.2z
      M288.2,238.4L287,238c0.2-0.6,0.4-1.3,0.6-2l1.3,0.4C288.7,237.1,288.5,237.7,288.2,238.4z M36.7,239.5c-0.4-0.6-0.7-1.2-1.1-1.8
      l1.1-0.6c0.3,0.6,0.7,1.2,1.1,1.8L36.7,239.5z M286.5,243.1l-1.2-0.5c0.3-0.6,0.5-1.3,0.8-1.9l1.2,0.5
      C287,241.8,286.8,242.5,286.5,243.1z M39.3,243.7c-0.4-0.6-0.8-1.2-1.1-1.8l1.1-0.7c0.4,0.6,0.8,1.2,1.1,1.8L39.3,243.7z
      M284.3,247.6l-1.1-0.6c0.3-0.6,0.7-1.2,1-1.8l1.2,0.6C285,246.4,284.6,247,284.3,247.6L284.3,247.6z M42.1,247.9
      c-0.4-0.6-0.8-1.2-1.2-1.8l1.1-0.7c0.4,0.6,0.8,1.2,1.2,1.7L42.1,247.9z M45,251.9c-0.4-0.6-0.9-1.1-1.3-1.7l1-0.8
      c0.4,0.6,0.8,1.1,1.3,1.7L45,251.9z M280.4,253.3l-1-0.8c0.4-0.5,0.9-1.1,1.3-1.6c0.4-0.5,0.7-1,1-1.5l1.1,0.7
      c-0.3,0.5-0.7,1-1.1,1.5C281.3,252.1,280.8,252.7,280.4,253.3L280.4,253.3z M48.1,255.8c-0.5-0.6-0.9-1.1-1.3-1.7l1-0.8
      c0.4,0.5,0.9,1.1,1.3,1.6L48.1,255.8z M277,257l-0.9-0.9c0.5-0.5,1-1,1.5-1.5l1,0.9C278,256,277.5,256.5,277,257L277,257z
      M51.4,259.5c-0.5-0.5-0.9-1.1-1.4-1.6l1-0.9c0.5,0.5,0.9,1.1,1.4,1.6L51.4,259.5z M273.3,260.4l-0.9-1c0.5-0.5,1.1-0.9,1.6-1.4
      l0.9,1C274.4,259.4,273.9,259.9,273.3,260.4z M54.8,263.2c-0.5-0.5-1-1-1.5-1.5l1-0.9c0.5,0.5,1,1,1.5,1.5L54.8,263.2z
      M269.4,263.5l-0.8-1c0.6-0.4,1.1-0.9,1.7-1.3l0.8,1C270.6,262.6,270,263.1,269.4,263.5L269.4,263.5z M265.4,266.4l-0.7-1.1
      c0.6-0.4,1.2-0.8,1.7-1.2l0.8,1.1C266.6,265.6,266,266,265.4,266.4L265.4,266.4z M58.3,266.7c-0.5-0.5-1-1-1.5-1.5l0.9-0.9
      c0.5,0.5,1,1,1.5,1.5L58.3,266.7z M261.2,269.2l-0.7-1.1c0.6-0.4,1.2-0.8,1.8-1.1l0.7,1.1C262.4,268.4,261.8,268.8,261.2,269.2
      L261.2,269.2z M61.9,270.1c-0.5-0.5-1.1-1-1.6-1.4l0.9-1c0.5,0.5,1,0.9,1.6,1.4L61.9,270.1z M257,271.8l-0.7-1.1
      c0.6-0.4,1.2-0.7,1.8-1.1l0.7,1.1C258.2,271,257.6,271.4,257,271.8L257,271.8z M65.7,273.3c-0.6-0.5-1.1-0.9-1.6-1.4l0.8-1
      c0.5,0.5,1.1,0.9,1.6,1.4L65.7,273.3z M252.7,274.3l-0.7-1.1c0.6-0.4,1.2-0.7,1.8-1.1l0.7,1.1C254,273.6,253.3,273.9,252.7,274.3
      L252.7,274.3z M69.7,276.4c-0.6-0.4-1.1-0.9-1.7-1.3l0.8-1c0.6,0.4,1.1,0.9,1.7,1.3L69.7,276.4z M248.4,276.7l-0.6-1.1
      c0.6-0.3,1.2-0.7,1.9-1l0.6,1.1C249.6,276,249,276.4,248.4,276.7L248.4,276.7z M244.1,279.1l-0.6-1.1l1.9-1l0.6,1.1L244.1,279.1z
      M73.7,279.3c-0.6-0.4-1.2-0.8-1.8-1.2l0.8-1.1c0.6,0.4,1.2,0.8,1.7,1.2L73.7,279.3z M239.7,281.5l-0.6-1.1c0.6-0.3,1.2-0.7,1.9-1
      l0.6,1.1C241,280.8,240.3,281.2,239.7,281.5L239.7,281.5z M77.9,282c-0.6-0.4-1.2-0.8-1.8-1.1l0.7-1.1c0.6,0.4,1.2,0.8,1.8,1.1
      L77.9,282z M235.4,283.9l-0.6-1.1c0.6-0.4,1.2-0.7,1.9-1l0.6,1.1C236.6,283.2,236,283.6,235.4,283.9z M82.2,284.5
      c-0.6-0.3-1.2-0.7-1.9-1l0.7-1.1c0.6,0.4,1.2,0.7,1.8,1L82.2,284.5z M231.1,286.4l-0.7-1.1c0.6-0.4,1.2-0.7,1.8-1.1l0.7,1.1
      C232.3,285.7,231.7,286,231.1,286.4z M86.6,286.8c-0.6-0.3-1.3-0.6-1.9-1l0.6-1.2c0.6,0.3,1.3,0.6,1.9,0.9L86.6,286.8z M91.2,288.9
      c-0.7-0.3-1.3-0.6-2-0.8l0.5-1.2c0.6,0.3,1.3,0.6,1.9,0.8L91.2,288.9z M226.9,289l-0.7-1.1c0.6-0.4,1.2-0.8,1.8-1.1l0.7,1.1
      C228.1,288.2,227.5,288.6,226.9,289z M95.8,290.7c-0.7-0.2-1.3-0.5-2-0.7l0.5-1.2c0.7,0.2,1.3,0.5,2,0.7L95.8,290.7z M222.9,291.8
      l-0.8-1.1c0.6-0.4,1.2-0.8,1.8-1.2l0.7,1.1C224,291,223.4,291.4,222.9,291.8z M100.6,292.2c-0.7-0.2-1.4-0.4-2.1-0.6l0.4-1.2
      c0.7,0.2,1.4,0.4,2,0.6L100.6,292.2z M105.4,293.4c-0.7-0.2-1.4-0.3-2.1-0.5l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L105.4,293.4z
      M110.4,294.3c-0.7-0.1-1.4-0.2-2.1-0.3l0.2-1.3c0.7,0.1,1.4,0.2,2.1,0.3L110.4,294.3z M219,294.7l-0.8-1c0.1-0.1,0.2-0.2,0.3-0.3
      c0.4-0.4,0.9-0.7,1.3-1.1l0.8,1c-0.4,0.3-0.9,0.7-1.3,1C219.2,294.6,219.1,294.7,219,294.7L219,294.7z M115.3,294.8
      c-0.7-0.1-1.4-0.1-2.1-0.2l0.1-1.3c0.7,0.1,1.4,0.1,2.1,0.2L115.3,294.8z M120.2,295.3c-0.7-0.1-1.4-0.2-2.1-0.2l0.1-1.3
      c0.7,0.1,1.4,0.2,2.1,0.3L120.2,295.3z M125.1,296.1c-0.7-0.1-1.4-0.3-2.1-0.4l0.2-1.3c0.7,0.1,1.4,0.3,2.1,0.4L125.1,296.1z
      M129.9,297.1c-0.7-0.2-1.4-0.3-2.1-0.5l0.3-1.3c0.7,0.1,1.4,0.3,2.1,0.5L129.9,297.1z M214.9,297.7l-0.7-1.1
      c0.6-0.4,1.2-0.8,1.7-1.2l0.8,1.1C216.1,296.9,215.5,297.3,214.9,297.7L214.9,297.7z M134.7,298.3c-0.7-0.2-1.4-0.3-2.1-0.5
      l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L134.7,298.3z M139.5,299.5l-2.1-0.5l0.3-1.3l2.1,0.5L139.5,299.5z M210.6,300.2L210,299
      c0.6-0.3,1.2-0.7,1.8-1l0.6,1.1C211.8,299.5,211.2,299.9,210.6,300.2L210.6,300.2z M144.3,300.7c-0.7-0.2-1.4-0.3-2.1-0.5l0.3-1.3
      c0.7,0.2,1.4,0.3,2.1,0.5L144.3,300.7z M149.2,301.8c-0.7-0.2-1.4-0.3-2.1-0.5l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L149.2,301.8z
      M206,302.3l-0.5-1.2c0.6-0.3,1.3-0.5,1.9-0.8l0.5,1.2C207.3,301.7,206.7,302,206,302.3L206,302.3z M154,302.9
      c-0.7-0.1-1.4-0.3-2.1-0.5l0.3-1.3c0.7,0.2,1.4,0.3,2.1,0.5L154,302.9z M201.2,303.9l-0.4-1.3c0.7-0.2,1.3-0.4,2-0.6l0.4,1.2
      C202.6,303.5,201.9,303.7,201.2,303.9z M158.9,303.9c-0.7-0.1-1.4-0.3-2.1-0.4l0.3-1.3c0.7,0.1,1.4,0.3,2.1,0.4L158.9,303.9z
      M163.8,304.8c-0.7-0.1-1.4-0.2-2.1-0.4l0.2-1.3c0.7,0.1,1.4,0.2,2.1,0.4L163.8,304.8z M196.4,305.1l-0.3-1.3
      c0.7-0.1,1.4-0.3,2.1-0.5l0.3,1.3C197.8,304.8,197.1,304.9,196.4,305.1L196.4,305.1z M168.7,305.5c-0.7-0.1-1.4-0.2-2.1-0.3
      l0.2-1.3c0.7,0.1,1.4,0.2,2.1,0.3L168.7,305.5z M191.4,305.9l-0.2-1.3c0.7-0.1,1.4-0.2,2.1-0.3l0.2,1.3
      C192.9,305.7,192.2,305.8,191.4,305.9L191.4,305.9z M173.7,306c-0.7-0.1-1.4-0.1-2.1-0.2l0.1-1.3c0.7,0.1,1.4,0.1,2.1,0.2
      L173.7,306z M186.5,306.3l-0.1-1.3c0.7,0,1.4-0.1,2.1-0.1l0.1,1.3C187.9,306.2,187.2,306.3,186.5,306.3z M178.6,306.3
      c-0.7,0-1.4-0.1-2.1-0.1l0.1-1.3c0.7,0,1.4,0.1,2.1,0.1L178.6,306.3z M182.1,306.4c-0.2,0-0.4,0-0.7,0l0-1.3c0.7,0,1.4,0,2.1,0
      l0,1.3C183.1,306.4,182.6,306.4,182.1,306.4z"/>
      <path fill="#B2E1EB" d="M138.1,9l0-1.4c0.9,0,1.8,0,2.7,0h0.2l0,1.4h-0.2C139.9,8.9,139,9,138.1,9L138.1,9z M145.9,9.1
      C144.9,9.1,144,9,143.1,9l0-1.4c1,0,1.9,0.1,2.9,0.1L145.9,9.1z M133.2,9.3l-0.1-1.4c1-0.1,1.9-0.2,2.8-0.2l0.1,1.4
      C135.1,9.1,134.2,9.2,133.2,9.3L133.2,9.3z M150.7,9.6c-0.9-0.1-1.9-0.2-2.8-0.3l0.1-1.4c0.9,0.1,1.9,0.2,2.8,0.3L150.7,9.6z
      M128.4,9.9l-0.2-1.3c0.9-0.1,1.9-0.3,2.8-0.4l0.2,1.4C130.2,9.6,129.3,9.8,128.4,9.9L128.4,9.9z M155.6,10.4
      c-0.9-0.2-1.9-0.3-2.8-0.5l0.2-1.3c0.9,0.2,1.9,0.3,2.8,0.5L155.6,10.4z M123.5,10.7l-0.3-1.3c0.9-0.2,1.9-0.4,2.8-0.5l0.2,1.3
      C125.4,10.4,124.4,10.6,123.5,10.7z M160.5,11.3c-0.9-0.2-1.9-0.3-2.8-0.5l0.2-1.3c0.9,0.2,1.9,0.3,2.8,0.5L160.5,11.3z
      M118.7,11.8l-0.3-1.3c0.9-0.2,1.9-0.5,2.8-0.7l0.3,1.3C120.5,11.4,119.6,11.6,118.7,11.8L118.7,11.8z M165.3,12.2
      c-0.9-0.2-1.9-0.4-2.8-0.5l0.2-1.3c0.9,0.2,1.9,0.3,2.8,0.5L165.3,12.2z M114,13.2l-0.4-1.3c0.9-0.3,1.8-0.5,2.7-0.8l0.4,1.3
      C115.8,12.6,114.9,12.9,114,13.2z M170.2,13.2c-0.9-0.2-1.8-0.4-2.8-0.6l0.3-1.3c0.9,0.2,1.9,0.4,2.8,0.6L170.2,13.2z M175,14.2
      c-0.9-0.2-1.8-0.4-2.8-0.6l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.6L175,14.2z M109.3,14.7l-0.4-1.3c0.9-0.3,1.8-0.6,2.7-0.9l0.4,1.3
      C111.1,14.1,110.2,14.4,109.3,14.7z M179.8,15.3c-0.9-0.2-1.8-0.4-2.7-0.7l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.7L179.8,15.3z
      M104.7,16.4l-0.5-1.3c0.9-0.3,1.8-0.7,2.7-1l0.5,1.3C106.4,15.7,105.5,16,104.7,16.4L104.7,16.4z M184.6,16.6
      c-0.9-0.2-1.8-0.5-2.7-0.7l0.3-1.3c0.9,0.2,1.8,0.5,2.7,0.7L184.6,16.6z M189.3,17.9c-0.9-0.3-1.8-0.5-2.7-0.8l0.4-1.3
      c0.9,0.3,1.8,0.5,2.7,0.8L189.3,17.9z M100.1,18.2L99.6,17c0.9-0.4,1.8-0.7,2.6-1.1l0.5,1.3C101.8,17.5,101,17.9,100.1,18.2
      L100.1,18.2z M194.1,19.3c-0.9-0.3-1.8-0.6-2.7-0.8l0.4-1.3c0.9,0.3,1.8,0.6,2.7,0.8L194.1,19.3z M95.6,20.3L95,19.1
      c0.9-0.4,1.7-0.8,2.6-1.2l0.6,1.2C97.3,19.5,96.5,19.9,95.6,20.3L95.6,20.3z M198.8,20.9c-0.9-0.3-1.8-0.6-2.7-0.9l0.4-1.3
      c0.9,0.3,1.8,0.6,2.7,0.9L198.8,20.9z M91.2,22.5l-0.6-1.2c0.8-0.4,1.7-0.9,2.5-1.3l0.6,1.2C92.9,21.6,92,22.1,91.2,22.5L91.2,22.5
      z M203.4,22.6c-0.9-0.3-1.7-0.7-2.6-1l0.5-1.3c0.9,0.3,1.8,0.7,2.7,1L203.4,22.6z M207.9,24.5c-0.8-0.4-1.7-0.7-2.6-1.1l0.5-1.3
      c0.9,0.4,1.8,0.7,2.6,1.1L207.9,24.5z M86.8,24.8l-0.7-1.2c0.8-0.5,1.7-0.9,2.5-1.4l0.6,1.2C88.5,23.9,87.7,24.4,86.8,24.8z
      M212.4,26.6c-0.8-0.4-1.7-0.8-2.5-1.2l0.6-1.2c0.9,0.4,1.7,0.8,2.6,1.2L212.4,26.6z M82.6,27.3l-0.7-1.2c0.8-0.5,1.6-1,2.5-1.4
      l0.7,1.2C84.2,26.4,83.4,26.8,82.6,27.3z M216.7,28.9c-0.8-0.4-1.6-0.9-2.5-1.3l0.6-1.2c0.9,0.4,1.7,0.9,2.5,1.4L216.7,28.9z
      M78.4,29.9l-0.7-1.1c0.8-0.5,1.6-1,2.4-1.5l0.7,1.2C80,28.9,79.2,29.4,78.4,29.9L78.4,29.9z M221,31.4c-0.8-0.5-1.6-1-2.4-1.5
      l0.7-1.2c0.8,0.5,1.6,1,2.4,1.5L221,31.4z M74.3,32.6l-0.8-1.1c0.8-0.5,1.6-1.1,2.4-1.6l0.7,1.1C75.8,31.6,75,32.1,74.3,32.6z
      M225,34.2c-0.7-0.5-1.5-1.1-2.3-1.6l0.8-1.1c0.8,0.5,1.6,1.1,2.3,1.6L225,34.2z M70.2,35.5l-0.8-1.1c0.8-0.6,1.5-1.1,2.3-1.7
      l0.8,1.1C71.8,34.4,71,34.9,70.2,35.5L70.2,35.5z M228.8,37.2c-0.7-0.6-1.4-1.2-2.2-1.8l0.8-1.1c0.8,0.6,1.5,1.2,2.2,1.8
      L228.8,37.2z M66.3,38.4l-0.8-1.1c0.7-0.6,1.5-1.1,2.3-1.7l0.8,1.1C67.8,37.3,67,37.9,66.3,38.4z M232.6,40.5
      c-0.8-0.6-1.5-1.2-2.2-1.8l0.9-1c0.7,0.6,1.4,1.2,2.1,1.8L232.6,40.5z M62.4,41.5l-0.9-1.1c0.7-0.6,1.5-1.2,2.2-1.8l0.8,1.1
      C63.9,40.3,63.2,40.9,62.4,41.5L62.4,41.5z M236.6,43.5c-0.8-0.6-1.6-1.2-2.3-1.7l0.8-1.1c0.7,0.6,1.5,1.1,2.3,1.7L236.6,43.5z
      M58.7,44.7l-0.9-1c0.7-0.6,1.4-1.2,2.2-1.8l0.9,1C60.1,43.5,59.4,44.1,58.7,44.7z M240.6,46.4c-0.8-0.6-1.6-1.1-2.3-1.6l0.8-1.1
      c0.7,0.5,1.5,1.1,2.3,1.6L240.6,46.4z M55,48l-0.9-1c0.7-0.6,1.4-1.3,2.1-1.9l0.9,1C56.4,46.7,55.7,47.3,55,48z M244.8,49.1
      c-0.8-0.5-1.6-1-2.4-1.6l0.8-1.1c0.8,0.5,1.6,1,2.4,1.5L244.8,49.1z M51.4,51.4l-1-1c0.7-0.7,1.4-1.3,2-2l0.9,1
      C52.8,50.1,52.1,50.7,51.4,51.4z M249,51.8c-0.8-0.5-1.6-1-2.4-1.5l0.7-1.1c0.8,0.5,1.6,1,2.4,1.5L249,51.8z M253.2,54.4
      c-0.8-0.5-1.6-1-2.4-1.5l0.7-1.2c0.8,0.5,1.6,1,2.4,1.5L253.2,54.4z M48,54.9l-1-0.9c0.6-0.7,1.3-1.4,2-2l1,1
      C49.3,53.5,48.6,54.2,48,54.9L48,54.9z M257.3,57.1c-0.8-0.5-1.6-1-2.4-1.5l0.7-1.1c0.8,0.5,1.6,1,2.4,1.5L257.3,57.1z M44.6,58.5
      l-1-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.4-0.5,0.9-1,1.4-1.5l1,0.9c-0.5,0.5-0.9,1-1.4,1.5C45,58.1,44.8,58.3,44.6,58.5L44.6,58.5z
      M261.4,59.8c-0.8-0.5-1.6-1.1-2.3-1.6l0.8-1.1c0.8,0.5,1.6,1.1,2.4,1.6L261.4,59.8z M41.5,62.3l-1.1-0.8c0.6-0.8,1.2-1.5,1.8-2.3
      l1.1,0.9C42.7,60.8,42.1,61.5,41.5,62.3z M265.4,62.7c-0.7-0.5-1.5-1.1-2.3-1.6l0.8-1.1c0.8,0.6,1.6,1.1,2.3,1.7L265.4,62.7z
      M269.3,65.7c-0.7-0.6-1.4-1.2-2.2-1.7l0.8-1.1c0.8,0.6,1.5,1.2,2.2,1.8L269.3,65.7z M38.8,66.3l-1.2-0.7c0.5-0.8,1-1.6,1.6-2.4
      l1.1,0.8C39.8,64.8,39.3,65.5,38.8,66.3L38.8,66.3z M273,68.9c-0.7-0.6-1.4-1.2-2.1-1.9l0.9-1c0.7,0.6,1.5,1.3,2.1,1.9L273,68.9z
      M36.4,70.6L35.2,70c0.4-0.9,0.9-1.7,1.4-2.5l1.2,0.7C37.3,68.9,36.8,69.8,36.4,70.6L36.4,70.6z M276.5,72.4
      c-0.6-0.7-1.2-1.4-1.9-2l1-1c0.7,0.7,1.3,1.4,2,2.1L276.5,72.4z M34.3,75L33,74.5c0.4-0.9,0.8-1.8,1.2-2.6l1.2,0.6
      C35,73.3,34.6,74.2,34.3,75z M279.5,76.2c-0.5-0.7-1.1-1.5-1.7-2.2l1.1-0.9c0.6,0.8,1.2,1.5,1.7,2.3L279.5,76.2z M32.4,79.6
      l-1.3-0.5c0.3-0.9,0.7-1.8,1.1-2.7l1.3,0.5C33.1,77.8,32.7,78.7,32.4,79.6z M282.1,80.4c-0.4-0.8-0.9-1.6-1.4-2.4l1.1-0.7
      c0.5,0.8,1,1.7,1.4,2.5L282.1,80.4z M30.7,84.3l-1.3-0.4c0.3-0.9,0.6-1.8,0.9-2.7l1.3,0.5C31.3,82.5,31,83.3,30.7,84.3z M284,84.8
      c-0.3-0.9-0.6-1.7-1-2.6l1.2-0.6c0.4,0.9,0.8,1.8,1.1,2.7L284,84.8z M29.3,89L28,88.6c0.3-0.9,0.5-1.8,0.8-2.7l1.3,0.4
      C29.8,87.1,29.5,88.1,29.3,89L29.3,89z M285.2,89.5c-0.2-0.9-0.4-1.8-0.6-2.7l1.3-0.4c0.3,0.9,0.5,1.9,0.6,2.8L285.2,89.5z
      M28,93.7l-1.3-0.3c0.2-0.9,0.5-1.8,0.7-2.7l1.3,0.4C28.4,91.9,28.2,92.8,28,93.7z M285.9,94.3c-0.1-0.9-0.2-1.7-0.3-2.5l0-0.3
      l1.4-0.2l0,0.3c0.1,0.8,0.2,1.6,0.3,2.5L285.9,94.3z M26.7,98.5l-1.3-0.3c0.2-0.9,0.5-1.8,0.7-2.8l1.3,0.3
      C27.2,96.7,27,97.6,26.7,98.5L26.7,98.5z M286.7,99.3c-0.2-1-0.4-1.9-0.5-2.8l1.3-0.2c0.2,0.9,0.3,1.8,0.5,2.8L286.7,99.3z
      M25.6,103.3l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.8l1.3,0.3C26,101.5,25.8,102.4,25.6,103.3L25.6,103.3z M287.9,104.1
      c-0.2-0.9-0.5-1.9-0.7-2.8l1.3-0.3c0.2,0.9,0.4,1.8,0.7,2.7L287.9,104.1z M24.5,108.2l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.8l1.3,0.3
      C24.9,106.3,24.7,107.3,24.5,108.2z M289.2,108.9c-0.3-0.9-0.6-1.9-0.8-2.7l1.3-0.4c0.2,0.9,0.5,1.8,0.8,2.7L289.2,108.9z
      M23.4,113l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.8l1.3,0.3C23.8,111.2,23.6,112.1,23.4,113z M290.7,113.6c-0.3-0.9-0.6-1.8-0.9-2.7
      l1.3-0.4c0.3,0.9,0.6,1.8,0.9,2.7L290.7,113.6z M22.3,117.8l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.7l1.3,0.3
      C22.8,116,22.5,116.9,22.3,117.8z M292.3,118.3c-0.3-0.9-0.6-1.8-0.9-2.7l1.3-0.4c0.3,0.9,0.6,1.8,0.9,2.7L292.3,118.3z
      M21.2,122.7l-1.3-0.3c0.2-0.9,0.5-1.8,0.7-2.7l1.3,0.3C21.6,120.8,21.4,121.7,21.2,122.7z M294,123c-0.3-0.9-0.6-1.8-0.9-2.7
      l1.3-0.5c0.3,0.9,0.6,1.8,0.9,2.7L294,123z M19.9,127.5l-1.3-0.4c0.3-0.9,0.5-1.8,0.7-2.7l1.3,0.3
      C20.4,125.6,20.1,126.5,19.9,127.5L19.9,127.5z M295.6,127.7c-0.3-0.9-0.6-1.8-0.9-2.7l1.3-0.5c0.3,0.9,0.6,1.8,0.9,2.7
      L295.6,127.7z M18.5,132.2l-1.3-0.4c0.3-0.9,0.5-1.8,0.8-2.7l1.3,0.4C19,130.4,18.7,131.4,18.5,132.2z M297.3,132.4
      c-0.3-0.9-0.6-1.8-0.9-2.7l1.3-0.4c0.3,0.9,0.6,1.8,0.9,2.7L297.3,132.4z M16.9,136.9l-1.3-0.5c0.3-0.9,0.6-1.8,0.9-2.7l1.3,0.4
      C17.5,135.2,17.2,136.1,16.9,136.9z M298.8,137.1c-0.3-0.9-0.6-1.8-0.9-2.7l1.3-0.4c0.3,0.9,0.6,1.8,0.9,2.7L298.8,137.1z
      M15.2,141.6l-1.3-0.4c0.3-0.9,0.6-1.8,0.9-2.7l1.3,0.5C15.8,139.8,15.5,140.7,15.2,141.6z M300.2,141.8c-0.2-0.9-0.5-1.8-0.8-2.7
      l1.3-0.4c0.3,0.9,0.5,1.8,0.8,2.7L300.2,141.8z M14,146.3l-1.3-0.3c0.2-0.9,0.4-1.9,0.7-2.8l1.3,0.4
      C14.4,144.5,14.1,145.4,14,146.3L14,146.3z M301.4,146.6c-0.2-0.9-0.4-1.8-0.7-2.7l1.3-0.3c0.2,0.9,0.5,1.9,0.7,2.8L301.4,146.6z
      M13.2,151.1l-1.4-0.1c0.1-1,0.2-1.9,0.4-2.8l1.3,0.2C13.4,149.3,13.3,150.2,13.2,151.1L13.2,151.1z M302.4,151.4
      c-0.1-0.9-0.3-1.8-0.5-2.8l1.3-0.3c0.2,1,0.4,1.9,0.5,2.8L302.4,151.4z M12.9,156l-1.4,0c0-1,0.1-1.9,0.1-2.9l1.4,0.1
      C13,154.1,12.9,155.1,12.9,156z M303,156.2c-0.1-0.9-0.2-1.8-0.3-2.8l1.3-0.2c0.1,1,0.2,1.9,0.3,2.8L303,156.2z M11.7,161
      c-0.1-1-0.1-1.9-0.1-2.9l1.4,0c0,0.9,0.1,1.9,0.1,2.8L11.7,161z M304.6,161.1l-1.4,0v-0.3c0-0.8,0-1.7-0.1-2.5l1.4-0.1
      c0,0.9,0.1,1.7,0.1,2.6L304.6,161.1z M12.1,166c-0.1-1-0.2-1.9-0.3-2.8l1.4-0.1c0.1,0.9,0.2,1.8,0.3,2.8L12.1,166z M304.3,166.1
      l-1.4-0.2c0.1-0.9,0.2-1.8,0.2-2.8l1.4,0.1C304.5,164.2,304.4,165.2,304.3,166.1z M12.9,170.9c-0.2-1-0.3-1.9-0.5-2.8l1.3-0.2
      c0.1,0.9,0.3,1.8,0.4,2.8L12.9,170.9z M303.3,171.1l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.7l1.3,0.2C303.8,169.2,303.6,170.2,303.3,171.1
      z M13.8,175.8c-0.2-1-0.4-1.9-0.6-2.8l1.3-0.3c0.2,0.9,0.4,1.8,0.6,2.8L13.8,175.8z M301.7,175.9l-1.2-0.5c0.4-0.8,0.7-1.7,1-2.6
      l1.3,0.4C302.4,174.1,302.1,175,301.7,175.9L301.7,175.9z M299.9,180.4l-1.3-0.4c0.3-0.9,0.6-1.8,1-2.7l1.3,0.5
      C300.6,178.6,300.2,179.5,299.9,180.4z M15,180.6c-0.2-0.9-0.4-1.8-0.7-2.8l1.3-0.3c0.2,0.9,0.4,1.8,0.6,2.7L15,180.6z
      M298.5,185.1l-1.3-0.3c0.3-0.9,0.5-1.9,0.8-2.7l1.3,0.4C299,183.3,298.8,184.2,298.5,185.1L298.5,185.1z M16.2,185.5
      c-0.2-0.9-0.5-1.8-0.7-2.7l1.3-0.3c0.2,0.9,0.5,1.8,0.7,2.7L16.2,185.5z M297.4,189.9l-1.3-0.3c0.2-1,0.4-1.9,0.6-2.8l1.3,0.3
      C297.8,188,297.6,189,297.4,189.9L297.4,189.9z M17.4,190.2c-0.2-0.9-0.5-1.8-0.7-2.7l1.3-0.3c0.2,0.9,0.5,1.8,0.7,2.7L17.4,190.2z
      M296.4,194.8l-1.3-0.2c0.2-0.9,0.3-1.9,0.5-2.8l1.3,0.3C296.8,192.9,296.6,193.8,296.4,194.8L296.4,194.8z M18.7,195
      c-0.2-0.9-0.5-1.8-0.7-2.7l1.3-0.3c0.2,0.9,0.5,1.8,0.7,2.7L18.7,195z M295.6,199.6l-1.3-0.2c0.2-0.9,0.3-1.9,0.5-2.8l1.3,0.2
      C295.9,197.8,295.8,198.7,295.6,199.6z M19.9,199.8c-0.2-0.9-0.5-1.8-0.7-2.7l1.3-0.3c0.2,0.9,0.5,1.8,0.7,2.8L19.9,199.8z
      M294.8,204.5l-1.3-0.2c0.1-0.9,0.3-1.9,0.4-2.8l1.3,0.2C295.1,202.7,295,203.6,294.8,204.5z M21,204.6c-0.2-0.9-0.4-1.8-0.6-2.7
      l1.3-0.3c0.2,0.9,0.4,1.8,0.6,2.8L21,204.6z M294.1,209.4l-1.3-0.2c0.1-0.9,0.3-1.9,0.4-2.8l1.3,0.2
      C294.4,207.5,294.2,208.5,294.1,209.4z M22,209.4c-0.2-0.9-0.3-1.8-0.5-2.8l1.3-0.3c0.2,1,0.4,1.9,0.5,2.8L22,209.4z M22.8,214.3
      c-0.1-0.9-0.3-1.8-0.4-2.8l1.3-0.2c0.2,1,0.3,1.9,0.4,2.8L22.8,214.3z M293.3,214.3l-1.3-0.2c0.2-0.9,0.3-1.9,0.5-2.8l1.3,0.2
      C293.6,212.4,293.4,213.4,293.3,214.3L293.3,214.3z M292.4,219.2l-1.3-0.3c0.2-0.9,0.4-1.8,0.5-2.8l1.3,0.2
      C292.7,217.3,292.5,218.3,292.4,219.2z M23.3,219.2c-0.1-0.8-0.2-1.6-0.3-2.4l0-0.5l1.4-0.1l0,0.5c0.1,0.8,0.2,1.5,0.3,2.3
      L23.3,219.2z M291.3,224.1l-1.3-0.3c0.2-0.9,0.4-1.8,0.6-2.8l1.3,0.3C291.7,222.3,291.5,223.2,291.3,224.1z M24.3,224.1
      c-0.2-0.9-0.4-1.9-0.6-2.8l1.3-0.3c0.2,0.9,0.4,1.8,0.6,2.7L24.3,224.1z M290.1,228.9l-1.3-0.4c0.2-0.9,0.5-1.8,0.7-2.7l1.3,0.3
      C290.6,227.1,290.4,228,290.1,228.9L290.1,228.9z M25.7,229c-0.3-0.9-0.6-1.8-0.9-2.7l1.3-0.4c0.3,0.9,0.5,1.8,0.8,2.7L25.7,229z
      M27.5,233.6c-0.4-0.9-0.7-1.8-1.1-2.7l1.3-0.5c0.3,0.9,0.7,1.7,1,2.6L27.5,233.6z M288.6,233.7l-1.3-0.4c0.3-0.9,0.6-1.8,0.9-2.7
      l1.3,0.4C289.2,231.9,288.9,232.8,288.6,233.7L288.6,233.7z M29.6,238.2c-0.4-0.9-0.8-1.7-1.2-2.6l1.2-0.6c0.4,0.8,0.8,1.7,1.2,2.5
      L29.6,238.2z M286.8,238.4l-1.2-0.5c0.4-0.8,0.7-1.7,1-2.6l1.3,0.5C287.6,236.6,287.2,237.5,286.8,238.4z M31.9,242.6
      c-0.5-0.8-0.9-1.7-1.4-2.5l1.2-0.6c0.4,0.8,0.9,1.7,1.4,2.5L31.9,242.6z M284.6,242.9l-1.2-0.7c0.4-0.8,0.9-1.6,1.3-2.5l1.2,0.6
      C285.5,241.2,285.1,242,284.6,242.9L284.6,242.9z M34.5,246.9c-0.5-0.8-1-1.6-1.5-2.4l1.2-0.7c0.5,0.8,1,1.6,1.5,2.4L34.5,246.9z
      M281.2,248.1l-1.1-0.8c0.6-0.8,1.1-1.5,1.6-2.3c0.2-0.3,0.5-0.7,0.7-1.1l1.2,0.7c-0.2,0.4-0.5,0.7-0.7,1.1
      C282.3,246.6,281.8,247.4,281.2,248.1z M37.3,251c-0.6-0.8-1.1-1.6-1.6-2.3l1.1-0.8c0.5,0.8,1.1,1.6,1.6,2.3L37.3,251z M278,252
      l-1-0.9c0.6-0.7,1.2-1.4,1.8-2.1l1.1,0.9C279.3,250.6,278.7,251.3,278,252L278,252z M40.3,255c-0.6-0.8-1.2-1.5-1.7-2.3l1.1-0.8
      c0.6,0.7,1.1,1.5,1.7,2.2L40.3,255z M274.6,255.6l-0.9-1c0.7-0.7,1.3-1.3,2-2l1,0.9C275.9,254.3,275.2,254.9,274.6,255.6z
      M43.4,258.8c-0.6-0.7-1.2-1.5-1.8-2.2l1.1-0.9c0.6,0.7,1.2,1.4,1.8,2.2L43.4,258.8z M270.9,259l-0.9-1c0.7-0.6,1.4-1.3,2.1-1.9
      l0.9,1C272.3,257.7,271.6,258.4,270.9,259L270.9,259z M267,262.2l-0.8-1.1c0.8-0.6,1.5-1.2,2.2-1.8l0.9,1
      C268.5,261,267.8,261.6,267,262.2L267,262.2z M46.7,262.5c-0.6-0.7-1.3-1.4-1.9-2.1l1-0.9c0.6,0.7,1.3,1.4,1.9,2.1L46.7,262.5z
      M263,265.2l-0.8-1.1c0.8-0.6,1.5-1.1,2.3-1.7l0.8,1.1C264.6,264,263.8,264.6,263,265.2L263,265.2z M50.2,266.1
      c-0.7-0.7-1.4-1.3-2-2l1-0.9c0.6,0.7,1.3,1.3,2,2L50.2,266.1z M259,268l-0.8-1.1c0.8-0.5,1.5-1.1,2.3-1.6l0.8,1.1
      C260.6,266.9,259.8,267.5,259,268L259,268z M53.8,269.5c-0.7-0.6-1.4-1.3-2.1-1.9l0.9-1c0.7,0.6,1.4,1.3,2.1,1.9L53.8,269.5z
      M254.9,270.8l-0.8-1.1c0.8-0.5,1.6-1,2.3-1.6l0.8,1.1C256.5,269.8,255.7,270.3,254.9,270.8z M57.6,272.8c-0.7-0.6-1.5-1.2-2.2-1.9
      l0.9-1c0.7,0.6,1.4,1.2,2.1,1.8L57.6,272.8z M250.7,273.5l-0.7-1.1c0.8-0.5,1.6-1,2.4-1.5l0.7,1.1
      C252.3,272.5,251.5,273,250.7,273.5L250.7,273.5z M61.5,275.9c-0.8-0.6-1.5-1.2-2.2-1.8l0.9-1.1c0.7,0.6,1.5,1.2,2.2,1.7
      L61.5,275.9z M246.6,276.2l-0.7-1.1l2.4-1.5l0.7,1.1L246.6,276.2z M65.5,278.9c-0.8-0.5-1.6-1.1-2.3-1.7l0.8-1.1
      c0.8,0.6,1.5,1.1,2.3,1.7L65.5,278.9z M242.4,278.9l-0.7-1.1c0.8-0.5,1.6-1,2.4-1.5l0.7,1.1C244,277.9,243.2,278.4,242.4,278.9
      L242.4,278.9z M238.3,281.6l-0.8-1.1c0.8-0.5,1.6-1,2.4-1.6l0.7,1.1C239.8,280.6,239.1,281.1,238.3,281.6z M69.6,281.7
      c-0.8-0.5-1.6-1-2.4-1.6L68,279c0.8,0.5,1.6,1,2.4,1.6L69.6,281.7z M73.9,284.3c-0.8-0.5-1.6-1-2.4-1.5l0.7-1.2
      c0.8,0.5,1.6,1,2.4,1.5L73.9,284.3z M234.2,284.4l-0.8-1.1c0.8-0.5,1.5-1.1,2.3-1.6l0.8,1.1C235.8,283.4,235,283.9,234.2,284.4
      L234.2,284.4z M78.3,286.7c-0.8-0.4-1.7-0.9-2.5-1.4l0.7-1.2c0.8,0.5,1.7,0.9,2.5,1.3L78.3,286.7z M230.2,287.4l-0.8-1.1
      c0.7-0.6,1.5-1.1,2.3-1.7l0.8,1.1C231.7,286.2,231,286.8,230.2,287.4L230.2,287.4z M82.7,288.9c-0.9-0.4-1.7-0.8-2.6-1.2l0.6-1.2
      c0.8,0.4,1.7,0.8,2.5,1.2L82.7,288.9z M226.4,290.4l-0.9-1c0.7-0.6,1.4-1.2,2.2-1.8l0.8,1.1C227.8,289.2,227.1,289.8,226.4,290.4
      L226.4,290.4z M87.3,290.9c-0.9-0.4-1.8-0.7-2.6-1.1l0.6-1.2c0.8,0.4,1.7,0.7,2.6,1.1L87.3,290.9z M91.9,292.7
      c-0.9-0.3-1.8-0.6-2.7-1l0.5-1.3c0.9,0.3,1.8,0.7,2.6,1L91.9,292.7z M222.7,293.7l-0.9-1l0.2-0.2c0.6-0.6,1.3-1.2,1.9-1.8l0.9,1
      c-0.7,0.6-1.3,1.2-1.9,1.7L222.7,293.7z M96.7,294.2c-0.9-0.3-1.8-0.6-2.7-0.8l0.4-1.3c0.9,0.3,1.8,0.6,2.7,0.8L96.7,294.2z
      M101.5,295.5c-0.9-0.2-1.8-0.5-2.8-0.7l0.4-1.3c0.9,0.2,1.8,0.5,2.7,0.7L101.5,295.5z M106.4,296.5c-0.9-0.2-1.9-0.3-2.8-0.5
      l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.5L106.4,296.5z M218.9,297l-0.8-1.1c0.7-0.6,1.5-1.2,2.2-1.8l0.9,1
      C220.4,295.8,219.7,296.4,218.9,297L218.9,297z M111.3,297.3c-0.6-0.1-1.2-0.2-1.8-0.3c-0.3,0-0.7-0.1-1-0.1l0.2-1.3
      c0.3,0,0.7,0.1,1,0.1c0.6,0.1,1.2,0.2,1.8,0.3L111.3,297.3z M116.1,298.3c-1-0.2-1.9-0.4-2.8-0.6l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.6
      L116.1,298.3z M120.9,299.4c-0.9-0.2-1.9-0.5-2.7-0.7l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.7L120.9,299.4z M214.9,299.9l-0.7-1.1
      c0.8-0.5,1.6-1,2.3-1.6l0.8,1.1C216.4,298.8,215.7,299.4,214.9,299.9z M125.7,300.7c-0.9-0.2-1.8-0.5-2.7-0.7l0.3-1.3
      c0.9,0.2,1.8,0.5,2.7,0.7L125.7,300.7z M130.5,301.9l-2.7-0.7l0.3-1.3l2.7,0.7L130.5,301.9z M210.5,302.4l-0.6-1.2
      c0.8-0.4,1.6-0.9,2.4-1.4l0.7,1.2C212.2,301.5,211.4,302,210.5,302.4L210.5,302.4z M135.3,303.2c-0.9-0.2-1.8-0.5-2.7-0.7l0.3-1.3
      c0.9,0.2,1.8,0.5,2.7,0.7L135.3,303.2z M140.1,304.3c-0.9-0.2-1.8-0.4-2.8-0.7l0.3-1.3c0.9,0.2,1.8,0.4,2.7,0.7L140.1,304.3z
      M206,304.5l-0.5-1.3c0.9-0.4,1.7-0.7,2.6-1.1l0.6,1.2C207.7,303.8,206.9,304.1,206,304.5L206,304.5z M144.9,305.4
      c-0.9-0.2-1.9-0.4-2.8-0.6l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.6L144.9,305.4z M201.3,306.2l-0.4-1.3c0.9-0.3,1.8-0.6,2.6-0.9l0.5,1.3
      C203.1,305.6,202.2,305.9,201.3,306.2L201.3,306.2z M149.8,306.5c-0.9-0.2-1.9-0.4-2.8-0.6l0.3-1.3c0.9,0.2,1.8,0.4,2.8,0.6
      L149.8,306.5z M154.7,307.4c-0.9-0.2-1.9-0.3-2.8-0.5l0.3-1.3c0.9,0.2,1.8,0.3,2.8,0.5L154.7,307.4z M196.4,307.5l-0.3-1.3
      c0.9-0.2,1.8-0.4,2.7-0.7l0.4,1.3C198.3,307.1,197.4,307.3,196.4,307.5L196.4,307.5z M159.6,308.2c-0.9-0.1-1.9-0.3-2.8-0.4
      l0.2-1.3c0.9,0.1,1.9,0.3,2.8,0.4L159.6,308.2z M191.5,308.5l-0.2-1.3c0.9-0.1,1.8-0.3,2.8-0.5l0.3,1.3
      C193.4,308.2,192.5,308.4,191.5,308.5L191.5,308.5z M164.6,308.8c-0.9-0.1-1.9-0.2-2.8-0.3l0.2-1.3c0.9,0.1,1.9,0.2,2.8,0.3
      L164.6,308.8z M186.6,309.1l-0.1-1.4c0.9-0.1,1.9-0.2,2.8-0.3l0.2,1.3C188.5,308.9,187.5,309.1,186.6,309.1L186.6,309.1z
      M169.5,309.2c-0.9-0.1-1.9-0.1-2.8-0.2l0.1-1.4c0.9,0.1,1.9,0.2,2.8,0.2L169.5,309.2z M181.6,309.5l-0.1-1.4
      c0.9,0,1.9-0.1,2.8-0.2l0.1,1.4C183.5,309.4,182.6,309.4,181.6,309.5L181.6,309.5z M174.5,309.5c-0.9,0-1.9-0.1-2.8-0.1l0.1-1.4
      c0.9,0.1,1.9,0.1,2.8,0.1L174.5,309.5z M177.7,309.6c-0.4,0-0.7,0-1.1,0l0-1.4c0.9,0,1.9,0,2.8,0l0,1.4
      C178.9,309.5,178.3,309.6,177.7,309.6z"/>
      <path fill="#B2E1EB" d="M131.3,11l-0.1-1.4c1.2-0.1,2.4-0.1,3.6-0.1l0,1.4C133.7,10.9,132.5,11,131.3,11z M139.7,11
      c-1.2-0.1-2.3-0.1-3.5-0.1l0-1.4c1.2,0,2.4,0.1,3.6,0.1L139.7,11z M126.4,11.4l-0.2-1.4c1.2-0.1,2.4-0.2,3.6-0.3l0.1,1.4
      C128.8,11.2,127.6,11.3,126.4,11.4L126.4,11.4z M144.6,11.5c-1.1-0.1-2.3-0.3-3.5-0.3l0.1-1.4c1.2,0.1,2.4,0.2,3.6,0.4L144.6,11.5z
      M121.6,12.1l-0.2-1.4c1.2-0.2,2.4-0.4,3.5-0.5l0.2,1.4C123.9,11.7,122.7,11.9,121.6,12.1z M149.5,12.3c-1.1-0.2-2.3-0.4-3.4-0.6
      l0.2-1.4c1.2,0.2,2.4,0.4,3.5,0.6L149.5,12.3z M116.7,13l-0.3-1.4c1.2-0.2,2.3-0.5,3.5-0.7l0.2,1.4C119,12.5,117.9,12.7,116.7,13
      L116.7,13z M154.3,13.4c-0.9-0.2-1.9-0.4-2.8-0.6c-0.2-0.1-0.4-0.1-0.6-0.1l0.3-1.4c0.2,0,0.4,0.1,0.7,0.2c0.9,0.2,1.9,0.4,2.8,0.6
      L154.3,13.4z M111.9,14.1l-0.4-1.4c1.2-0.3,2.3-0.6,3.5-0.8l0.3,1.4C114.2,13.6,113.1,13.8,111.9,14.1z M159.1,14.4
      c-1.2-0.3-2.3-0.5-3.5-0.8l0.3-1.4c1.1,0.3,2.3,0.5,3.5,0.8L159.1,14.4z M164,15.5c-1.2-0.2-2.3-0.5-3.5-0.7l0.3-1.4
      c1.1,0.2,2.3,0.5,3.5,0.7L164,15.5z M107.2,15.5l-0.4-1.4c1.1-0.4,2.3-0.7,3.4-1l0.4,1.4C109.5,14.8,108.3,15.1,107.2,15.5z
      M168.8,16.5c-1.1-0.2-2.3-0.5-3.4-0.7l0.1-0.7l0.1-0.7l-0.1,0.7l0.2-0.7c1.1,0.2,2.3,0.5,3.4,0.7L168.8,16.5z M102.5,17l-0.5-1.3
      c1.1-0.4,2.3-0.8,3.4-1.1l0.4,1.3C104.8,16.3,103.6,16.6,102.5,17z M173.6,17.5c-1.2-0.3-2.3-0.5-3.5-0.7l0.3-1.4
      c1.2,0.2,2.3,0.5,3.5,0.7L173.6,17.5z M178.5,18.6c-1.1-0.3-2.3-0.5-3.4-0.8l0.3-1.4c1.2,0.3,2.3,0.5,3.5,0.8L178.5,18.6z
      M97.9,18.7l-0.5-1.3c1.1-0.4,2.2-0.9,3.3-1.3l0.5,1.3C100.1,17.9,99,18.3,97.9,18.7L97.9,18.7z M183.3,19.7
      c-1.1-0.3-2.3-0.6-3.4-0.8l0.3-1.4c1.2,0.3,2.3,0.5,3.5,0.8L183.3,19.7z M93.4,20.6l-0.6-1.3c1.1-0.5,2.2-0.9,3.3-1.4l0.5,1.3
      C95.5,19.7,94.4,20.2,93.4,20.6z M188.1,21c-1.1-0.3-2.3-0.6-3.4-0.9l0.3-1.4c1.2,0.3,2.3,0.6,3.4,0.9L188.1,21z M192.8,22.3
      c-1.1-0.3-2.2-0.6-3.4-1l0.4-1.4c1.2,0.3,2.3,0.6,3.4,1L192.8,22.3z M88.9,22.7l-0.6-1.3c1.1-0.5,2.2-1,3.2-1.5l0.6,1.3
      C91,21.7,89.9,22.2,88.9,22.7L88.9,22.7z M197.5,23.7c-1.1-0.4-2.2-0.7-3.4-1.1l0.4-1.4c1.2,0.4,2.3,0.7,3.4,1.1L197.5,23.7z
      M84.4,24.9l-0.7-1.3c1.1-0.5,2.1-1.1,3.2-1.6l0.6,1.3C86.5,23.8,85.5,24.4,84.4,24.9L84.4,24.9z M202.2,25.4
      c-1.1-0.4-2.2-0.8-3.3-1.2l0.5-1.3c1.1,0.4,2.3,0.8,3.4,1.2L202.2,25.4z M206.8,27.2c-1-0.4-2.1-0.9-3.3-1.3l0.5-1.3
      c1.1,0.4,2.2,0.9,3.3,1.3L206.8,27.2z M80.1,27.2L79.4,26c1-0.6,2.1-1.2,3.1-1.7l0.7,1.2C82.2,26.1,81.1,26.7,80.1,27.2L80.1,27.2z
      M211.2,29.3c-1-0.5-2.1-1-3.2-1.5l0.6-1.3c1.1,0.5,2.2,1,3.2,1.5L211.2,29.3z M75.8,29.7l-0.7-1.2c1-0.6,2-1.2,3.1-1.8l0.7,1.2
      C77.9,28.5,76.8,29.1,75.8,29.7z M215.5,31.6c-1-0.6-2-1.1-3.1-1.7l0.7-1.3c1.1,0.6,2.1,1.1,3.1,1.7L215.5,31.6z M71.7,32.3
      l-0.8-1.2c1-0.6,2-1.3,3-1.9l0.7,1.2C73.6,31.1,72.6,31.7,71.7,32.3z M219.6,34.3c-0.9-0.7-1.9-1.3-2.9-1.9l0.7-1.2
      c1,0.6,2,1.3,3,2L219.6,34.3z M67.6,35.1l-0.8-1.2c1-0.7,2-1.3,2.9-2l0.8,1.2C69.5,33.7,68.5,34.4,67.6,35.1L67.6,35.1z
      M223.7,37.1c-1-0.7-2-1.3-3-2l0.8-1.2c0.9,0.6,1.9,1.3,2.9,2L223.7,37.1z M63.5,37.9l-0.8-1.1c1-0.7,1.9-1.4,2.9-2.1l0.8,1.2
      C65.4,36.5,64.5,37.2,63.5,37.9L63.5,37.9z M228,39.7c-1.1-0.7-2.1-1.3-3-1.9l0.8-1.2c0.9,0.6,1.9,1.2,3,1.9L228,39.7z M59.6,40.9
      l-0.9-1.1c0.9-0.7,1.9-1.4,2.8-2.1l0.8,1.1C61.5,39.4,60.5,40.2,59.6,40.9L59.6,40.9z M232.3,42.2c-1-0.6-2.1-1.2-3.1-1.8l0.7-1.2
      c1,0.6,2,1.2,3,1.8L232.3,42.2z M55.8,44l-0.9-1.1c0.9-0.7,1.8-1.5,2.8-2.2l0.9,1.1C57.6,42.5,56.7,43.2,55.8,44L55.8,44z
      M236.6,44.7c-1-0.6-2.1-1.2-3.1-1.7l0.7-1.2c1,0.6,2,1.2,3.1,1.7L236.6,44.7z M240.9,47.1l-3.1-1.7l0.7-1.2l3.1,1.7L240.9,47.1z
      M52,47.2l-0.9-1.1c0.9-0.8,1.8-1.6,2.7-2.3l0.9,1.1C53.8,45.6,52.9,46.4,52,47.2L52,47.2z M245.2,49.5c-1-0.6-2.1-1.2-3.1-1.7
      l0.7-1.2c1,0.6,2.1,1.1,3.1,1.7L245.2,49.5z M48.4,50.5l-1-1c0.9-0.8,1.7-1.6,2.6-2.4l0.9,1.1C50.1,48.9,49.2,49.7,48.4,50.5z
      M249.5,51.9c-1-0.6-2-1.2-3.1-1.8l0.7-1.2c1,0.6,2.1,1.2,3.1,1.8L249.5,51.9z M44.8,53.9l-1-1c0.8-0.8,1.7-1.7,2.5-2.5l1,1
      C46.5,52.2,45.6,53.1,44.8,53.9L44.8,53.9z M253.8,54.5c-1-0.6-2-1.2-3-1.8l0.7-1.2c1.1,0.6,2.1,1.2,3.1,1.8L253.8,54.5z M258,57.1
      c-0.9-0.6-1.9-1.2-3-1.9l0.7-1.2c1,0.6,2.1,1.3,3,1.9L258,57.1z M41.4,57.4l-1-1c0.8-0.9,1.6-1.7,2.5-2.6l1,1
      C43,55.7,42.2,56.6,41.4,57.4z M262,59.8c-0.9-0.7-1.9-1.3-2.9-2l0.8-1.2c1,0.7,2,1.4,2.9,2L262,59.8z M38.1,61.1L37,60.2
      c0.2-0.3,0.5-0.6,0.8-0.9c0.5-0.6,1.1-1.2,1.6-1.8l1,1c-0.5,0.6-1.1,1.2-1.6,1.8C38.6,60.5,38.3,60.8,38.1,61.1L38.1,61.1z
      M266,62.8c-0.9-0.7-1.8-1.4-2.8-2.1l0.8-1.1c1,0.7,1.9,1.4,2.8,2.2L266,62.8z M35.1,64.9l-1.1-0.8c0.7-1,1.4-1.9,2.2-2.8l1.1,0.9
      C36.5,63.1,35.8,64,35.1,64.9L35.1,64.9z M269.7,66c-0.8-0.8-1.7-1.5-2.6-2.3l0.9-1.1c0.9,0.8,1.8,1.6,2.7,2.3L269.7,66z M32.4,69
      l-1.2-0.7c0.6-1,1.3-2,1.9-3l1.2,0.8C33.6,67,33,68,32.4,69z M273.2,69.4c-0.8-0.8-1.6-1.7-2.4-2.5l1-1c0.9,0.8,1.7,1.7,2.5,2.6
      L273.2,69.4z M276.3,73.2c-0.7-0.9-1.4-1.8-2.2-2.7l1.1-0.9c0.8,0.9,1.6,1.9,2.2,2.8L276.3,73.2z M30,73.3l-1.3-0.7
      c0.6-1.1,1.1-2.1,1.7-3.1l1.2,0.7C31.1,71.2,30.5,72.2,30,73.3L30,73.3z M278.9,77.3c-0.5-1-1.2-2-1.8-3l1.2-0.8
      c0.7,1,1.3,2,1.9,3.1L278.9,77.3z M27.9,77.7l-1.3-0.6c0.5-1.1,1-2.2,1.5-3.2l1.3,0.6C28.8,75.6,28.3,76.6,27.9,77.7z M280.9,81.6
      c-0.4-1.1-0.9-2.1-1.4-3.2l1.3-0.6c0.5,1.1,1,2.2,1.4,3.3L280.9,81.6z M26,82.3l-1.3-0.5c0.4-1.1,0.9-2.2,1.3-3.3l1.3,0.6
      C26.8,80.1,26.4,81.2,26,82.3L26,82.3z M282.2,86.3c-0.2-1.1-0.5-2.2-0.9-3.3l1.3-0.4c0.4,1.1,0.7,2.3,0.9,3.5L282.2,86.3z
      M24.3,86.9L23,86.5c0.4-1.1,0.8-2.3,1.2-3.4l1.3,0.5C25.1,84.7,24.7,85.8,24.3,86.9z M282.8,91.1c-0.1-1.2-0.2-2.3-0.4-3.4
      l1.4-0.2c0.2,1.2,0.3,2.4,0.4,3.6L282.8,91.1z M22.9,91.6l-1.4-0.4c0.3-1.2,0.7-2.3,1-3.4l1.3,0.4C23.6,89.3,23.2,90.5,22.9,91.6z
      M283.2,96.1c-0.1-1.2-0.3-2.4-0.3-3.6l1.4-0.1c0.1,1.1,0.2,2.3,0.3,3.5L283.2,96.1z M21.6,96.4L20.2,96c0.3-1.2,0.6-2.3,0.9-3.4
      l1.4,0.4C22.2,94.1,21.9,95.2,21.6,96.4L21.6,96.4z M284,101c-0.2-1.2-0.4-2.4-0.6-3.5l1.4-0.2c0.2,1.1,0.4,2.3,0.6,3.4L284,101z
      M20.5,101.2l-1.4-0.3c0.3-1.2,0.5-2.3,0.8-3.5l1.4,0.3C21,98.9,20.7,100,20.5,101.2L20.5,101.2z M285.1,105.9
      c-0.3-1.2-0.6-2.3-0.8-3.5l1.4-0.3c0.2,1.1,0.5,2.3,0.8,3.4L285.1,105.9z M19.4,106l-1.4-0.3c0.2-1.2,0.5-2.3,0.7-3.5l1.4,0.3
      C19.9,103.7,19.7,104.9,19.4,106z M286.5,110.7c-0.4-1.2-0.7-2.3-1-3.4l1.4-0.4c0.3,1.1,0.6,2.2,1,3.4L286.5,110.7z M18.5,110.9
      l-1.4-0.3c0.2-1.2,0.4-2.3,0.7-3.5l1.4,0.3C18.9,108.6,18.7,109.7,18.5,110.9z M288,115.4c-0.4-1.2-0.8-2.3-1.1-3.4l1.3-0.4
      c0.3,1.1,0.7,2.2,1.1,3.3L288,115.4z M17.6,115.8l-1.4-0.2c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.3C18,113.5,17.8,114.6,17.6,115.8
      L17.6,115.8z M289.7,120.1c-0.4-1.1-0.8-2.2-1.2-3.3l1.3-0.5c0.4,1.1,0.8,2.2,1.2,3.3L289.7,120.1z M16.7,120.6l-1.4-0.2
      c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.2C17.1,118.3,16.9,119.5,16.7,120.6z M291.4,124.7c-0.4-1.1-0.8-2.2-1.2-3.3l1.3-0.5
      c0.4,1.1,0.8,2.2,1.2,3.3L291.4,124.7z M15.9,125.5l-1.4-0.2l0.6-3.5l1.4,0.2L15.9,125.5z M293.1,129.4c-0.4-0.9-0.7-1.9-1.1-2.8
      l-0.2-0.5l1.3-0.5l0.2,0.5c0.4,0.9,0.7,1.9,1.1,2.8L293.1,129.4z M15,130.4l-1.4-0.3c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.2
      C15.4,128.1,15.2,129.2,15,130.4L15,130.4z M294.8,134c-0.4-1.1-0.8-2.2-1.2-3.3l1.3-0.5c0.4,1.1,0.8,2.2,1.2,3.3L294.8,134z
      M14.1,135.3l-1.4-0.3c0.2-1.2,0.4-2.3,0.7-3.5l1.4,0.3C14.6,133,14.3,134.1,14.1,135.3L14.1,135.3z M296.4,138.7
      c-0.4-1.1-0.7-2.1-1.1-3.3l1.3-0.5c0.4,1.2,0.8,2.3,1.1,3.4L296.4,138.7z M13.1,140.1l-1.4-0.3c0.2-1.1,0.5-2.3,0.7-3.5l1.4,0.3
      C13.6,137.8,13.4,139,13.1,140.1L13.1,140.1z M297.9,143.4c-0.3-1.1-0.7-2.2-1-3.4l1.3-0.4c0.4,1.2,0.7,2.3,1,3.4L297.9,143.4z
      M12.1,145l-1.4-0.3c0.3-1.1,0.5-2.3,0.8-3.4l1.4,0.3C12.6,142.7,12.3,143.9,12.1,145z M299.1,148.2c-0.3-1.1-0.6-2.2-0.9-3.4
      l1.4-0.4c0.3,1.2,0.6,2.3,0.9,3.4L299.1,148.2z M10.9,149.8l-1.4-0.3c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.5,0.3-1.1,0.4-1.6l1.4,0.3
      c-0.1,0.5-0.3,1.1-0.4,1.6C11.2,148.6,11,149.2,10.9,149.8z M300.1,153c-0.2-1.1-0.4-2.3-0.7-3.4l1.4-0.3c0.3,1.2,0.5,2.4,0.7,3.5
      L300.1,153z M10.1,154.6l-1.4-0.2c0.1-1.2,0.3-2.4,0.5-3.6l1.4,0.3C10.4,152.3,10.3,153.4,10.1,154.6L10.1,154.6z M300.8,157.8
      c-0.1-1.1-0.2-2.3-0.4-3.5l1.4-0.2c0.2,1.2,0.3,2.4,0.4,3.6L300.8,157.8z M9.8,159.5l-1.4,0c0-1.2,0.1-2.4,0.2-3.6L10,156
      C9.9,157.1,9.9,158.3,9.8,159.5L9.8,159.5z M302.4,162.7l-1.4,0c0-0.2,0-0.4,0-0.6c0-0.9,0-1.9-0.1-2.8l1.4-0.1
      c0.1,1,0.1,2,0.1,2.9C302.4,162.3,302.4,162.5,302.4,162.7L302.4,162.7z M8.6,164.5c-0.1-1.2-0.1-2.4-0.1-3.6l1.4,0
      c0,1.1,0.1,2.3,0.1,3.5L8.6,164.5z M302,167.7l-1.4-0.2c0.1-1.1,0.3-2.3,0.3-3.5l1.4,0.1C302.3,165.4,302.2,166.6,302,167.7
      L302,167.7z M9.1,169.4c-0.2-1.2-0.3-2.4-0.4-3.5l1.4-0.1c0.1,1.1,0.2,2.3,0.4,3.5L9.1,169.4z M301,172.7l-1.4-0.4
      c0.3-1.1,0.6-2.2,0.8-3.4l1.4,0.2C301.6,170.3,301.3,171.5,301,172.7L301,172.7z M9.9,174.4c-0.2-1.2-0.4-2.4-0.6-3.5l1.4-0.2
      c0.2,1.1,0.4,2.3,0.6,3.5L9.9,174.4z M299.8,177.4l-1.4-0.3c0.3-1.2,0.6-2.4,0.9-3.5l1.4,0.4C300.3,175.1,300.1,176.3,299.8,177.4z
      M10.9,179.3c-0.3-1.2-0.5-2.4-0.8-3.5l1.4-0.3c0.2,1.1,0.5,2.3,0.7,3.4L10.9,179.3z M298.7,182.3l-1.4-0.3
      c0.2-1.2,0.5-2.4,0.7-3.5l1.4,0.3C299.2,179.9,299,181.1,298.7,182.3L298.7,182.3z M12.1,184.1c-0.3-1.2-0.6-2.3-0.9-3.4l1.4-0.3
      c0.3,1.1,0.5,2.2,0.9,3.4L12.1,184.1z M297.8,187.1l-1.4-0.2c0.2-1.2,0.4-2.4,0.6-3.5l1.4,0.3C298.3,184.7,298.1,185.9,297.8,187.1
      L297.8,187.1z M13.4,188.9c-0.3-1.1-0.6-2.3-0.9-3.4l1.4-0.4c0.3,1.1,0.6,2.3,0.9,3.4L13.4,188.9z M297,192l-1.4-0.2
      c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.2C297.4,189.7,297.2,190.8,297,192L297,192z M14.7,193.7l-1-3.4l1.4-0.4l1,3.4L14.7,193.7z
      M296.2,196.9l-1.4-0.2l0,0c0.2-1.2,0.4-2.3,0.6-3.4l1.4,0.2C296.6,194.5,296.4,195.7,296.2,196.9L296.2,196.9z M16,198.4
      c-0.3-1.1-0.6-2.3-0.9-3.4l1.4-0.4c0.3,1.1,0.6,2.3,0.9,3.4L16,198.4z M295.4,201.8l-1.4-0.2c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.2
      C295.8,199.4,295.6,200.6,295.4,201.8z M17.3,203.2c-0.3-1.1-0.6-2.2-0.9-3.4l1.4-0.4c0.3,1.2,0.6,2.4,0.9,3.4L17.3,203.2z
      M294.6,206.7l-1.4-0.2c0.2-1.2,0.4-2.3,0.6-3.5l1.4,0.2C295,204.3,294.8,205.5,294.6,206.7z M18.4,208c-0.2-1.1-0.5-2.2-0.8-3.4
      l1.4-0.3c0.3,1.2,0.6,2.4,0.8,3.5L18.4,208z M293.7,211.5l-1.4-0.3c0.2-1.2,0.4-2.3,0.7-3.5l1.4,0.3
      C294.1,209.2,293.9,210.4,293.7,211.5z M19.3,212.8c-0.2-1.1-0.4-2.3-0.6-3.5l1.4-0.3c0.2,1.2,0.5,2.4,0.7,3.5L19.3,212.8z
      M292.6,216.4l-1.4-0.3c0.2-1.1,0.5-2.2,0.7-3.4l1.4,0.3C293.1,214.1,292.9,215.3,292.6,216.4L292.6,216.4z M20,217.7
      c-0.1-1.1-0.3-2.3-0.5-3.5L21,214c0.2,1.2,0.3,2.4,0.5,3.5L20,217.7z M291.4,221.3l-1.4-0.4c0.3-1.1,0.6-2.2,0.9-3.4l1.4,0.3
      C292,219,291.7,220.1,291.4,221.3L291.4,221.3z M20.4,222.6c0-0.3,0-0.5,0-0.8c0-0.9-0.1-1.8-0.2-2.7l1.4-0.1
      c0.1,0.9,0.1,1.9,0.2,2.8c0,0.3,0,0.5,0,0.8L20.4,222.6z M290,226l-1.3-0.4c0.3-1.1,0.7-2.2,1-3.4l1.4,0.4
      C290.7,223.8,290.4,224.9,290,226L290,226z M20.9,227.6c-0.2-1.2-0.3-2.4-0.4-3.6l1.4-0.1c0.1,1.1,0.2,2.3,0.4,3.5L20.9,227.6z
      M288.4,230.8l-1.3-0.5c0.4-1.1,0.8-2.2,1.2-3.3l1.3,0.4C289.2,228.5,288.8,229.7,288.4,230.8z M21.9,232.5
      c-0.3-1.2-0.6-2.3-0.8-3.5l1.4-0.3c0.2,1.1,0.5,2.3,0.8,3.4L21.9,232.5z M286.4,235.4l-1.3-0.6c0.5-1,1-2.1,1.4-3.2l1.3,0.5
      C287.4,233.2,286.9,234.3,286.4,235.4L286.4,235.4z M23.4,237.3c-0.4-1.1-0.8-2.3-1.1-3.4l1.4-0.4c0.3,1.1,0.7,2.2,1.1,3.3
      L23.4,237.3z M284.1,239.8l-1.2-0.7c0.6-1,1.1-2,1.7-3.1l1.3,0.6C285.3,237.7,284.7,238.8,284.1,239.8L284.1,239.8z M25.3,241.9
      c-0.5-1.1-0.9-2.2-1.4-3.3l1.3-0.5c0.4,1.1,0.9,2.2,1.3,3.2L25.3,241.9z M282.1,243l-1.2-0.8c0.7-1,1.3-2,1.9-2.9l1.2,0.7
      C283.4,241,282.8,242,282.1,243z M27.5,246.4c-0.6-1.1-1.1-2.1-1.6-3.2l1.3-0.6c0.5,1,1,2.1,1.6,3.1L27.5,246.4z M279.1,247.1
      l-1.1-0.9c0.7-0.9,1.4-1.9,2.1-2.8l1.2,0.8C280.6,245.2,279.9,246.1,279.1,247.1z M30,250.7c-0.6-1-1.3-2-1.8-3.1l1.2-0.7
      c0.6,1,1.2,2,1.8,3L30,250.7z M275.9,250.9l-1-1c0.8-0.9,1.6-1.8,2.3-2.6l1.1,0.9C277.5,249.1,276.7,250,275.9,250.9z M272.4,254.5
      l-1-1c0.9-0.8,1.7-1.7,2.5-2.5l1,1C274.1,252.8,273.3,253.6,272.4,254.5z M32.8,254.9c-0.7-1-1.4-2-2-2.9l1.2-0.8
      c0.6,1,1.3,1.9,2,2.9L32.8,254.9z M268.8,257.9l-0.9-1.1c0.9-0.8,1.8-1.6,2.6-2.4l1,1C270.6,256.2,269.7,257.1,268.8,257.9
      L268.8,257.9z M35.8,258.8c-0.8-0.9-1.5-1.9-2.2-2.8l1.1-0.8c0.7,0.9,1.4,1.9,2.2,2.8L35.8,258.8z M265,261.1l-0.9-1.1
      c0.9-0.8,1.8-1.5,2.7-2.3l0.9,1.1C266.9,259.6,266,260.3,265,261.1L265,261.1z M39.1,262.6c-0.8-0.9-1.6-1.8-2.3-2.7l1.1-0.9
      c0.7,0.9,1.5,1.8,2.3,2.7L39.1,262.6z M261.2,264.3l-0.9-1.1c0.9-0.7,1.9-1.5,2.7-2.2l0.9,1.1C263.1,262.8,262.1,263.5,261.2,264.3
      z M42.5,266.3c-0.8-0.8-1.7-1.7-2.5-2.6l1-1c0.8,0.8,1.6,1.7,2.4,2.5L42.5,266.3z M257.3,267.3l-0.9-1.1c0.9-0.7,1.9-1.4,2.8-2.2
      l0.9,1.1C259.1,265.9,258.2,266.6,257.3,267.3L257.3,267.3z M46.1,269.7c-0.9-0.8-1.8-1.6-2.6-2.4l1-1c0.8,0.8,1.7,1.6,2.6,2.4
      L46.1,269.7z M253.3,270.3l-0.8-1.1c0.9-0.7,1.9-1.4,2.8-2.1l0.9,1.1C255.2,268.9,254.3,269.6,253.3,270.3z M49.8,273
      c-0.9-0.8-1.8-1.5-2.7-2.3l0.9-1.1c0.9,0.8,1.8,1.5,2.7,2.3L49.8,273z M249.3,273.3l-0.8-1.1l2.8-2.1l0.8,1.1L249.3,273.3z
      M53.7,276.1c-0.9-0.7-1.9-1.5-2.8-2.2l0.9-1.1c0.9,0.7,1.8,1.5,2.8,2.2L53.7,276.1z M245.4,276.3l-0.9-1.1
      c0.9-0.7,1.9-1.4,2.8-2.1l0.8,1.1C247.3,274.8,246.3,275.5,245.4,276.3z M57.7,279.1c-1-0.7-1.9-1.4-2.9-2.1l0.8-1.1
      c0.9,0.7,1.9,1.4,2.8,2.1L57.7,279.1z M241.5,279.3l-0.9-1.1c0.9-0.7,1.9-1.4,2.8-2.2l0.9,1.1C243.3,277.8,242.4,278.5,241.5,279.3
      z M61.9,281.9c-1-0.6-2-1.3-3-2l0.8-1.2c1,0.7,1.9,1.3,2.9,2L61.9,281.9z M237.6,282.3l-0.9-1.1c0.9-0.7,1.8-1.4,2.8-2.2l0.9,1.1
      C239.4,280.9,238.5,281.6,237.6,282.3L237.6,282.3z M66.1,284.5c-1-0.6-2-1.2-3-1.9l0.8-1.2c1,0.6,2,1.2,3,1.8L66.1,284.5z
      M233.8,285.5l-0.9-1.1c0.9-0.8,1.8-1.5,2.7-2.3l0.9,1.1C235.6,284,234.7,284.8,233.8,285.5z M70.5,286.9c-1-0.6-2.1-1.2-3.1-1.7
      l0.7-1.2c1,0.6,2,1.2,3.1,1.7L70.5,286.9z M230.2,288.8l-1-1c0.8-0.8,1.7-1.6,2.6-2.4l0.9,1.1C231.9,287.3,231,288.1,230.2,288.8
      L230.2,288.8z M74.9,289.2c-1.1-0.5-2.1-1.1-3.2-1.6l0.7-1.3c1,0.5,2.1,1.1,3.1,1.6L74.9,289.2z M79.4,291.4
      c-1.1-0.5-2.2-1-3.2-1.5l0.6-1.3c1,0.5,2.1,1,3.2,1.5L79.4,291.4z M226.8,292.3l-1-1c0.8-0.8,1.6-1.7,2.5-2.6l1,1
      C228.3,290.7,227.5,291.5,226.8,292.3z M84,293.3c-1.1-0.4-2.2-0.9-3.3-1.4l0.6-1.3c1.1,0.5,2.2,0.9,3.2,1.4L84,293.3z M88.6,295.1
      c-1.1-0.4-2.2-0.8-3.3-1.2l0.5-1.3c1.1,0.4,2.2,0.8,3.3,1.2L88.6,295.1z M223.2,295.9l-1-1c0.8-0.8,1.7-1.6,2.5-2.5l1,1
      C225,294.2,224.1,295.1,223.2,295.9z M93.3,296.7c-1.1-0.4-2.3-0.7-3.4-1.1l0.5-1.3c1.1,0.4,2.2,0.7,3.3,1.1L93.3,296.7z M98.1,298
      c-1.1-0.3-2.3-0.6-3.4-1l0.4-1.4c1.1,0.3,2.3,0.7,3.4,1L98.1,298z M219.4,299.1l-0.9-1.1c0.9-0.7,1.8-1.5,2.7-2.2l0.9,1.1
      C221.3,297.6,220.4,298.4,219.4,299.1L219.4,299.1z M103,299.2c-0.2,0-0.4-0.1-0.6-0.1c-0.9-0.2-1.9-0.4-2.9-0.7l0.3-1.4
      c0.9,0.2,1.9,0.5,2.8,0.7c0.2,0,0.4,0.1,0.6,0.1L103,299.2z M107.7,300.5c-1.3-0.4-2.4-0.7-3.4-0.9l0.3-1.4c1,0.2,2.1,0.5,3.4,0.9
      L107.7,300.5z M112.5,301.8c-1.2-0.3-2.3-0.7-3.4-1l0.4-1.4c1.1,0.3,2.2,0.6,3.4,1L112.5,301.8z M215.3,302l-0.8-1.2
      c1-0.6,1.9-1.3,2.9-2l0.8,1.1C217.3,300.7,216.3,301.4,215.3,302L215.3,302z M117.2,303.2c-1.2-0.3-2.3-0.6-3.3-1l-0.1,0l0.4-1.4
      l0.1,0c1.1,0.3,2.2,0.6,3.3,1L117.2,303.2z M122,304.5c-1.2-0.3-2.3-0.6-3.4-1l0.4-1.4c1.1,0.3,2.2,0.6,3.4,1L122,304.5z
      M211,304.6l-0.7-1.2c1-0.5,2-1.1,3-1.7l0.7,1.2C213.1,303.4,212.1,304,211,304.6L211,304.6z M126.8,305.8
      c-1.2-0.3-2.3-0.6-3.4-0.9l0.4-1.4c1.1,0.3,2.3,0.6,3.4,0.9L126.8,305.8z M206.5,306.8l-0.6-1.3c1.1-0.5,2.1-1,3.2-1.5l0.6,1.3
      C208.7,305.8,207.6,306.3,206.5,306.8L206.5,306.8z M131.6,307c-1.2-0.3-2.3-0.6-3.4-0.9l0.4-1.4c1.1,0.3,2.3,0.6,3.4,0.9
      L131.6,307z M136.5,308.2c-1.2-0.3-2.3-0.5-3.5-0.8l0.3-1.4c1.1,0.3,2.3,0.5,3.4,0.8L136.5,308.2z M201.8,308.6l-0.5-1.3
      c1.1-0.4,2.2-0.8,3.3-1.2l0.5,1.3C204.1,307.8,203,308.2,201.8,308.6L201.8,308.6z M141.3,309.2c-1.1-0.2-2.3-0.5-3.5-0.7l0.3-1.4
      c1.1,0.3,2.3,0.5,3.4,0.7L141.3,309.2z M197.1,310l-0.4-1.4c1.1-0.3,2.3-0.6,3.4-1l0.4,1.3C199.4,309.4,198.2,309.7,197.1,310z
      M146.2,310.2c-1.1-0.2-2.3-0.4-3.5-0.7l0.3-1.4c1.2,0.2,2.3,0.5,3.5,0.7L146.2,310.2z M151.1,311c-1.1-0.2-2.3-0.4-3.5-0.6
      l0.2-1.4c1.2,0.2,2.3,0.4,3.5,0.6L151.1,311z M192.2,311.2l-0.3-1.4c1.2-0.2,2.3-0.5,3.4-0.8l0.3,1.4
      C194.5,310.7,193.4,310.9,192.2,311.2L192.2,311.2z M156,311.7c-1.2-0.1-2.3-0.3-3.5-0.5l0.2-1.4c1.2,0.2,2.3,0.3,3.5,0.5
      L156,311.7z M187.3,312l-0.2-1.4c1.2-0.2,2.3-0.3,3.5-0.6l0.3,1.4C189.6,311.7,188.4,311.8,187.3,312L187.3,312z M161,312.3
      c-1.2-0.1-2.4-0.2-3.5-0.4l0.2-1.4c1.2,0.1,2.4,0.3,3.5,0.4L161,312.3z M182.3,312.5l-0.1-1.4c1.2-0.1,2.4-0.2,3.5-0.4l0.2,1.4
      C184.7,312.3,183.5,312.4,182.3,312.5L182.3,312.5z M165.9,312.7c-1.2-0.1-2.4-0.2-3.5-0.3l0.1-1.4c1.2,0.1,2.4,0.2,3.5,0.3
      L165.9,312.7z M177.3,312.8l0-1.4c1.2,0,2.4-0.1,3.5-0.2l0.1,1.4C179.7,312.7,178.5,312.8,177.3,312.8L177.3,312.8z M170.9,312.9
      c-1.2,0-2.4-0.1-3.6-0.1l0.1-1.4c1.2,0.1,2.4,0.1,3.5,0.1L170.9,312.9z M173.5,312.9c-0.4,0-0.8,0-1.2,0l0-1.4c1.2,0,2.3,0,3.5,0
      l0,1.4C175.1,312.9,174.3,312.9,173.5,312.9"/>
      <path fill="#9FD9E7" d="M124.8,13.7c-15.5,0-30.4,4.7-41.9,9.6C61.6,32.6,40.3,48.2,26.2,65C4.5,90.8,1.5,130.9,1.5,166.7
      c0,11.3,3.9,21.9,7.6,32.1c4.1,11.1,7.9,21.6,7.2,32.9c-2.3,37.6,32.1,52.6,62.4,65.8c3.4,1.5,6.7,2.9,9.9,4.4
      c11,5,37.1,14,64.9,16.2c36.4,2.8,62.9-6.6,78.9-28.2c6.5-8.8,14.4-16.5,22.1-24c11.5-11.2,23.4-22.9,30.3-38
      c8.7-19.3,13.2-39.8,13.2-61.1c0-13.3-5.4-25.5-10.7-37.4c-5.7-12.9-11.1-25-10-38.4c1.3-15.3-6.6-28.4-24.2-40
      c-15.2-10-35.1-17.2-51.1-23l-1.9-0.7c-7.4-2.7-15.3-3.3-23.7-3.9c-9-0.7-18.3-1.4-27.1-4.9C141.4,15.1,133,13.7,124.8,13.7
      L124.8,13.7z M165.5,320c-3.9,0-7.9-0.2-12-0.5c-28-2.2-54.3-11.3-65.4-16.3c-3.2-1.4-6.4-2.9-9.9-4.4c-15.5-6.7-31.5-13.7-43.8-24
      c-14.2-11.9-20.6-26-19.5-43.3c0.7-11-3.1-21.3-7.1-32.3C3.9,188.9,0,178.2,0,166.7C0,130.6,3,90.2,25,64
      C39.3,47,60.8,31.3,82.3,22c17.7-7.7,43.9-14.7,67.7-5c8.5,3.5,17.7,4.2,26.6,4.8c8.1,0.6,16.5,1.2,24.1,4l1.8,0.7
      c16.1,5.8,36.1,13.1,51.4,23.2c18.1,11.9,26.2,25.5,24.9,41.4c-1.1,13,4.2,25,9.9,37.7c5.3,12,10.8,24.4,10.8,38
      c0,21.5-4.5,42.3-13.3,61.8c-7,15.4-19,27.1-30.6,38.5c-7.6,7.4-15.5,15.1-21.9,23.8c-8.9,11.9-21.1,20.3-36.4,25
      C187.8,318.6,177.2,320,165.5,320"/>
      <path fill="#9FD9E7" d="M33.3,69.2c-18,20.6-26.5,52.4-25.9,97.4c0.1,10.5,3.7,20.4,7.1,30c3.7,10.2,7.1,19.9,6.8,30.2
      c-1,34.1,29.8,48.7,56.9,61.5c3.3,1.6,6.4,3,9.5,4.5c8.9,4.4,32.8,13.4,59.3,15.8c4,0.4,8.3,0.6,12.7,0.6c20.4,0,44.3-4.8,61-23.1
      c6.6-7.3,14.5-13.8,22.1-20.2c13.1-10.9,26.7-22.2,34.6-37.7c9.8-19.1,14.7-39.8,14.7-61.6c0-14.3-5.9-27.5-11.5-40.3
      c-5.5-12.3-10.6-24-10.8-36.3c-0.4-33.4-45.8-49.1-73-58.5c-7.2-2.5-14.8-3-22.8-3.5c-8.2-0.6-16.6-1.1-24.6-3.9
      c-22-7.8-46-0.9-62.3,6.2C66.9,39.2,47.2,53.4,33.3,69.2z M160,310.9c-4.2,0-8.6-0.2-13.1-0.6c-26.8-2.4-50.9-11.5-59.9-15.9
      c-3.1-1.5-6.2-3-9.4-4.5c-27.6-13.1-58.9-27.9-57.9-63c0.3-10-3.1-19.5-6.7-29.6c-3.5-9.7-7.1-19.8-7.2-30.6
      c-0.3-20.7,1.3-38,4.7-53.2C14.7,94.9,21.9,79.7,32,68.2c14.1-16,33.9-30.4,54.5-39.3c16.6-7.2,41-14.1,63.5-6.2
      c7.8,2.7,16.1,3.3,24.1,3.8c7.8,0.5,15.8,1.1,23.2,3.6c16.6,5.7,34.1,12.1,48.4,21.3c17.2,11.1,25.6,23.7,25.8,38.7
      c0.1,12,5.2,23.5,10.6,35.6c5.7,12.9,11.7,26.3,11.7,41c0,22.1-5,43.1-14.9,62.4c-8.1,15.8-21.8,27.2-35,38.2
      c-7.6,6.3-15.4,12.8-21.9,20c-9,9.9-20.7,16.7-34.9,20.4C178.8,309.9,169.8,310.9,160,310.9"/>
      <path fill="#8CD1E2" d="M39.7,72.9l0.6,0.6c-18.9,20.6-28.3,52.8-27.2,93.2c0.3,9.8,3.5,19.1,6.7,28c3.3,9.3,6.3,18,6.4,27.3
      c0.3,30.8,27.8,45,52,57.6c2.9,1.5,5.7,3,8.4,4.4c10.9,5.8,32.4,13.1,54.2,15.5c19.9,2.1,47.9,0.9,68.2-16.9
      c6.6-5.8,14.1-11.1,21.4-16.3c15-10.6,30.5-21.5,39.6-37.5c10.8-18.8,16.3-39.7,16.3-62.1c0-15.5-6.5-29.9-12.7-43.8
      c-5.2-11.5-10-22.4-11.3-33.5c-3.2-28.4-38.5-43.4-68.9-53.3c-7-2.3-14.3-2.7-22.1-3.1c-7.3-0.4-14.8-0.9-21.9-2.9
      c-20.6-6-43,0.7-58.1,7.3c-19,8.4-37.7,21.5-51.1,36.2L39.7,72.9z M155.3,302c-4.7,0-9.6-0.3-14.6-0.8c-22-2.3-43.8-9.7-54.8-15.6
      c-2.7-1.5-5.5-2.9-8.4-4.4c-24.7-12.8-52.6-27.3-53-59.1c-0.1-9-3.1-17.6-6.3-26.8c-3.2-9.1-6.5-18.5-6.8-28.6
      c-0.5-18.9,1.2-36.1,5.2-51.1c4.6-17.4,12.1-32,22.5-43.2c13.6-14.8,32.4-28.2,51.7-36.6c15.4-6.7,38.1-13.5,59.3-7.4
      c7,2,14.4,2.5,21.5,2.9c7.5,0.4,15.3,0.9,22.5,3.2c13.4,4.4,30.6,10.5,44.5,19.4c15.5,10,24.1,21.9,25.6,35.3
      c1.2,10.9,6,21.6,11.1,33c6.3,14.1,12.9,28.7,12.9,44.6c0,22.4-5.7,44.2-16.5,63c-9.3,16.4-25,27.4-40.1,38.1
      c-7.3,5.1-14.8,10.4-21.3,16.1c-8.9,7.9-20,13.2-33,16C170.4,301.3,163,302,155.3,302"/>
      <path fill="#79CADD" d="M136.6,34.5c-31,0-67.5,20.7-89.2,43.3C27.5,98.5,17.4,130.1,19,166.6c0.4,9.1,3.4,17.8,6.3,26.2
      c2.8,8.3,5.5,16.1,6,24.3C32.8,245,57.3,259,79,271.3c2.2,1.3,4.5,2.6,6.8,3.9c9.9,5.8,29,12.7,49.3,15.4
      c17.5,2.2,42.5,2.1,62.3-11.5c6.2-4.2,12.9-8.2,19.5-12.1c17.3-10.2,35.1-20.7,45.8-37.6c11.7-18.4,17.9-40.1,17.9-62.6
      c0-17.1-7.3-33.1-14.3-48.5c-4.7-10.2-9.1-19.9-11.3-29.6C252.3,77,244,66.7,230.4,57.9c-10.3-6.6-23.5-12.3-40.2-17.4
      c-6.8-2.1-14-2.4-21.6-2.7c-6.3-0.3-12.7-0.6-18.9-1.9C145.4,34.9,141.1,34.5,136.6,34.5L136.6,34.5z M151.4,293.4
      c-5.9,0-11.6-0.4-16.6-1.1c-20.6-2.6-39.9-9.7-50-15.6c-2.2-1.3-4.5-2.6-6.7-3.9C56,260.3,31,246.1,29.4,217.3
      c-0.5-8-3.1-15.7-5.9-23.8c-2.9-8.5-5.9-17.4-6.4-26.8c-0.8-17.7,1.1-34.2,5.6-49.1c4.9-16.2,12.8-30.1,23.4-41.1l0.7,0.6l-0.7-0.6
      C59.2,62.9,77,50.5,94.9,42.6c14.2-6.3,35.3-12.9,55-8.5c6.1,1.3,12.5,1.6,18.6,1.9c7.4,0.3,15,0.7,22.1,2.8
      c16.9,5.1,30.2,10.9,40.7,17.6c14.1,9,22.6,19.7,25.3,31.8c2.2,9.5,6.5,19.1,11.2,29.2c7.1,15.6,14.5,31.8,14.5,49.3
      c0,22.9-6.3,44.9-18.2,63.6c-11,17.2-29,27.9-46.5,38.2c-6.5,3.8-13.3,7.8-19.4,12C183.9,290.5,166.6,293.4,151.4,293.4z"/>
      <path fill="#65C2D8" d="M53.8,81.3l0.7,0.7c-21.2,21-31.8,51-29.7,84.6c0.5,8.6,3.3,16.8,5.9,24.7c2.3,6.8,4.6,13.9,5.4,21
      c2.9,25.3,25,39.1,44.5,51.3c1.4,0.9,2.8,1.7,4.2,2.6c8.2,5.2,24.8,12.4,44.7,15.6c15.2,2.4,37.2,3.3,56.2-6.5
      c4.8-2.4,9.8-4.8,14.7-7.2c20.6-9.8,42-20,54.9-38.4c12.7-18.1,19.5-40,19.5-63.1c0-19.7-8.7-38-17.1-55.7
      c-3.8-8-7.5-15.7-10.1-23.2c-9.1-25.3-41.9-37.7-60.7-42.9c-6.9-1.9-14.5-2.1-21.8-2.3c-5.1-0.1-10.3-0.3-15.4-1
      C118.3,37.4,78.1,58.8,54.5,82L53.8,81.3z M148.8,285.4c-7.2,0-13.9-0.7-19.6-1.6c-20.2-3.2-36.7-10.3-45.5-15.8
      c-1.4-0.9-2.8-1.7-4.1-2.6c-19.8-12.5-42.3-26.6-45.4-52.8c-0.8-6.9-3-13.5-5.4-20.6c-2.7-8.1-5.5-16.4-6-25.2
      c-1-16.6,1-32.5,6-47.1c5.2-15.1,13.4-28.2,24.3-39C77,57,117.9,35.4,149.9,39.7c5,0.7,10.1,0.8,15.1,0.9
      c7.4,0.2,15.1,0.4,22.3,2.4c19.1,5.3,52.6,18,62,44.1c2.7,7.4,6.3,15,10.1,23c8.5,17.9,17.3,36.3,17.3,56.5
      c0,23.6-6.9,45.8-19.8,64.3c-13.2,18.8-34.8,29.1-55.7,39c-4.9,2.3-9.9,4.7-14.6,7.1C174.4,283.4,160.9,285.4,148.8,285.4"/>
      <path fill="#52BBD3" d="M61.6,86.3c-22.5,21-33.5,49.6-31,80.3c0.7,8.3,3.3,16.3,5.8,24c1.8,5.5,3.6,11.2,4.7,16.8
      c4.5,23.5,24.8,37.6,42.7,50c13.3,9.2,38.4,18.6,63.4,18.6c9.1,0,18.3-1.2,26.9-4.2l1.4-0.5c27.3-9.4,55.6-19.1,72.4-41
      c13.8-17.8,21-39.8,21-63.6c0-24.8-12.5-48.4-23.5-69.2c-1.9-3.6-3.7-7-5.4-10.4c-8.7-17.1-29.9-31.2-56.6-37.7
      c-7.5-1.8-15.7-1.8-23.6-1.8c-3.3,0-6.8,0-10.2-0.1C123.3,46.5,87.1,62.5,61.6,86.3L61.6,86.3z M147.3,278
      c-25.6,0-51.1-9.5-64.7-18.9c-18.2-12.6-38.9-26.9-43.6-51.3c-1-5.5-2.8-10.9-4.6-16.6c-2.5-7.9-5.2-16-5.9-24.5
      c-1.3-15.8,0.9-31,6.4-45.2c5.5-13.9,13.9-26.3,25.2-36.8c12-11.2,27.3-21.3,43.1-28.5c16.8-7.6,32.9-11.3,46.5-10.8
      c3.3,0.1,6.8,0.1,10.1,0.1c8,0,16.3-0.1,24.1,1.8c27.3,6.7,48.9,21.2,57.9,38.8c1.7,3.4,3.5,6.8,5.4,10.4
      c11.1,21,23.7,44.8,23.7,70.1c0,24.3-7.4,46.7-21.5,64.9c-17.2,22.4-45.7,32.2-73.4,41.7l-1.4,0.5C166,276.7,156.6,278,147.3,278z"
      />
      <path fill="#3FB3CE" d="M68,89.7l0.7,0.8c-23.8,20.9-35.2,47.9-32.2,76c1,9.1,3.8,17.9,6.6,26.4c1,3.1,2.1,6.4,3,9.6
      c5.9,20.4,21.9,34.7,36.8,46c14.4,10.9,39.2,20.9,64.5,20.9c5.1,0,10.2-0.4,15.2-1.3c36.8-6.4,62.3-18.7,78-37.4
      c14.8-17.5,22.6-39.7,22.6-64.1c0-24.2-10.3-51.2-30.5-80.3c-11.1-15.9-30.7-28.1-52.5-32.6c-9.4-2-19.3-1.4-30.3-0.5
      c-25.6,2.1-58.2,17-81.2,37.2L68,89.7z M147.1,271.6c-9.4,0-19.2-1.3-29-4c-13.8-3.7-26.8-9.9-36.6-17.4
      c-15.2-11.5-31.5-26.1-37.6-47.1c-0.9-3.1-2-6.4-3-9.5c-2.8-8.6-5.7-17.5-6.7-26.9c-1.6-15,0.7-29.5,6.8-43.2
      c5.7-12.7,14.5-24.4,26.1-34.7c23.3-20.5,56.4-35.7,82.5-37.8c11.2-0.9,21.2-1.5,30.9,0.5c22.3,4.6,42.5,17.1,53.8,33.5
      c9.1,13.1,16.3,25.9,21.4,38c6.3,15.2,9.5,29.8,9.5,43.5c0,24.9-8,47.6-23.1,65.5c-16.1,19.1-42,31.6-79.3,38.1
      C157.8,271.2,152.5,271.6,147.1,271.6"/>
      <path fill="#3FB3CE" d="M165.4,57.4c-5.1,0-10.3,0.6-15.4,1.7c-26.6,5.9-54.4,19.3-74.3,35.7c-17.7,14.6-38,38.9-33.5,71.7
      c6.7,48.7,46.1,98.1,108.7,98.1c61.6,0,106.3-41.2,106.3-97.9c0-29.2-12-59.4-32.1-81C208.1,67.3,186.9,57.4,165.4,57.4L165.4,57.4
      z M151,266.9c-14.7,0-28.8-2.7-41.9-8.1c-12.4-5.1-23.7-12.4-33.6-21.8c-19.2-18.2-31.8-43.2-35.5-70.2C35.4,133,56.1,108,74.3,93
      c20.2-16.6,48.3-30.1,75.2-36.2c14.3-3.2,28.5-2.2,42.3,3.1c12.6,4.8,24.7,13.1,35,24.2c20.5,22,32.7,52.8,32.7,82.5
      c0,14.8-2.8,28.7-8.5,41.3c-5.4,12.1-13.2,22.7-23.2,31.6C208.2,257.2,180.9,266.9,151,266.9z"/>
      <path fill="#9FD9E7" d="M127.8,12.6l0-1.5c1.4,0,2.9,0,4.3,0l0,1.5C130.7,12.6,129.3,12.6,127.8,12.6L127.8,12.6z M136.9,12.9
      c-1.4-0.1-2.8-0.2-4.2-0.3l0.1-1.5c1.4,0,2.9,0.1,4.3,0.3L136.9,12.9z M122.9,12.9l-0.1-1.5c1.4-0.1,2.9-0.2,4.3-0.3l0.1,1.5
      C125.8,12.7,124.3,12.8,122.9,12.9L122.9,12.9z M118.1,13.4l-0.2-1.5c1.4-0.2,2.8-0.4,4.2-0.5l0.1,1.5
      C120.9,13.1,119.5,13.2,118.1,13.4L118.1,13.4z M141.8,13.5c-1.4-0.2-2.8-0.4-4.2-0.5l0.1-1.5c1.4,0.1,2.9,0.3,4.3,0.6L141.8,13.5z
      M113.2,14.3l-0.3-1.4c1.4-0.3,2.8-0.5,4.2-0.7l0.2,1.5C116,13.7,114.6,14,113.2,14.3L113.2,14.3z M146.6,14.5
      c-1.3-0.3-2.7-0.6-4.1-0.9l0.3-1.4c1.4,0.2,2.8,0.5,4.2,0.9L146.6,14.5z M108.4,15.3l-0.3-1.4c1.4-0.3,2.8-0.6,4.2-0.9l0.3,1.4
      C111.2,14.7,109.8,15,108.4,15.3L108.4,15.3z M151.3,15.8c-0.3-0.1-0.6-0.2-0.8-0.3c-1.1-0.3-2.1-0.6-3.2-0.9l0.4-1.4
      c1.1,0.3,2.2,0.6,3.3,0.9c0.3,0.1,0.5,0.2,0.8,0.3L151.3,15.8z M103.6,16.6l-0.4-1.4c1.4-0.4,2.8-0.8,4.1-1.1l0.4,1.4
      C106.4,15.8,105,16.2,103.6,16.6z M156.1,17.1c-1.4-0.4-2.8-0.7-4.1-1.1l0.4-1.4c1.3,0.4,2.7,0.8,4.1,1.1L156.1,17.1z M98.9,18
      l-0.5-1.4c1.4-0.5,2.7-0.9,4.1-1.3l0.4,1.4C101.6,17.1,100.3,17.6,98.9,18L98.9,18z M161,18.3c-1.5-0.3-2.8-0.6-4.2-1l0.4-1.4
      c1.3,0.3,2.7,0.6,4.1,1L161,18.3z M165.8,19.3c-1.5-0.3-2.9-0.6-4.2-0.9L162,17c1.3,0.3,2.7,0.6,4.1,0.9L165.8,19.3z M94.3,19.7
      l-0.5-1.4c1.3-0.5,2.7-1,4-1.4l0.5,1.4C97,18.7,95.6,19.2,94.3,19.7z M170.7,20.2c-1.4-0.3-2.8-0.5-4.2-0.8l0.3-1.4
      c1.4,0.3,2.8,0.5,4.2,0.8L170.7,20.2z M175.6,21.1l-0.4-0.1c-1.3-0.2-2.5-0.5-3.8-0.7l0.3-1.4c1.3,0.2,2.5,0.5,3.8,0.7l0.4,0.1
      L175.6,21.1z M89.7,21.5l-0.6-1.4c1.3-0.6,2.7-1.1,4-1.6l0.5,1.4C92.3,20.4,91,20.9,89.7,21.5z M180.5,22c-1.4-0.3-2.8-0.5-4.2-0.8
      l0.3-1.4c1.4,0.3,2.8,0.5,4.2,0.8L180.5,22z M185.3,22.9c-1.3-0.3-2.6-0.5-4.2-0.8l0.3-1.4c1.5,0.3,2.9,0.6,4.2,0.8L185.3,22.9z
      M85.2,23.5l-0.6-1.3c1.3-0.6,2.6-1.2,3.9-1.7l0.6,1.4C87.8,22.3,86.5,22.9,85.2,23.5z M190.1,23.9c-1.3-0.3-2.7-0.6-4.1-0.9
      l0.3-1.4c1.5,0.3,2.9,0.6,4.2,0.9L190.1,23.9z M194.9,25.1c-1.3-0.3-2.7-0.7-4.1-1l0.3-1.4c1.4,0.3,2.8,0.7,4.1,1L194.9,25.1z
      M80.7,25.6l-0.7-1.3c1.3-0.6,2.6-1.3,3.8-1.9l0.6,1.3C83.3,24.3,82,25,80.7,25.6L80.7,25.6z M199.6,26.5c-1.3-0.4-2.6-0.8-4-1.2
      l0.4-1.4c1.4,0.4,2.8,0.8,4.1,1.2L199.6,26.5z M76.4,27.9l-0.7-1.3c1.3-0.7,2.5-1.3,3.8-2l0.7,1.3C78.9,26.5,77.6,27.2,76.4,27.9z
      M204.3,28.1c-1.2-0.5-2.6-1-3.9-1.4l0.5-1.4c1.4,0.5,2.8,1,4,1.5L204.3,28.1z M208.7,30.1c-1.2-0.6-2.5-1.2-3.8-1.7l0.6-1.4
      c1.4,0.6,2.7,1.2,3.9,1.8L208.7,30.1z M72.1,30.3L71.3,29c1.2-0.7,2.5-1.4,3.7-2.1l0.7,1.3C74.5,28.9,73.3,29.6,72.1,30.3z
      M213.1,32.4c-1.1-0.5-2.1-1.1-3-1.6c-0.2-0.1-0.5-0.3-0.7-0.4l0.7-1.3c0.2,0.1,0.5,0.3,0.7,0.4c0.9,0.5,2,1,3,1.6L213.1,32.4z
      M67.8,32.8l-0.8-1.2c1.2-0.7,2.4-1.5,3.6-2.2l0.7,1.3C70.2,31.3,69,32.1,67.8,32.8z M217.5,34.7c-1.4-0.7-2.6-1.3-3.8-1.9l0.7-1.3
      c1.2,0.6,2.4,1.2,3.8,1.9L217.5,34.7z M63.7,35.5l-0.8-1.2c1.2-0.8,2.4-1.6,3.6-2.3l0.8,1.2C66.1,33.9,64.9,34.7,63.7,35.5z
      M222,36.8c-1.3-0.6-2.6-1.2-3.8-1.9l0.6-1.3c1.3,0.6,2.5,1.2,3.8,1.9L222,36.8z M59.6,38.3l-0.8-1.2c1.2-0.8,2.3-1.6,3.5-2.4
      l0.8,1.2C61.9,36.7,60.8,37.5,59.6,38.3z M226.5,39c-1.3-0.6-2.6-1.2-3.8-1.8l0.6-1.3c1.3,0.6,2.5,1.2,3.8,1.8L226.5,39z
      M230.9,41.1c-1.3-0.6-2.6-1.2-3.8-1.8l0.6-1.3c1.3,0.6,2.6,1.2,3.8,1.8L230.9,41.1z M55.7,41.2L54.8,40c1.1-0.9,2.3-1.7,3.4-2.5
      l0.9,1.2C57.9,39.5,56.8,40.3,55.7,41.2z M235.4,43.3c-1.3-0.6-2.5-1.2-3.8-1.8l0.6-1.3c1.3,0.6,2.5,1.2,3.8,1.9L235.4,43.3z
      M51.8,44.2l-0.9-1.1c1.1-0.9,2.2-1.8,3.4-2.6l0.9,1.2C54,42.5,52.9,43.3,51.8,44.2L51.8,44.2z M239.8,45.5
      c-1.3-0.6-2.5-1.3-3.8-1.9l0.6-1.3c1.3,0.6,2.5,1.3,3.8,1.9L239.8,45.5z M48,47.4l-1-1.1c1.1-0.9,2.2-1.8,3.3-2.7l0.9,1.1
      C50.1,45.5,49,46.4,48,47.4z M244.2,47.8c-1.2-0.6-2.5-1.3-3.8-2l0.7-1.3c1.3,0.7,2.6,1.3,3.8,2L244.2,47.8z M248.5,50.1
      c-1.2-0.7-2.4-1.3-3.7-2l0.7-1.3c1.3,0.7,2.6,1.4,3.7,2.1L248.5,50.1z M44.3,50.6l-1-1.1c1-0.9,2.1-1.9,3.2-2.8l1,1.1
      C46.4,48.7,45.3,49.7,44.3,50.6z M252.8,52.6c-1.2-0.7-2.4-1.4-3.6-2.1l0.7-1.3c1.3,0.7,2.5,1.5,3.7,2.2L252.8,52.6z M40.7,54
      l-1-1.1c1-1,2.1-2,3.1-2.9l1,1.1C42.7,52,41.7,53,40.7,54z M257,55.3c-1.1-0.8-2.3-1.5-3.6-2.3l0.8-1.3c1.2,0.8,2.5,1.5,3.6,2.3
      L257,55.3z M37.2,57.5l-1.1-1c1-1,2-2,3-3l1,1C39.2,55.5,38.2,56.5,37.2,57.5L37.2,57.5z M261,58.1c-1.1-0.8-2.2-1.6-3.4-2.4
      l0.8-1.2c1.2,0.8,2.4,1.7,3.5,2.5L261,58.1z M33.8,61.1l-1.1-1c0.9-1,1.9-2.1,2.9-3.1l1.1,1C35.7,59,34.8,60.1,33.8,61.1z
      M264.8,61.1c-1-0.9-2.1-1.8-3.3-2.6l0.9-1.2c1.2,0.9,2.3,1.8,3.3,2.7L264.8,61.1z M268.4,64.4c-1-1-2-1.9-3.1-2.9l1-1.1
      c1.1,1,2.2,1.9,3.2,2.9L268.4,64.4z M30.7,64.8l-1.2-0.9c0.6-0.8,1.2-1.5,1.9-2.3c0.3-0.3,0.6-0.7,0.9-1l1.1,1
      c-0.3,0.3-0.6,0.7-0.9,1C31.9,63.3,31.3,64.1,30.7,64.8z M271.7,68c-0.9-1-1.8-2.1-2.8-3.1l1-1c1,1,2,2.1,2.9,3.2L271.7,68z
      M27.8,68.8L26.6,68c0.8-1.2,1.6-2.4,2.5-3.5l1.2,0.9C29.4,66.5,28.6,67.6,27.8,68.8L27.8,68.8z M274.6,72
      c-0.7-1.1-1.6-2.3-2.4-3.4l1.1-0.9c0.9,1.1,1.8,2.3,2.5,3.5L274.6,72z M25.2,72.9l-1.3-0.7c0.7-1.2,1.5-2.5,2.3-3.6l1.2,0.8
      C26.6,70.5,25.9,71.7,25.2,72.9z M277,76.2c-0.6-1.2-1.3-2.5-2-3.6l1.2-0.8c0.8,1.2,1.5,2.5,2.1,3.8L277,76.2z M22.9,77.3l-1.3-0.7
      c0.6-1.3,1.3-2.6,2-3.8l1.3,0.7C24.2,74.8,23.5,76,22.9,77.3L22.9,77.3z M278.7,80.7c-0.4-1.3-0.9-2.6-1.5-3.9l1.3-0.6
      c0.6,1.3,1.1,2.7,1.5,4L278.7,80.7z M20.8,81.7l-1.3-0.6c0.6-1.3,1.2-2.6,1.8-3.9l1.3,0.6C21.9,79.1,21.3,80.4,20.8,81.7z
      M279.8,85.4c-0.2-1.4-0.5-2.7-0.9-4l1.4-0.4c0.4,1.4,0.7,2.8,0.9,4.2L279.8,85.4z M18.9,86.3l-1.4-0.5c0.5-1.3,1-2.7,1.6-4
      l1.4,0.6C20,83.6,19.4,85,18.9,86.3L18.9,86.3z M280.1,90.2c0-1.4-0.1-2.8-0.3-4.1l1.5-0.2c0.2,1.4,0.3,2.9,0.3,4.3L280.1,90.2z
      M17.3,90.9l-1.4-0.5c0.4-1.4,0.9-2.7,1.4-4l1.4,0.5C18.2,88.2,17.8,89.6,17.3,90.9z M280.2,95.2c-0.1-1-0.1-2-0.1-2.9
      c0-0.4,0-0.7,0-1.1l0-0.3l1.5,0l0,0.3c0,0.3,0,0.7,0,1c0,0.9,0,1.9,0.1,2.8L280.2,95.2z M15.9,95.6l-1.4-0.4
      c0.4-1.4,0.8-2.8,1.2-4.1l1.4,0.4C16.7,92.9,16.3,94.3,15.9,95.6L15.9,95.6z M280.7,100.2c-0.2-1.4-0.4-2.9-0.5-4.3l1.5-0.1
      c0.1,1.3,0.3,2.7,0.5,4.2L280.7,100.2z M14.6,100.4l-1.4-0.3c0.3-1.4,0.7-2.8,1.1-4.1l1.4,0.4C15.3,97.6,15,99,14.6,100.4z
      M281.7,105.1c-0.3-1.4-0.6-2.8-0.8-4.2l1.5-0.2c0.2,1.3,0.5,2.7,0.8,4.1L281.7,105.1z M13.5,105.2l-1.4-0.3
      c0.3-1.4,0.6-2.8,0.9-4.2l1.4,0.3C14.2,102.4,13.8,103.8,13.5,105.2z M283,109.9c-0.4-1.4-0.8-2.8-1.1-4.1l1.4-0.3
      c0.3,1.3,0.7,2.7,1.1,4.1L283,109.9z M12.6,110.1l-1.4-0.3c0.3-1.4,0.5-2.8,0.8-4.2l1.4,0.3C13.1,107.2,12.8,108.6,12.6,110.1z
      M284.5,114.7c-0.5-1.4-0.9-2.8-1.3-4.1l1.4-0.4c0.4,1.3,0.8,2.6,1.3,4L284.5,114.7z M11.7,114.9l-1.4-0.2c0.2-1.4,0.5-2.8,0.7-4.2
      l1.4,0.3C12.2,112.1,11.9,113.5,11.7,114.9L11.7,114.9z M286.2,119.4c-0.5-1.4-1-2.7-1.5-4l1.4-0.5c0.4,1.3,0.9,2.6,1.4,4
      L286.2,119.4z M10.9,119.8l-1.5-0.2c0.2-1.4,0.4-2.9,0.7-4.2l1.5,0.2C11.4,117,11.2,118.4,10.9,119.8z M288,124
      c-0.6-1.5-1.1-2.8-1.5-4l1.4-0.5c0.5,1.2,0.9,2.4,1.5,3.9L288,124z M10.3,124.7l-1.5-0.2c0.2-1.5,0.4-2.9,0.6-4.2l1.5,0.2
      C10.6,121.8,10.4,123.3,10.3,124.7L10.3,124.7z M289.8,128.6l-0.1-0.4c-0.5-1.2-1-2.4-1.4-3.6l1.4-0.5c0.5,1.2,1,2.4,1.4,3.6
      l0.1,0.4L289.8,128.6z M9.6,129.6l-1.5-0.2c0.2-1.4,0.4-2.8,0.5-4.2l1.5,0.2C10,126.8,9.8,128.2,9.6,129.6z M291.7,133.2
      c-0.5-1.3-1-2.6-1.6-3.9l1.4-0.5c0.5,1.3,1.1,2.6,1.6,3.9L291.7,133.2z M9,134.5l-1.5-0.2c0.2-1.4,0.3-2.8,0.5-4.2l1.5,0.2
      C9.4,131.7,9.2,133.1,9,134.5z M293.4,137.8c-0.4-1.2-0.9-2.5-1.5-4l1.4-0.5c0.6,1.5,1.1,2.8,1.5,4L293.4,137.8z M8.5,139.5
      L7,139.3c0.2-1.4,0.3-2.8,0.5-4.2l1.5,0.2C8.8,136.6,8.6,138,8.5,139.5z M295.1,142.4c-0.4-1.3-0.9-2.6-1.4-4l1.4-0.5
      c0.5,1.4,1,2.7,1.4,4L295.1,142.4z M7.9,144.4l-1.5-0.2l0.5-4.2l1.5,0.2L7.9,144.4z M296.6,147.1c-0.4-1.3-0.8-2.6-1.2-4l1.4-0.5
      c0.5,1.4,0.9,2.8,1.3,4.1L296.6,147.1z M7.4,149.3l-1.5-0.2c0.2-1.4,0.3-2.8,0.5-4.2l1.5,0.2C7.7,146.5,7.5,147.9,7.4,149.3
      L7.4,149.3z M297.8,151.9c-0.3-1.3-0.6-2.7-1-4.1l1.4-0.4c0.4,1.4,0.7,2.8,1,4.2L297.8,151.9z M6.8,154.2L5.4,154
      c0.2-1.4,0.3-2.8,0.5-4.2l1.5,0.2C7.1,151.4,7,152.8,6.8,154.2L6.8,154.2z M298.6,156.7c-0.2-1.3-0.4-2.7-0.7-4.1l1.4-0.3
      c0.3,1.4,0.6,2.9,0.8,4.2L298.6,156.7z M6.2,159.1L4.8,159c0.1-0.6,0.1-1.2,0.2-1.8c0.1-0.8,0.2-1.6,0.3-2.4l1.5,0.2
      c-0.1,0.8-0.2,1.6-0.3,2.4C6.4,157.9,6.3,158.5,6.2,159.1z M299.1,161.6c-0.1-1.4-0.2-2.8-0.4-4.2l1.5-0.2c0.2,1.4,0.3,2.9,0.4,4.3
      L299.1,161.6z M4.6,164c0-0.2,0-0.4,0-0.5c0-1.3,0-2.5,0.1-3.8l1.5,0.1c-0.1,1.2-0.1,2.4-0.1,3.7c0,0.2,0,0.3,0,0.5L4.6,164z
      M300.6,166.5l-1.5-0.1c0.1-0.8,0.1-1.7,0.1-2.6c0-0.5,0-1.1,0-1.6l1.5-0.1c0,0.5,0,1.1,0,1.6C300.6,164.8,300.6,165.7,300.6,166.5
      z M4.9,169c-0.1-1.5-0.2-2.9-0.3-4.3l1.5,0c0,1.4,0.1,2.8,0.2,4.2L4.9,169z M300,171.5l-1.5-0.2l0.2-1.7c0.1-0.8,0.2-1.7,0.3-2.5
      l1.5,0.1c-0.1,0.9-0.2,1.8-0.3,2.6L300,171.5z M5.5,174c-0.2-1.4-0.4-2.9-0.6-4.2l1.5-0.2c0.1,1.3,0.3,2.7,0.6,4.2L5.5,174z
      M299.4,176.4l-1.5-0.2l0.1-0.6c0.2-1.2,0.3-2.4,0.5-3.6l1.5,0.2c-0.2,1.2-0.3,2.4-0.5,3.6L299.4,176.4z M6.5,178.9
      c-0.3-1.4-0.6-2.9-0.8-4.2l1.4-0.3c0.2,1.3,0.5,2.7,0.8,4.1L6.5,178.9z M298.7,181.3l-1.5-0.2c0.2-1.4,0.4-2.8,0.6-4.2l1.5,0.2
      C299.1,178.5,298.9,179.9,298.7,181.3L298.7,181.3z M7.7,183.7c-0.4-1.5-0.7-2.8-1-4.1l1.4-0.3c0.3,1.3,0.6,2.6,1,4.1L7.7,183.7z
      M298,186.2l-1.5-0.2c0.2-1.4,0.4-2.8,0.6-4.2l1.5,0.2C298.4,183.4,298.2,184.8,298,186.2L298,186.2z M9,188.5
      c-0.4-1.5-0.8-2.8-1.2-4.1l1.4-0.4c0.3,1.3,0.7,2.6,1.2,4.1L9,188.5z M297.3,191.1l-1.5-0.2c0.2-1.4,0.4-2.8,0.6-4.2l1.5,0.2
      C297.7,188.3,297.5,189.7,297.3,191.1L297.3,191.1z M10.5,193.2c-0.4-1.4-0.8-2.7-1.2-4.1l1.4-0.4c0.4,1.4,0.8,2.7,1.2,4.1
      L10.5,193.2z M296.5,196l-1.5-0.2c0.2-1.3,0.5-2.7,0.7-4.2l1.5,0.2C297,193.3,296.8,194.7,296.5,196L296.5,196z M11.9,198
      c-0.3-0.8-0.5-1.7-0.8-2.5l-0.5-1.5l1.4-0.4l0.5,1.5c0.3,0.8,0.5,1.7,0.8,2.5L11.9,198z M295.7,200.9l-1.4-0.3
      c0.3-1.3,0.5-2.7,0.7-4.2l1.5,0.2C296.2,198.2,295.9,199.6,295.7,200.9L295.7,200.9z M13.4,202.7c-0.4-1.2-0.7-2.5-1.2-4.1l1.4-0.4
      c0.5,1.6,0.9,2.9,1.2,4.1L13.4,202.7z M294.7,205.8l-1.4-0.3c0.3-1.3,0.6-2.7,0.8-4.1l1.4,0.3C295.3,203.1,295,204.5,294.7,205.8
      L294.7,205.8z M14.7,207.4c-0.3-1.3-0.7-2.6-1.1-4.1L15,203c0.4,1.5,0.8,2.8,1.1,4.1L14.7,207.4z M293.6,210.7l-1.4-0.3
      c0.3-1.3,0.6-2.7,0.9-4.1l1.4,0.3C294.2,207.9,293.9,209.3,293.6,210.7z M15.9,212.2c-0.3-1.3-0.6-2.7-1-4.1l1.4-0.4
      c0.4,1.5,0.7,2.8,1,4.2L15.9,212.2z M292.3,215.5l-1.4-0.4c0.4-1.3,0.7-2.7,1.1-4.1l1.4,0.4C293.1,212.8,292.7,214.1,292.3,215.5
      L292.3,215.5z M16.8,217c-0.2-1.3-0.5-2.7-0.8-4.1l1.4-0.3c0.3,1.4,0.6,2.9,0.8,4.2L16.8,217z M290.9,220.3l-1.4-0.5
      c0.4-1.3,0.8-2.7,1.2-4l1.4,0.4C291.7,217.6,291.3,218.9,290.9,220.3L290.9,220.3z M17.4,221.9c-0.1-1.3-0.3-2.7-0.5-4.2l1.5-0.2
      c0.2,1.5,0.4,2.9,0.5,4.3L17.4,221.9z M289.2,225l-1.4-0.5c0.5-1.3,1-2.6,1.4-4l1.4,0.5C290.2,222.3,289.7,223.7,289.2,225z
      M19.1,226.8l-1.5,0l0-0.4c0-1.2,0-2.5-0.1-3.8l1.5-0.1c0.1,1.3,0.1,2.6,0.1,3.9L19.1,226.8z M287.4,229.6L286,229
      c0.5-1.2,1.1-2.6,1.6-3.9l1.4,0.5C288.5,227,287.9,228.3,287.4,229.6L287.4,229.6z M17.8,231.8c-0.1-1.4-0.2-2.9-0.2-4.3l1.5,0
      c0,1.4,0.1,2.8,0.2,4.2L17.8,231.8z M285.2,234.1l-1.3-0.7c0.6-1.2,1.2-2.5,1.8-3.8l1.3,0.6C286.5,231.6,285.8,232.9,285.2,234.1
      L285.2,234.1z M18.5,236.7c-0.3-1.4-0.5-2.8-0.6-4.3l1.5-0.1c0.1,1.4,0.3,2.8,0.6,4.1L18.5,236.7z M283,238l-1.3-0.8
      c0.7-1.2,1.4-2.4,2.1-3.7l1.3,0.7C284.5,235.5,283.8,236.7,283,238L283,238z M19.6,241.6c-0.4-1.4-0.7-2.8-1-4.2l1.4-0.3
      c0.3,1.4,0.6,2.7,1,4.1L19.6,241.6z M280.3,242.2l-1.2-0.8c0.8-1.2,1.6-2.3,2.3-3.5l1.2,0.8C281.9,239.8,281.1,241,280.3,242.2
      L280.3,242.2z M277.3,246.1l-1.1-0.9c0.9-1.1,1.8-2.2,2.5-3.3l1.2,0.9C279.1,243.9,278.2,245,277.3,246.1L277.3,246.1z M21.3,246.4
      c-0.5-1.3-1-2.7-1.4-4.1l1.4-0.4c0.4,1.3,0.9,2.6,1.4,3.9L21.3,246.4z M274.1,249.9l-1.1-1c1-1.1,1.9-2.1,2.7-3.2l1.1,0.9
      C276,247.8,275,248.9,274.1,249.9z M23.3,250.9c-0.6-1.3-1.2-2.6-1.8-3.9l1.4-0.6c0.5,1.3,1.1,2.6,1.7,3.8L23.3,250.9z
      M270.7,253.6l-1.1-1c1-1,2-2.1,2.9-3.1l1.1,1C272.7,251.5,271.7,252.5,270.7,253.6L270.7,253.6z M25.8,255.3
      c-0.8-1.2-1.5-2.5-2.1-3.7l1.3-0.7c0.6,1.2,1.3,2.4,2.1,3.6L25.8,255.3z M267.1,257.1l-1-1.1c1-1,2.1-2,3-3l1,1
      C269.2,255.1,268.2,256.1,267.1,257.1L267.1,257.1z M28.6,259.5c-0.9-1.2-1.7-2.4-2.4-3.5l1.2-0.8c0.7,1.2,1.5,2.3,2.4,3.5
      L28.6,259.5z M263.5,260.5l-1-1.1c1.1-1,2.1-1.9,3.1-2.9l1,1.1C265.6,258.5,264.6,259.5,263.5,260.5L263.5,260.5z M31.7,263.4
      c-0.9-1.1-1.8-2.2-2.7-3.4l1.2-0.9c0.8,1.1,1.7,2.2,2.6,3.3L31.7,263.4z M259.8,263.8l-1-1.1c1.1-1,2.2-1.9,3.2-2.8l1,1.1
      C262,261.8,260.9,262.8,259.8,263.8z M256,267l-1-1.1c1.1-0.9,2.1-1.8,3.2-2.8l1,1.1C258.2,265.2,257.1,266.1,256,267z M35,267.1
      c-1-1-2-2.1-2.9-3.2l1.1-1c0.9,1,1.9,2.1,2.8,3.1L35,267.1z M252.3,270.3l-1-1.1l0.9-0.8c0.8-0.7,1.5-1.3,2.3-2l1,1.1
      c-0.8,0.7-1.5,1.3-2.3,2L252.3,270.3z M38.6,270.6c-1.1-1-2.1-2-3.1-3l1-1c1,1,2,2,3,2.9L38.6,270.6z M248.5,273.5l-1-1.1
      c1.1-0.9,2.1-1.9,3.2-2.8l1,1.1C250.7,271.7,249.6,272.6,248.5,273.5L248.5,273.5z M42.4,273.9c-1.1-0.9-2.2-1.9-3.2-2.8l1-1.1
      c1,0.9,2.1,1.9,3.2,2.8L42.4,273.9z M244.8,276.8l-1-1.1c1.1-0.9,2.1-1.9,3.2-2.8l1,1.1C246.9,274.9,245.9,275.8,244.8,276.8
      L244.8,276.8z M46.3,276.9c-1.2-0.9-2.3-1.8-3.4-2.6l0.9-1.1c1.1,0.9,2.2,1.7,3.3,2.6L46.3,276.9z M50.3,279.9
      c-1.2-0.8-2.4-1.6-3.5-2.5l0.9-1.2c1.1,0.8,2.3,1.6,3.4,2.4L50.3,279.9z M241.2,280.1l-1-1.1c1-0.9,2-1.9,3.2-2.9l1,1.1
      C243.2,278.2,242.1,279.2,241.2,280.1L241.2,280.1z M54.5,282.6c-1.2-0.8-2.4-1.5-3.6-2.3l0.8-1.2c1.1,0.8,2.3,1.5,3.5,2.3
      L54.5,282.6z M237.6,283.5l-1-1c1-1,2-2,3.1-3l1,1.1C239.6,281.6,238.6,282.6,237.6,283.5z M58.8,285.2c-1.2-0.7-2.5-1.5-3.7-2.2
      l0.8-1.2c1.2,0.7,2.4,1.5,3.6,2.2L58.8,285.2z M234.2,287l-1.1-1c0.9-1,1.9-2,3-3.1l1,1C236.1,285,235.1,286,234.2,287L234.2,287z
      M63.1,287.6c-1.3-0.7-2.5-1.4-3.7-2.1l0.7-1.3c1.2,0.7,2.4,1.4,3.7,2.1L63.1,287.6z M67.5,289.9c-1.3-0.6-2.6-1.3-3.8-2l0.7-1.3
      c1.2,0.6,2.5,1.3,3.8,1.9L67.5,289.9z M230.9,290.7l-1.1-0.9c0.9-1.1,1.8-2.1,2.8-3.2l1.1,1C232.7,288.6,231.8,289.7,230.9,290.7z
      M72,292c-1.3-0.6-2.6-1.2-3.8-1.8l0.7-1.3c1.2,0.6,2.5,1.2,3.8,1.8L72,292z M76.6,294.1c-1.3-0.6-2.6-1.1-3.9-1.7l0.6-1.3
      c1.3,0.6,2.6,1.2,3.9,1.7L76.6,294.1z M227.6,294.5l-1.1-1c0.8-0.9,1.6-1.8,2.4-2.8l0.3-0.4l1.1,0.9l-0.3,0.4
      C229.3,292.6,228.5,293.6,227.6,294.5L227.6,294.5z M81.1,296c-1.3-0.5-2.6-1.1-3.9-1.6l0.6-1.3c1.3,0.5,2.6,1.1,3.9,1.6L81.1,296z
      M85.8,297.8c-1.3-0.5-2.7-1-4-1.5l0.5-1.4c1.3,0.5,2.6,1,3.9,1.5L85.8,297.8z M224.1,298l-1-1.1c1-0.9,2-1.9,3-2.9l1.1,1
      C226.2,296.1,225.1,297.1,224.1,298L224.1,298z M90.4,299.5c-1.3-0.5-2.7-1-4-1.5l0.5-1.4c1.3,0.5,2.6,1,4,1.4L90.4,299.5z
      M95.1,301.1c-1.4-0.4-2.7-0.9-4-1.4l0.5-1.4c1.3,0.5,2.7,0.9,4,1.4L95.1,301.1z M220.3,301.3l-0.9-1.2c1.1-0.9,2.2-1.8,3.2-2.7
      l1,1.1C222.5,299.5,221.4,300.4,220.3,301.3L220.3,301.3z M99.8,302.7l-1.5-0.5c-0.9-0.3-1.8-0.6-2.5-0.8l0.5-1.4
      c0.8,0.3,1.6,0.5,2.5,0.8l1.5,0.5L99.8,302.7z M216.2,304.2l-0.8-1.2c1.2-0.8,2.3-1.6,3.4-2.4l0.9,1.2
      C218.6,302.6,217.4,303.4,216.2,304.2L216.2,304.2z M104.6,304.3c-1.4-0.5-2.8-0.9-4-1.3l0.5-1.4c1.2,0.4,2.6,0.9,4,1.3
      L104.6,304.3z M109.3,305.8c-1.4-0.4-2.8-0.9-4.1-1.3l0.5-1.4c1.3,0.4,2.6,0.8,4,1.3L109.3,305.8z M211.9,306.8l-0.7-1.3
      c1.2-0.7,2.4-1.4,3.6-2.1l0.8,1.2C214.4,305.3,213.2,306.1,211.9,306.8L211.9,306.8z M114,307.2c-1.3-0.4-2.7-0.8-4.1-1.2l0.4-1.4
      c1.4,0.4,2.7,0.8,4.1,1.2L114,307.2z M118.8,308.6c-1.3-0.4-2.7-0.8-4.1-1.2l0.4-1.4c1.4,0.4,2.7,0.8,4.1,1.2L118.8,308.6z
      M207.4,309l-0.6-1.3c1.3-0.6,2.5-1.2,3.8-1.9l0.7,1.3C210,307.8,208.8,308.4,207.4,309z M123.6,309.9c-1.4-0.4-2.7-0.7-4.1-1.1
      l0.4-1.4c1.4,0.4,2.7,0.7,4.1,1.1L123.6,309.9z M202.8,310.9l-0.5-1.4c1.3-0.5,2.6-1,3.9-1.6l0.6,1.3
      C205.5,309.9,204.2,310.4,202.8,310.9L202.8,310.9z M128.4,311.1c-1.4-0.3-2.8-0.7-4.1-1l0.4-1.4c1.4,0.3,2.7,0.7,4.1,1
      L128.4,311.1z M133.3,312.2c-1.4-0.3-2.8-0.6-4.2-0.9l0.3-1.4c1.4,0.3,2.8,0.6,4.1,0.9L133.3,312.2z M198.1,312.5l-0.4-1.4
      c1.3-0.4,2.7-0.9,4-1.3l0.5,1.4C200.8,311.7,199.5,312.1,198.1,312.5L198.1,312.5z M138.1,313.2c-1.4-0.3-2.8-0.5-4.2-0.8l0.3-1.4
      c1.4,0.3,2.8,0.6,4.1,0.8L138.1,313.2z M193.3,313.8l-0.3-1.4c1.4-0.3,2.7-0.7,4.1-1.1l0.4,1.4
      C196.1,313.1,194.7,313.5,193.3,313.8L193.3,313.8z M143,314c-1.4-0.2-2.8-0.5-4.2-0.7l0.3-1.4c1.4,0.3,2.8,0.5,4.2,0.7L143,314z
      M148,314.8c-1.4-0.2-2.8-0.4-4.2-0.6l0.2-1.5c1.4,0.2,2.8,0.4,4.2,0.6L148,314.8z M188.4,314.8l-0.3-1.4c1.4-0.2,2.8-0.5,4.1-0.8
      l0.3,1.4C191.2,314.3,189.8,314.6,188.4,314.8L188.4,314.8z M152.9,315.4c-1.4-0.2-2.8-0.3-4.2-0.5l0.2-1.5
      c1.4,0.2,2.8,0.4,4.2,0.5L152.9,315.4z M183.4,315.6l-0.2-1.5c1.4-0.2,2.8-0.4,4.2-0.6l0.2,1.4
      C186.3,315.2,184.9,315.4,183.4,315.6z M157.9,315.9c-1.4-0.1-2.8-0.2-4.3-0.4l0.2-1.5c1.4,0.2,2.8,0.3,4.2,0.4L157.9,315.9z
      M178.5,316.1l-0.1-1.5c1.4-0.1,2.8-0.2,4.2-0.4l0.2,1.5C181.3,315.8,179.9,316,178.5,316.1L178.5,316.1z M162.8,316.2
      c-1.4-0.1-2.8-0.2-4.3-0.3l0.1-1.5c1.4,0.1,2.8,0.2,4.2,0.3L162.8,316.2z M173.5,316.3l0-1.5c1.4,0,2.8-0.1,4.2-0.2l0.1,1.5
      C176.4,316.2,174.9,316.3,173.5,316.3L173.5,316.3z M167.8,316.4c-1.4,0-2.8-0.1-4.3-0.1l0.1-1.5c1.4,0.1,2.8,0.1,4.2,0.1
      L167.8,316.4z M169.5,316.4c-0.3,0-0.6,0-0.9,0l0-1.5c1.4,0,2.8,0,4.2,0l0,1.5C171.7,316.4,170.6,316.4,169.5,316.4"/>
  </g>
</svg>
);

export default HeroImage;
