import React from 'react';

const Icon10X = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.1613 32H48.7742C47.9463 30.013 46.5488 28.3155 44.7577 27.1215C42.9667 25.9275 40.8623 25.2903 38.7097 25.2903C36.5571 25.2903 34.4527 25.9275 32.6617 27.1215C30.8706 28.3155 29.4731 30.013 28.6452 32H6.83869C6.61625 32 6.40292 32.0884 6.24563 32.2456C6.08834 32.4029 5.99998 32.6163 5.99998 32.8387V39.5484C5.99998 39.7708 6.08834 39.9841 6.24563 40.1414C6.40292 40.2987 6.61625 40.3871 6.83869 40.3871H28.6452C29.245 41.8353 30.151 43.1366 31.301 44.2018C32.451 45.267 33.8178 46.0708 35.3076 46.5581C36.7974 47.0455 38.375 47.2048 39.9322 47.0252C41.4894 46.8455 42.9893 46.3312 44.329 45.5175L56.5658 57.7543L57.7518 56.5683L45.7163 44.5328C46.3481 43.998 46.9173 43.3934 47.413 42.7304L56.5683 51.8833L57.7543 50.6974L48.3431 41.2862C48.4983 40.9935 48.6434 40.6949 48.7717 40.3871H57.1613C57.3838 40.3871 57.5971 40.2987 57.7544 40.1414C57.9117 39.9841 58 39.7708 58 39.5484V32.8387C58 32.6163 57.9117 32.4029 57.7544 32.2456C57.5971 32.0884 57.3838 32 57.1613 32ZM7.6774 33.6774H22.7742V38.7097H7.6774V33.6774ZM24.4516 38.7097V33.6774H28.1109C27.705 35.3303 27.705 37.0568 28.1109 38.7097H24.4516ZM38.7097 45.4194C36.885 45.4194 35.1013 44.8783 33.5841 43.8645C32.0669 42.8508 30.8844 41.4099 30.1861 39.7241C29.4879 38.0383 29.3052 36.1833 29.6611 34.3937C30.0171 32.604 30.8958 30.9602 32.186 29.6699C33.4763 28.3797 35.1202 27.501 36.9098 27.145C38.6995 26.789 40.5545 26.9717 42.2403 27.67C43.9261 28.3683 45.3669 29.5508 46.3807 31.068C47.3944 32.5851 47.9355 34.3688 47.9355 36.1935C47.9326 38.6395 46.9597 40.9844 45.2301 42.714C43.5006 44.4435 41.1556 45.4165 38.7097 45.4194ZM56.3226 38.7097H49.3085C49.7144 37.0568 49.7144 35.3303 49.3085 33.6774H56.3226V38.7097Z"
      fill="black"
    />
    <path d="M11.0322 35.3548H9.35482V37.0322H11.0322V35.3548Z" fill="black" />
    <path d="M14.3871 35.3548H12.7097V37.0322H14.3871V35.3548Z" fill="black" />
    <path d="M17.7419 35.3548H16.0645V37.0322H17.7419V35.3548Z" fill="black" />
    <path d="M21.0968 35.3548H19.4193V37.0322H21.0968V35.3548Z" fill="black" />
    <path
      d="M38.7097 28.6451C37.2168 28.6451 35.7573 29.0878 34.516 29.9173C33.2747 30.7467 32.3072 31.9256 31.7359 33.3049C31.1646 34.6842 31.0151 36.2019 31.3063 37.6662C31.5976 39.1304 32.3165 40.4754 33.3722 41.5311C34.4278 42.5867 35.7728 43.3056 37.2371 43.5969C38.7013 43.8881 40.219 43.7387 41.5983 43.1673C42.9776 42.596 44.1565 41.6285 44.9859 40.3872C45.8154 39.1459 46.2581 37.6865 46.2581 36.1935C46.2559 34.1923 45.4599 32.2736 44.0448 30.8585C42.6296 29.4433 40.711 28.6474 38.7097 28.6451V28.6451ZM38.7097 42.0645C37.5485 42.0645 36.4134 41.7202 35.4479 41.0751C34.4825 40.43 33.73 39.513 33.2856 38.4403C32.8413 37.3675 32.725 36.187 32.9515 35.0482C33.1781 33.9093 33.7372 32.8632 34.5583 32.0421C35.3794 31.2211 36.4255 30.6619 37.5643 30.4354C38.7032 30.2088 39.8836 30.3251 40.9564 30.7695C42.0292 31.2138 42.9461 31.9663 43.5912 32.9318C44.2363 33.8973 44.5807 35.0324 44.5807 36.1935C44.5787 37.75 43.9595 39.2421 42.8589 40.3427C41.7583 41.4433 40.2662 42.0625 38.7097 42.0645Z"
      fill="black"
    />
    <path
      d="M9.35482 24.4516H14.8786L17.9877 27.5607C18.1449 27.7179 18.3582 27.8062 18.5806 27.8062C18.803 27.8062 19.0163 27.7179 19.1736 27.5607L22.2827 24.4516H29.4839C29.7063 24.4516 29.9196 24.3632 30.0769 24.2059C30.2342 24.0486 30.3226 23.8353 30.3226 23.6129V6.83867C30.3226 6.61623 30.2342 6.4029 30.0769 6.24561C29.9196 6.08833 29.7063 5.99996 29.4839 5.99996H9.35482C9.13238 5.99996 8.91905 6.08833 8.76176 6.24561C8.60447 6.4029 8.51611 6.61623 8.51611 6.83867V23.6129C8.51611 23.8353 8.60447 24.0486 8.76176 24.2059C8.91905 24.3632 9.13238 24.4516 9.35482 24.4516ZM10.1935 7.67738H28.6452V22.7742H21.9355C21.7131 22.7742 21.4998 22.8626 21.3425 23.0199L18.5806 25.7818L15.8188 23.0199C15.6615 22.8626 15.4482 22.7742 15.2258 22.7742H10.1935V7.67738Z"
      fill="black"
    />
    <path
      d="M57.1613 9.3548H37.0323C36.8098 9.3548 36.5965 9.44316 36.4392 9.60045C36.2819 9.75774 36.1936 9.97107 36.1936 10.1935V20.258C36.1936 20.4805 36.2819 20.6938 36.4392 20.8511C36.5965 21.0084 36.8098 21.0967 37.0323 21.0967H45.0721L47.3425 23.3671C47.4998 23.5244 47.7131 23.6127 47.9355 23.6127C48.1579 23.6127 48.3712 23.5244 48.5285 23.3671L50.7989 21.0967H57.1613C57.3837 21.0967 57.5971 21.0084 57.7544 20.8511C57.9117 20.6938 58 20.4805 58 20.258V10.1935C58 9.97107 57.9117 9.75774 57.7544 9.60045C57.5971 9.44316 57.3837 9.3548 57.1613 9.3548ZM56.3226 19.4193H50.4516C50.2292 19.4194 50.0159 19.5078 49.8587 19.6651L47.9355 21.5882L46.0123 19.6651C45.8551 19.5078 45.6418 19.4194 45.4194 19.4193H37.871V11.0322H56.3226V19.4193Z"
      fill="black"
    />
    <path
      d="M12.7097 14.3871H26.129C26.3515 14.3871 26.5648 14.2987 26.7221 14.1414C26.8794 13.9841 26.9677 13.7708 26.9677 13.5483V10.1935C26.9677 9.97107 26.8794 9.75774 26.7221 9.60045C26.5648 9.44316 26.3515 9.3548 26.129 9.3548H12.7097C12.4872 9.3548 12.2739 9.44316 12.1166 9.60045C11.9593 9.75774 11.871 9.97107 11.871 10.1935V13.5483C11.871 13.7708 11.9593 13.9841 12.1166 14.1414C12.2739 14.2987 12.4872 14.3871 12.7097 14.3871ZM13.5484 11.0322H25.2903V12.7096H13.5484V11.0322Z"
      fill="black"
    />
    <path
      d="M40.3871 17.7419H53.8065C54.0289 17.7419 54.2422 17.6535 54.3995 17.4963C54.5568 17.339 54.6452 17.1256 54.6452 16.9032V13.5484C54.6452 13.3259 54.5568 13.1126 54.3995 12.9553C54.2422 12.798 54.0289 12.7096 53.8065 12.7096H40.3871C40.1647 12.7096 39.9513 12.798 39.794 12.9553C39.6368 13.1126 39.5484 13.3259 39.5484 13.5484V16.9032C39.5484 17.1256 39.6368 17.339 39.794 17.4963C39.9513 17.6535 40.1647 17.7419 40.3871 17.7419ZM41.2258 14.3871H52.9678V16.0645H41.2258V14.3871Z"
      fill="black"
    />
    <path
      d="M26.9677 20.258V16.9032C26.9677 16.6808 26.8794 16.4674 26.7221 16.3101C26.5648 16.1528 26.3515 16.0645 26.129 16.0645H12.7097C12.4872 16.0645 12.2739 16.1528 12.1166 16.3101C11.9593 16.4674 11.871 16.6808 11.871 16.9032V20.258C11.871 20.4805 11.9593 20.6938 12.1166 20.8511C12.2739 21.0084 12.4872 21.0967 12.7097 21.0967H26.129C26.3515 21.0967 26.5648 21.0084 26.7221 20.8511C26.8794 20.6938 26.9677 20.4805 26.9677 20.258ZM25.2903 19.4193H13.5484V17.7419H25.2903V19.4193Z"
      fill="black"
    />
    <path
      d="M21.9355 42.0645C20.9691 42.0657 20.0327 42.4001 19.2841 43.0112C18.5355 43.6223 18.0205 44.4728 17.8258 45.4194H11.8709C11.7051 45.4194 11.543 45.4686 11.4051 45.5608C11.2672 45.6529 11.1597 45.7839 11.0962 45.9371C11.0328 46.0904 11.0162 46.259 11.0485 46.4216C11.0809 46.5843 11.1607 46.7337 11.278 46.851L14.0399 49.6129L10.4393 53.2135C10.282 53.3708 10.1937 53.5841 10.1937 53.8065C10.1937 54.0289 10.282 54.2421 10.4393 54.3994L13.7941 57.7543C13.9514 57.9115 14.1647 57.9998 14.3871 57.9998C14.6095 57.9998 14.8228 57.9115 14.98 57.7543L18.5806 54.1537L21.3425 56.9156C21.4598 57.0328 21.6092 57.1127 21.7719 57.145C21.9346 57.1774 22.1032 57.1608 22.2564 57.0973C22.4096 57.0338 22.5406 56.9264 22.6328 56.7885C22.7249 56.6506 22.7741 56.4884 22.7742 56.3226V50.3678C23.7933 50.1608 24.6993 49.5825 25.3161 48.7452C25.9328 47.9079 26.2165 46.8713 26.112 45.8366C26.0075 44.8019 25.5222 43.843 24.7505 43.1459C23.9787 42.4489 22.9754 42.0635 21.9355 42.0645ZM21.0968 54.2979L19.1736 52.3748C19.0163 52.2175 18.803 52.1292 18.5806 52.1292C18.3582 52.1292 18.1449 52.2175 17.9877 52.3748L14.3871 55.9754L12.2182 53.8065L15.8188 50.2059C15.976 50.0486 16.0643 49.8353 16.0643 49.6129C16.0643 49.3905 15.976 49.1772 15.8188 49.0199L13.8956 47.0968H21.0968V54.2979ZM22.7742 48.6199V46.2581C22.7742 46.0356 22.6858 45.8223 22.5285 45.665C22.3712 45.5077 22.1579 45.4194 21.9355 45.4194H19.5737C19.7144 45.0189 19.9543 44.6607 20.2711 44.3782C20.5879 44.0956 20.9711 43.8981 21.385 43.8039C21.7989 43.7097 22.2299 43.722 22.6377 43.8397C23.0455 43.9573 23.4168 44.1764 23.717 44.4766C24.0171 44.7767 24.2362 45.148 24.3539 45.5559C24.4715 45.9637 24.4838 46.3947 24.3896 46.8085C24.2955 47.2224 24.0979 47.6057 23.8154 47.9224C23.5329 48.2392 23.1746 48.4792 22.7742 48.6199V48.6199Z"
      fill="black"
    />
  </svg>
);

export default Icon10X;
