import React from 'react';

const IconTrulyGlobal = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3445 23.0174H15.3597C15.6969 23.0138 15.6897 22.511 15.3597 22.5074H14.3445C14.0037 22.5074 14.0109 23.0138 14.3445 23.0174Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M16.2565 14.1458C16.4933 14.3793 16.852 14.0237 16.6153 13.7866L15.8978 13.0683C15.6611 12.8348 15.3023 13.1904 15.5391 13.4274L16.2565 14.1458Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M37.6149 23.0174H38.6302C38.9674 23.0138 38.9602 22.511 38.6302 22.5074H37.6149C37.2777 22.5074 37.2849 23.0138 37.6149 23.0174Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M7.73326 22.5076H6.97276C6.85438 20.9487 5.55221 19.7419 3.99176 19.7419C2.34163 19.7419 1 21.0816 1 22.7374C1 24.3932 2.33804 25.733 3.99176 25.733C5.53428 25.733 6.82927 24.5549 6.96917 23.0176H7.73326C8.07046 23.014 8.06687 22.5076 7.73326 22.5076ZM3.99176 25.2229C2.62143 25.2229 1.50939 24.1095 1.50939 22.7374C1.50939 21.3654 2.62143 20.2519 3.99176 20.2519C5.36209 20.2519 6.47413 21.3654 6.47413 22.7374C6.47413 24.1095 5.36209 25.2229 3.99176 25.2229Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M14.4593 12.3464C14.6961 12.5799 15.0548 12.2243 14.818 11.9873L14.1006 11.2689C13.8638 11.0354 13.5051 11.391 13.7419 11.6281L14.4593 12.3464Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M18.0537 15.9455C18.2905 16.1789 18.6492 15.8233 18.4124 15.5863L17.695 14.8679C17.4582 14.6345 17.0995 14.9901 17.3362 15.2271L18.0537 15.9455Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M39.8282 7.77426L39.2004 8.40283C38.9673 8.63988 39.3224 8.99906 39.5592 8.762L40.2013 8.11907C41.3851 9.03139 43.0818 8.92364 44.1401 7.86406C45.3023 6.70032 45.3023 4.79308 44.1401 3.62934C42.9778 2.4656 41.073 2.4656 39.9107 3.62934C38.7843 4.75716 38.7484 6.59975 39.8282 7.77426ZM40.273 3.98852C41.238 3.02232 42.82 3.02232 43.7849 3.98852C44.7499 4.95471 44.7499 6.53869 43.7849 7.50488C42.82 8.47107 41.238 8.47107 40.273 7.50488C39.308 6.53869 39.308 4.95471 40.273 3.98852Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M35.9648 12.3609L36.6822 11.6426C36.9154 11.4055 36.5602 11.0463 36.3235 11.2834L35.606 12.0017C35.3729 12.2388 35.728 12.598 35.9648 12.3609Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M37.762 10.5612L38.4794 9.84287C38.7126 9.60582 38.3575 9.24664 38.1207 9.48369L37.4033 10.2021C37.1701 10.4391 37.5252 10.7983 37.762 10.5612Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M34.1676 14.1602L34.885 13.4419C35.1182 13.2048 34.7631 12.8456 34.5263 13.0827L33.8089 13.8011C33.5757 14.0381 33.9309 14.3973 34.1676 14.1602Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M24.9663 6.97673V7.81361C24.9699 8.15124 25.4721 8.14406 25.4757 7.81361V6.97314C26.1896 6.90848 26.8353 6.58882 27.3124 6.10752C28.4746 4.94378 28.4746 3.03654 27.3124 1.8728C26.1501 0.709065 24.2453 0.709065 23.083 1.8728C21.9207 3.03654 21.9207 4.94378 23.083 6.10752C23.5709 6.59959 24.2309 6.91926 24.9663 6.97673ZM23.4417 2.23198C24.4067 1.26579 25.9887 1.26579 26.9536 2.23198C27.9186 3.19817 27.9186 4.78215 26.9536 5.74834C26.5052 6.19731 25.8846 6.47747 25.1995 6.47747C24.5143 6.47747 23.8937 6.2009 23.4453 5.74834C22.4767 4.78215 22.4767 3.19817 23.4417 2.23198Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M32.3704 15.9599L33.0879 15.2416C33.3211 15.0045 32.9659 14.6453 32.7291 14.8824L32.0116 15.6007C31.7784 15.8378 32.1336 16.197 32.3704 15.9599Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M25.4721 9.33998C25.4685 9.00235 24.9663 9.00954 24.9627 9.33998V10.3565C24.9663 10.6941 25.4685 10.6869 25.4721 10.3565V9.33998Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M24.9663 11.883V12.8995C24.9699 13.2371 25.4721 13.2299 25.4757 12.8995V11.883C25.4721 11.5454 24.9663 11.5525 24.9663 11.883Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M33.547 22.5078H32.7578C32.6968 20.6149 31.9363 18.898 30.7274 17.605L31.287 17.0447C31.5202 16.8076 31.1651 16.4484 30.9283 16.6855L30.3687 17.2458C29.0773 16.0354 27.3626 15.2775 25.4721 15.2129V14.4299C25.4685 14.0922 24.9663 14.0994 24.9627 14.4299V15.2129C23.0723 15.2739 21.3576 16.0354 20.0662 17.2458L19.4922 16.6675C19.2554 16.4341 18.8967 16.7897 19.1335 17.0267L19.711 17.605C18.5021 18.898 17.7452 20.6149 17.6806 22.5078H16.8879C16.5507 22.5114 16.5578 23.0142 16.8879 23.0178H17.6806C17.7416 24.9107 18.5021 26.6275 19.711 27.9206L19.1514 28.4809C18.9182 28.718 19.2734 29.0771 19.5101 28.8401L20.0697 28.2798C21.3611 29.4902 23.0758 30.2481 24.9663 30.3127V31.067C24.9699 31.4046 25.4721 31.3974 25.4757 31.067V30.3127C27.3662 30.2516 29.0809 29.4902 30.3723 28.2798L30.9176 28.8257C31.1543 29.0592 31.513 28.7036 31.2763 28.4665L30.731 27.9206C31.9399 26.6275 32.6968 24.9107 32.7614 23.0178H33.5506C33.8842 23.0142 33.8806 22.5078 33.547 22.5078ZM30.1929 27.7446C28.9374 29.0017 27.2119 29.7847 25.2999 29.8063C25.2461 29.7883 25.1887 29.7847 25.1313 29.8063C23.2229 29.7847 21.4939 28.9981 20.2383 27.7446C18.9649 26.4695 18.1793 24.7095 18.1793 22.7628C18.1793 20.816 18.9685 19.0561 20.2383 17.781C21.5154 16.5059 23.2767 15.7157 25.221 15.7157C27.1653 15.7157 28.923 16.5059 30.1965 17.7774C31.47 19.0525 32.2556 20.8124 32.2556 22.7592C32.2556 24.7059 31.4664 26.4695 30.1929 27.7446Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M12.081 9.96854L12.6585 10.5468C12.8953 10.7803 13.254 10.4247 13.0172 10.1876L12.4325 9.60218C13.3903 8.42049 13.297 6.67848 12.2209 5.60095C11.0586 4.43722 9.15378 4.43722 7.99151 5.60095C6.82925 6.76468 6.82925 8.67191 7.99151 9.83565C9.10356 10.9455 10.9044 11.003 12.081 9.96854ZM8.35382 9.47647C7.38886 8.51028 7.38886 6.92631 8.35382 5.96013C9.31879 4.99394 10.9008 4.99394 11.8657 5.96013C12.8307 6.92631 12.8307 8.51028 11.8657 9.47647C10.9008 10.4427 9.31879 10.4427 8.35382 9.47647Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M34.1532 31.3469C33.9165 31.1134 33.5577 31.469 33.7945 31.7061L34.512 32.4244C34.7487 32.6579 35.1075 32.3023 34.8707 32.0653L34.1532 31.3469Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M32.356 29.5476C32.1192 29.3141 31.7605 29.6697 31.9973 29.9068L32.7147 30.6252C32.9515 30.8586 33.3102 30.503 33.0735 30.266L32.356 29.5476Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M38.544 32.9166C37.762 32.9166 37.0481 33.2183 36.5136 33.714L35.9504 33.1501C35.7137 32.9166 35.355 33.2722 35.5917 33.5093L36.1728 34.0911C35.7854 34.5976 35.5558 35.2261 35.5558 35.9122C35.5558 37.5644 36.8939 38.9077 38.5476 38.9077C40.2013 38.9077 41.5393 37.568 41.5393 35.9122C41.5357 34.2564 40.1977 32.9166 38.544 32.9166ZM38.544 38.3941C37.1737 38.3941 36.0616 37.2806 36.0616 35.9086C36.0616 35.2225 36.3379 34.6012 36.7898 34.1522C37.2382 33.7032 37.8588 33.4231 38.544 33.4231C39.9143 33.4231 41.0264 34.5365 41.0264 35.9086C41.0264 37.2842 39.9143 38.3941 38.544 38.3941Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M36.0904 22.5074H35.0752C34.738 22.511 34.7451 23.0138 35.0752 23.0174H36.0904C36.4276 23.0138 36.4205 22.5074 36.0904 22.5074Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M46.131 20.6435C44.9687 19.4798 43.0639 19.4798 41.9017 20.6435C41.4174 21.1284 41.0981 21.7821 41.0371 22.5076H40.1583C39.8211 22.5112 39.8282 23.0141 40.1583 23.0177H41.0371C41.0981 23.7432 41.4174 24.3933 41.9017 24.8818C43.0639 26.0456 44.9687 26.0456 46.131 24.8818C47.2897 23.7145 47.2897 21.8072 46.131 20.6435ZM45.7687 24.519C44.8037 25.4852 43.2218 25.4852 42.2568 24.519C41.8084 24.0701 41.5286 23.4487 41.5286 22.7627C41.5286 22.0766 41.8048 21.4552 42.2568 21.0063C43.2218 20.0401 44.8037 20.0401 45.7687 21.0063C46.7337 21.9689 46.7337 23.5529 45.7687 24.519Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M18.0681 29.5619L17.3506 30.2803C17.1175 30.5174 17.4726 30.8766 17.7094 30.6395L18.4268 29.9211C18.66 29.6877 18.3049 29.3249 18.0681 29.5619Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M12.8163 22.5074H11.8012C11.4639 22.511 11.4711 23.0138 11.8012 23.0174H12.8163C13.1571 23.0138 13.15 22.5074 12.8163 22.5074Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M15.0297 30.1185C13.3796 30.1185 12.038 31.4582 12.038 33.114C12.038 34.7663 13.376 36.1096 15.0297 36.1096C16.6798 36.1096 18.0214 34.7699 18.0214 33.114C18.0214 31.4582 16.6834 30.1185 15.0297 30.1185ZM15.0297 35.5996C13.6594 35.5996 12.5474 34.4861 12.5474 33.114C12.5474 31.742 13.6594 30.6285 15.0297 30.6285C16.4 30.6285 17.5121 31.742 17.5121 33.114C17.5121 34.4861 16.4 35.5996 15.0297 35.5996Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
    <path
      d="M10.2766 22.5074H9.2614C8.9242 22.511 8.93138 23.0138 9.2614 23.0174H10.2766C10.6138 23.0138 10.6066 22.5074 10.2766 22.5074Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M24.9663 38.6993C24.9699 39.0369 25.4721 39.0298 25.4757 38.6993V37.6829C25.4721 37.3453 24.9699 37.3524 24.9663 37.6829V38.6993Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M24.9663 36.1564C24.9699 36.4941 25.4721 36.4869 25.4757 36.1564V35.1399C25.4721 34.8022 24.9699 34.8094 24.9663 35.1399V36.1564Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M25.4721 33.6099V32.5934C25.4685 32.2557 24.9663 32.2629 24.9627 32.5934V33.6099C24.9663 33.9475 25.4721 33.9439 25.4721 33.6099Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="0.65"
    />
    <path
      d="M25.4721 41.0197V40.2259C25.4685 39.8882 24.9663 39.8954 24.9627 40.2259V41.0233C23.4274 41.1633 22.2472 42.46 22.2472 44.0045C22.2472 45.6567 23.5852 47.0001 25.239 47.0001C26.8927 47.0001 28.2307 45.6603 28.2307 44.0045C28.2307 42.442 27.029 41.1418 25.4721 41.0197ZM25.2425 46.49C23.8722 46.49 22.7601 45.3766 22.7601 44.0045C22.7601 42.6324 23.8722 41.5189 25.2425 41.5189C26.6129 41.5189 27.7249 42.6324 27.7249 44.0045C27.7249 45.3766 26.6129 46.49 25.2425 46.49Z"
      fill="#718096"
      stroke="#718096"
      stroke-width="1.2"
    />
  </svg>
);

export default IconTrulyGlobal;
