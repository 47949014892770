import React from 'react';

const IconConfluenceEngine = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="path-2-outside-1"
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="54"
      height="54"
      fill="black"
    >
      <rect fill="white" x="5" y="5" width="54" height="54" />
      <path d="M56.7317 56.7317H53.561V58H58V53.561H56.7317V56.7317Z" />
      <path d="M23.2488 56.7317H15.1317V58H23.2488V56.7317Z" />
      <path d="M36.0585 56.7317H27.9415V58H36.0585V56.7317Z" />
      <path d="M48.8683 56.7317H40.7512V58H48.8683V56.7317Z" />
      <path d="M7.26829 53.561H6V58H10.439V56.7317H7.26829V53.561Z" />
      <path d="M7.26829 40.7512H6V48.8683H7.26829V40.7512Z" />
      <path d="M7.26829 27.9415H6V36.0585H7.26829V27.9415Z" />
      <path d="M7.26829 15.1317H6V23.2488H7.26829V15.1317Z" />
      <path d="M6 10.439H7.26829V7.26829H10.439V6H6V10.439Z" />
      <path d="M36.0585 6H27.9415V7.26829H36.0585V6Z" />
      <path d="M48.8683 6H40.7512V7.26829H48.8683V6Z" />
      <path d="M23.2488 6H15.1317V7.26829H23.2488V6Z" />
      <path d="M53.561 6V7.26829H56.7317V10.439H58V6H53.561Z" />
      <path d="M58 40.7512H56.7317V48.8683H58V40.7512Z" />
      <path d="M58 27.9415H56.7317V36.0585H58V27.9415Z" />
      <path d="M58 15.1317H56.7317V23.2488H58V15.1317Z" />
      <path d="M43.7951 20.9024L44.5561 20.1415L43.6683 19.2537L42.7171 20.2049H21.1561L20.2049 19.2537L19.3171 20.1415L20.2683 21.0927V43.3512L19.3805 44.1756L20.2683 45.0634L21.6 43.7317H42.4634L43.7951 45.0634L44.6829 44.1756L43.8585 43.3512V20.9024H43.7951ZM22.8683 42.5268L23.5024 41.8927L22.6146 41.0049L21.4732 42.1463V22.361L22.4878 23.3756L23.3756 22.4878L22.361 21.4732H41.3854L40.3707 22.4878L41.2585 23.3756L42.4634 22.1707V42.0829L41.3219 40.9415L40.4341 41.8293L41.0683 42.4634H22.8683V42.5268Z" />
      <path d="M25.3396 24.4174L24.4428 25.3142L27.1332 28.0046L28.03 27.1078L25.3396 24.4174Z" />
      <path d="M15.3219 16.2098L17.3512 18.239L18.239 17.3512L16.2097 15.322L17.9854 13.5463L11.8976 11.8976L13.5463 17.9854L15.3219 16.2098Z" />
      <path d="M46.4585 18.239L48.4878 16.2098L50.2634 17.9854L51.9122 11.8976L45.8244 13.5463L47.6 15.322L45.5707 17.3512L46.4585 18.239Z" />
      <path d="M38.494 24.4407L35.8036 27.1312L36.7004 28.028L39.3908 25.3375L38.494 24.4407Z" />
      <path d="M17.478 46.1415L15.4488 48.1707L13.6097 46.3951L12.0244 52.4829L18.1122 50.8341L16.3366 49.0585L18.3658 47.0293L17.478 46.1415Z" />
      <path d="M27.1864 36.3712L24.496 39.0617L25.3928 39.9585L28.0832 37.268L27.1864 36.3712Z" />
      <path d="M36.7984 36.3497L35.9016 37.2465L38.5921 39.937L39.4889 39.0402L36.7984 36.3497Z" />
      <path d="M51.9756 52.4829L50.3902 46.3951L48.5512 48.1707L46.5219 46.1415L45.6341 47.0293L47.6634 49.0585L45.8878 50.8341L51.9756 52.4829Z" />
    </mask>
    <path d="M56.7317 56.7317H53.561V58H58V53.561H56.7317V56.7317Z" fill="black" />
    <path d="M23.2488 56.7317H15.1317V58H23.2488V56.7317Z" fill="black" />
    <path d="M36.0585 56.7317H27.9415V58H36.0585V56.7317Z" fill="black" />
    <path d="M48.8683 56.7317H40.7512V58H48.8683V56.7317Z" fill="black" />
    <path d="M7.26829 53.561H6V58H10.439V56.7317H7.26829V53.561Z" fill="black" />
    <path d="M7.26829 40.7512H6V48.8683H7.26829V40.7512Z" fill="black" />
    <path d="M7.26829 27.9415H6V36.0585H7.26829V27.9415Z" fill="black" />
    <path d="M7.26829 15.1317H6V23.2488H7.26829V15.1317Z" fill="black" />
    <path d="M6 10.439H7.26829V7.26829H10.439V6H6V10.439Z" fill="black" />
    <path d="M36.0585 6H27.9415V7.26829H36.0585V6Z" fill="black" />
    <path d="M48.8683 6H40.7512V7.26829H48.8683V6Z" fill="black" />
    <path d="M23.2488 6H15.1317V7.26829H23.2488V6Z" fill="black" />
    <path d="M53.561 6V7.26829H56.7317V10.439H58V6H53.561Z" fill="black" />
    <path d="M58 40.7512H56.7317V48.8683H58V40.7512Z" fill="black" />
    <path d="M58 27.9415H56.7317V36.0585H58V27.9415Z" fill="black" />
    <path d="M58 15.1317H56.7317V23.2488H58V15.1317Z" fill="black" />
    <path
      d="M43.7951 20.9024L44.5561 20.1415L43.6683 19.2537L42.7171 20.2049H21.1561L20.2049 19.2537L19.3171 20.1415L20.2683 21.0927V43.3512L19.3805 44.1756L20.2683 45.0634L21.6 43.7317H42.4634L43.7951 45.0634L44.6829 44.1756L43.8585 43.3512V20.9024H43.7951ZM22.8683 42.5268L23.5024 41.8927L22.6146 41.0049L21.4732 42.1463V22.361L22.4878 23.3756L23.3756 22.4878L22.361 21.4732H41.3854L40.3707 22.4878L41.2585 23.3756L42.4634 22.1707V42.0829L41.3219 40.9415L40.4341 41.8293L41.0683 42.4634H22.8683V42.5268Z"
      fill="black"
    />
    <path
      d="M25.3396 24.4174L24.4428 25.3142L27.1332 28.0046L28.03 27.1078L25.3396 24.4174Z"
      fill="black"
    />
    <path
      d="M15.3219 16.2098L17.3512 18.239L18.239 17.3512L16.2097 15.322L17.9854 13.5463L11.8976 11.8976L13.5463 17.9854L15.3219 16.2098Z"
      fill="black"
    />
    <path
      d="M46.4585 18.239L48.4878 16.2098L50.2634 17.9854L51.9122 11.8976L45.8244 13.5463L47.6 15.322L45.5707 17.3512L46.4585 18.239Z"
      fill="black"
    />
    <path
      d="M38.494 24.4407L35.8036 27.1312L36.7004 28.028L39.3908 25.3375L38.494 24.4407Z"
      fill="black"
    />
    <path
      d="M17.478 46.1415L15.4488 48.1707L13.6097 46.3951L12.0244 52.4829L18.1122 50.8341L16.3366 49.0585L18.3658 47.0293L17.478 46.1415Z"
      fill="black"
    />
    <path
      d="M27.1864 36.3712L24.496 39.0617L25.3928 39.9585L28.0832 37.268L27.1864 36.3712Z"
      fill="black"
    />
    <path
      d="M36.7984 36.3497L35.9016 37.2465L38.5921 39.937L39.4889 39.0402L36.7984 36.3497Z"
      fill="black"
    />
    <path
      d="M51.9756 52.4829L50.3902 46.3951L48.5512 48.1707L46.5219 46.1415L45.6341 47.0293L47.6634 49.0585L45.8878 50.8341L51.9756 52.4829Z"
      fill="black"
    />
    <path
      d="M56.7317 56.7317H53.561V58H58V53.561H56.7317V56.7317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M23.2488 56.7317H15.1317V58H23.2488V56.7317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M36.0585 56.7317H27.9415V58H36.0585V56.7317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M48.8683 56.7317H40.7512V58H48.8683V56.7317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M7.26829 53.561H6V58H10.439V56.7317H7.26829V53.561Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M7.26829 40.7512H6V48.8683H7.26829V40.7512Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M7.26829 27.9415H6V36.0585H7.26829V27.9415Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M7.26829 15.1317H6V23.2488H7.26829V15.1317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M6 10.439H7.26829V7.26829H10.439V6H6V10.439Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M36.0585 6H27.9415V7.26829H36.0585V6Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M48.8683 6H40.7512V7.26829H48.8683V6Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M23.2488 6H15.1317V7.26829H23.2488V6Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M53.561 6V7.26829H56.7317V10.439H58V6H53.561Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M58 40.7512H56.7317V48.8683H58V40.7512Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M58 27.9415H56.7317V36.0585H58V27.9415Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M58 15.1317H56.7317V23.2488H58V15.1317Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M43.7951 20.9024L44.5561 20.1415L43.6683 19.2537L42.7171 20.2049H21.1561L20.2049 19.2537L19.3171 20.1415L20.2683 21.0927V43.3512L19.3805 44.1756L20.2683 45.0634L21.6 43.7317H42.4634L43.7951 45.0634L44.6829 44.1756L43.8585 43.3512V20.9024H43.7951ZM22.8683 42.5268L23.5024 41.8927L22.6146 41.0049L21.4732 42.1463V22.361L22.4878 23.3756L23.3756 22.4878L22.361 21.4732H41.3854L40.3707 22.4878L41.2585 23.3756L42.4634 22.1707V42.0829L41.3219 40.9415L40.4341 41.8293L41.0683 42.4634H22.8683V42.5268Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M25.3396 24.4174L24.4428 25.3142L27.1332 28.0046L28.03 27.1078L25.3396 24.4174Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M15.3219 16.2098L17.3512 18.239L18.239 17.3512L16.2097 15.322L17.9854 13.5463L11.8976 11.8976L13.5463 17.9854L15.3219 16.2098Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M46.4585 18.239L48.4878 16.2098L50.2634 17.9854L51.9122 11.8976L45.8244 13.5463L47.6 15.322L45.5707 17.3512L46.4585 18.239Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M38.494 24.4407L35.8036 27.1312L36.7004 28.028L39.3908 25.3375L38.494 24.4407Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M17.478 46.1415L15.4488 48.1707L13.6097 46.3951L12.0244 52.4829L18.1122 50.8341L16.3366 49.0585L18.3658 47.0293L17.478 46.1415Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M27.1864 36.3712L24.496 39.0617L25.3928 39.9585L28.0832 37.268L27.1864 36.3712Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M36.7984 36.3497L35.9016 37.2465L38.5921 39.937L39.4889 39.0402L36.7984 36.3497Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
    <path
      d="M51.9756 52.4829L50.3902 46.3951L48.5512 48.1707L46.5219 46.1415L45.6341 47.0293L47.6634 49.0585L45.8878 50.8341L51.9756 52.4829Z"
      stroke="black"
      stroke-width="0.5"
      mask="url(#path-2-outside-1)"
    />
  </svg>
);

export default IconConfluenceEngine;
